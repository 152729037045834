import {configureStore} from '@reduxjs/toolkit';
import userReducer from './user';

const configRoot = {
  reducer: {
    user: userReducer,
  },
};

export const store = configureStore(configRoot);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
