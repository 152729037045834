import React from "react";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";
import { Button, NavBar } from "components";
import { useGoHome } from "views/Partner/utils";

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  const goBack = () => useGoHome(navigate);

  return (
    <>
      <NavBar />
      <S.Wrapper>
        <S.Title>404</S.Title>
        <S.SubTitle>Página não encontrada</S.SubTitle>
        <S.Button>
          <Button
            title={`Voltar página principal`}
            type_button="primary"
            onClick={goBack}
          />
        </S.Button>
      </S.Wrapper>
    </>
  );
};

export default NotFound;
