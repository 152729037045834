import React, { useState } from "react";
import * as S from "./styles";

interface ISwitch {
  children?: React.ReactNode;
  onPress: (value: boolean) => void;
}

export const Switch: React.FC<ISwitch> = ({ children, onPress }) => {
  const [active, setActive] = useState<boolean>(false);
  return (
    <S.Container
      onClick={() => {
        setActive(!active);
        onPress(!active);
      }}
    >
      <S.Wrapper>
        <S.Switch active={active}>
          <S.Circle active={active} />
        </S.Switch>
        {children}
      </S.Wrapper>
    </S.Container>
  );
};
