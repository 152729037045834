import React, { useEffect, useRef } from 'react'
import reCaptcha from "services/RecaptchaService";

interface ButtonRecaptchaProps {
  onVerify: (token: string | null) => void;
}

const ButtonRecaptchaBase: React.FC<ButtonRecaptchaProps> = ({ onVerify }) => {
  const widgetId = useRef<number | null>(null)
  
  useEffect(() => {
    if (widgetId.current === null) {
      widgetId.current = reCaptcha.renderWidget('render-recaptcha', {
        callback: onSuccessToken,
        errorCallback: onErrorToken,
        expiresCallback: onExpiresToken
      })
    }
  }, [])

  const onSuccessToken = (token: string) => {
    onVerify(token)
  }
  const onErrorToken = () => {
    onVerify(null)
  }
  const onExpiresToken = () => {
    onVerify(null)
  }

  return (
    <div id="render-recaptcha" />
  )
}

export default ButtonRecaptchaBase