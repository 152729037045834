import styled from "styled-components";

export const Container = styled.div<{ removeMargin?: boolean }>`
  width: 400px;
  min-width: 400px;
  height: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  margin-right: ${({ removeMargin }) => (removeMargin ? 0 : 83)}px;
  margin-top: ${({ removeMargin }) => (removeMargin ? 0 : 30)}px;
  box-sizing: border-box;

  background: linear-gradient(153.22deg, #ffc706 0%, #adff00 100%);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(25px);

  border-radius: 15px;

  @media screen and (max-width: 1023px) {
    margin-right: 0;
    margin-top: ${({ removeMargin }) => (removeMargin ? 0 : 15)}px;
  }

  @media screen and (max-width: 440px) {
    width: 100%;
    min-width: 280px;
  }
`;

export const TitleInstallment = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: #312f30;
  margin-top: 50px;
  margin-bottom: 24px;
`;

export const SubTitleInstallment = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  margin: 0;
  margin-bottom: 50px;

  color: #312f30;
`;

export const WrapperInput = styled.div`
  width: 100%;
`;

export const WrapperInputSelect = styled(WrapperInput)`
  flex-direction: row;
  display: flex;
`;

export const TextOr = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin: 20px 0;

  color: #312f30;
`;

export const UsagePolicies = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  text-align: center;

  color: rgba(49, 47, 48, 0.6);
  margin: 0;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const UsagePoliciesLink = styled.a`
  text-decoration: none;
  font-family: "Inter";
  font-style: bold;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;

  color: rgba(49, 47, 48, 0.6);
`;

export const ButtonInstallments = styled.div`
  width: 100%;
  margin-bottom: 50px;
`;

export const WrapperSelectSize = styled.div``;

export const Option = styled.option``;
