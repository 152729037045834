import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useParams } from "react-router-dom";
import { cleanMask } from "masks-br";

import {
  IStagesBilletUserInformationForm,
  SchemaRegisterOptional,
} from "models";
import usePayment from "hooks/usePayment";
import { Button, Input, Switch } from "components";
import { EnumStages } from "../../views/Billet/types";
import { yupResolver } from "@hookform/resolvers/yup";
import { getFullAdressByCep } from "utils/viaCep";
import { createOrUpdateAccount } from "views/Billet/providers/create-or-update-account.provider";
import { CreateOrUpdateAccountRequest } from "views/Billet/providers/dto/create-or-update-account.dto";

import Order2 from "assets/images/icons/order-2.svg";
import { id_establishment_default } from "config/establishment";
import * as S from "./styles";
import { isEmpty } from "lodash";
import { useWhitelabel } from "hooks";

type CreateClientForm = {
  address: string;
  city: string;
  document: string;
  email: string;
  name: string;
  neighborhood: string;
  number: string;
  phone: string;
  state: string;
  zip_code: string;
};

type IDisabledFields = {
  address: boolean;
  neighborhood: boolean;
};

export const UserInformationForm: React.FC<
  IStagesBilletUserInformationForm
> = ({ enable, setStage }) => {
  const {
    formState: { errors },
    control,
    watch,
    handleSubmit,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(SchemaRegisterOptional),
  });

  const { id_usuario } = useParams();
  const { client: clientWhitelabel } = useWhitelabel();

  const { setClient, client } = usePayment();

  const [loading, setLoading] = useState<boolean>(false);
  const [terms, setTerms] = useState<boolean>(false);
  const [disabledFields, setDisabledFields] = useState<IDisabledFields>({
    address: true,
    neighborhood: true,
  });
  const zip_code = watch("zip_code");
  const address = watch("address");
  const neighborhood = watch("neighborhood");

  useEffect(() => {
    if (!isEmpty(address)) {
      errors.address = undefined;
    }
    if (
      isEmpty(address) &&
      !isEmpty(zip_code) &&
      Number(cleanMask(zip_code).length) === 8
    ) {
      trigger("address");
    }

    if (!isEmpty(neighborhood)) {
      errors.neighborhood = undefined;
    }
    if (
      isEmpty(neighborhood) &&
      !isEmpty(zip_code) &&
      Number(cleanMask(zip_code).length) === 8
    ) {
      trigger("neighborhood");
    }
  }, [address, neighborhood, errors]);

  useEffect(() => {
    if (Number(cleanMask(zip_code).length) === 8) {
      fetchFullAdress();
    }
  }, [zip_code, setValue]);

  const fetchFullAdress = async () => {
    try {
      const data = await getFullAdressByCep(zip_code);

      setValue("address", data.address);
      setValue("neighborhood", data.neighborhood);
      setValue("city", data.city);
      setValue("state", data.state);

      trigger("address");
      trigger("neighborhood");
      trigger("city");
      trigger("state");

      setDisabledFields({
        ...disabledFields,
        address: !!data.address,
        neighborhood: !!data.neighborhood,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmitForm = () =>
    handleSubmit((data: any) => {
      createClient(data);
    });

  async function createClient(form: CreateClientForm) {
    setLoading(true);

    const data: CreateOrUpdateAccountRequest = {
      name: form.name,
      document: cleanMask(form.document),
      email: form.email,
      phone: cleanMask(form.phone),
      address: {
        address: form.address,
        number: form.number,
        neighborhood: form.neighborhood,
        zip_code: cleanMask(form.zip_code),
        city: form.city,
        state: form.state,
      },
    };

    const response = await createOrUpdateAccount(
      data,
      id_usuario ?? clientWhitelabel?.id_usuario ?? id_establishment_default
    );

    setLoading(false);

    if (response.created_at || client.created_at.length > 0) {
      setClient(response);
      setStage(EnumStages.PAYMENT_FORM);
    }
  }

  return (
    <S.UserInformationFormWrapper enable={enable}>
      <S.TitleRow>
        <S.OrderImg src={Order2} />
        <S.Title>Identifique-se</S.Title>
      </S.TitleRow>

      <S.Body>
        <S.BodyCollum>
          <S.WrapperInput>
            <Input
              type="text"
              type_input="normal"
              placeholder="Nome completo"
              name="name"
              error={errors?.name?.message as string}
              control={control}
              required
            />
          </S.WrapperInput>
          <S.WrapperInput>
            <Input
              control={control}
              name="document"
              error={errors?.document?.message as string}
              type="text"
              type_input="normal"
              placeholder="CPF"
              mask="999.999.999-99"
              required
            />
          </S.WrapperInput>
          <S.WrapperInput>
            <Input
              type="email"
              type_input="normal"
              placeholder="Seu melhor e-mail"
              control={control}
              name="email"
              error={errors?.email?.message as string}
              required
            />
          </S.WrapperInput>
          <S.WrapperInput>
            <Input
              type="text"
              type_input="normal"
              placeholder="Celular com DDD"
              mask={"(99) 99999-9999"}
              control={control}
              name="phone"
              error={errors?.phone?.message as string}
              required
            />
          </S.WrapperInput>
        </S.BodyCollum>
        <S.BodyCollum>
          <S.WrapperInput>
            <S.CepOrCityInputContainer>
              <Input
                type="text"
                type_input="normal"
                placeholder="CEP"
                mask={"99999-999"}
                control={control}
                name="zip_code"
                error={errors?.zip_code?.message as string}
                required
              />
            </S.CepOrCityInputContainer>
            <S.HomeNumberOrStateInputContainer>
              <Input
                type="text"
                type_input="normal"
                placeholder="Número"
                control={control}
                name="number"
                error={errors?.number?.message as string}
                required
              />
            </S.HomeNumberOrStateInputContainer>
          </S.WrapperInput>
          <S.WrapperInput>
            <Input
              disabled={disabledFields.address}
              type="text"
              type_input="normal"
              placeholder="Nome da rua"
              control={control}
              name="address"
              error={errors?.address?.message as string}
              required
            />
          </S.WrapperInput>

          <S.WrapperInput>
            <Input
              disabled={disabledFields.neighborhood}
              type="text"
              type_input="normal"
              placeholder="Bairro"
              control={control}
              name="neighborhood"
              error={errors?.neighborhood?.message as string}
              required
            />
          </S.WrapperInput>

          <S.WrapperInput>
            <S.CepOrCityInputContainer>
              <Input
                disabled
                type="text"
                type_input="normal"
                placeholder="Cidade"
                control={control}
                name="city"
                error={errors?.city?.message as string}
              />
            </S.CepOrCityInputContainer>
            <S.HomeNumberOrStateInputContainer>
              <Input
                disabled
                type="text"
                type_input="normal"
                placeholder="UF"
                control={control}
                name="state"
                error={errors?.state?.message as string}
              />
            </S.HomeNumberOrStateInputContainer>
          </S.WrapperInput>
        </S.BodyCollum>
      </S.Body>
      <S.FooterForm>
        <Switch onPress={(value) => setTerms(value)}>
          <S.RowTextsSwitch>
            <S.TextSwitch>
              <S.TextSwitchRequired>(obrigatório) </S.TextSwitchRequired>
              Concordo com os{" "}
              <Link to="/termos-de-uso" target="_blank">
                Termos de Uso
              </Link>
              , e{" "}
              <Link to="/politica-de-privacidade" target="_blank">
                Políticas de Privacidade.
              </Link>
            </S.TextSwitch>
          </S.RowTextsSwitch>
        </Switch>
      </S.FooterForm>
      <S.ButtonContainer>
        <Button
          action={handleSubmitForm()}
          title={"Continuar"}
          type_button="primary"
          paddingHorizontal={10}
          loading={loading}
          color_loading="#fff"
          type_loading="spinningBubbles"
          disabled={!terms}
          id="continue-button"
        />
      </S.ButtonContainer>
    </S.UserInformationFormWrapper>
  );
};
