import styled from "styled-components";
import { Link as link } from "react-router-dom";

export const Container = styled.div`
	width: 100%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #f3f5f6;
`;

export const Text = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 13px;
	line-height: 120%;
	text-align: center;
	letter-spacing: 1.5px;
	text-transform: uppercase;
	color: #000000;
	margin: 0;
	margin-top: 64px;
	margin-bottom: 80px;
	@media screen and (max-width: 598px) {
		margin-top: 32px;
		margin-bottom: 40px;
	}
`;

export const Text2 = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 32px;
	line-height: 120%;
	text-align: center;
	color: #000000;
	margin: 0;
	margin-top: 40px;
	@media screen and (max-width: 598px) {
		font-size: 16px;
		margin-top: 20px;
	}
`;

export const Text3 = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 300;
	font-size: 22px;
	line-height: 140%;
	text-align: center;
	color: #000000;
	margin: 0;
	margin-top: 78px;
	margin-bottom: 64px;
	@media screen and (max-width: 598px) {
		font-size: 16px;
		margin-top: 39px;
		margin-bottom: 32px;
	}
`;

export const IconWhats = styled.img``;

export const Gradient = styled.div`
	width: 100%;
	background: linear-gradient(153.22deg, #ffc706 0%, #adff00 100%);
	padding: 40px 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
export const WrapperIcons = styled.div`
	display: flex;
	flex-direction: row;
`;

export const Link = styled.a`
	text-decoration: none;
`;

export const IconFB = styled.img`
	width: 32px;
	height: 32px;
	margin-right: 8px;
`;

export const IconTT = styled.img`
	width: 32px;
	height: 32px;
	margin-left: 8px;
	margin-right: 8px;
`;

export const IconTelegram = styled.img`
	width: 32px;
	height: 32px;
	margin-left: 8px;
`;

export const Text4 = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 140%;
	text-align: center;
	color: #000000;
	margin: 0;
	margin-top: 16px;
`;

export const LinksContainer = styled.div`
	border-bottom: 1px solid #312f30;
	opacity: 60%;
	margin-bottom: 70px;
`;

export const CompanyPoliticsAndPrivacyLink = styled(link)`
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 17px;
	line-height: 18px;
	text-align: center;
	color: #312f30;
	margin-bottom: 50px;
	opacity: 60%;
	text-decoration: none;
`;
