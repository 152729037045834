import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

import { BoxForm, Input, Button } from "components";

import Order1 from "assets/images/icons/order-1.svg";
import * as S from "./styles";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { applyMaskToIdentifier } from "utils/identifier";

type FormIdentifierSchema = { identifier: string }

type SearchIdentifierProps = {
  idEstablishment: string;
}

export const Search: React.FC<SearchIdentifierProps> = ({ idEstablishment }) => {
  const queryParameters = new URLSearchParams(window.location.search);
  const identifier = queryParameters.get("identifier");
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    formState,
    setValue,
    setError,
    resetField
  } = useForm<FormIdentifierSchema>({
    mode: "all",
  });

  useEffect(() => {
    if (identifier) {
      const hasOnlyValidCharacteres = /^[0-9-]+$/.test(identifier)

      if (!hasOnlyValidCharacteres) {
        toast.error('Forneça um identificador numérico válido!', { toastId: 'identifier-invalid' })
        return 
      }

      const isValid = validateIdentifier(identifier)
      if (typeof isValid === 'string') {
        setError('identifier', { message: isValid })
      }

      const masked = applyMaskToIdentifier(identifier)
      setValue('identifier', masked)

      if (isValid) {
        const cleanedIdentifier = identifier.replace(/\D/g, '');

        navigate(`/checkout/${cleanedIdentifier}`)
      }
    }
  }, [])


  function validateIdentifier(value: string) {
    const number = value.replace(/\D/g, '');
    
    if (number.length < 12) {
      return 'Continue digitando!'
    }

    const regex = /^\d{4}-\d{4}-\d{4}$/;

    return regex.test(value) || number.length === 12 || 'Identificador inválido!'
  }

  const onSubmitHandler = ({ identifier }: FormIdentifierSchema) => {
    const cleanedIdentifier = identifier.replace(/\D/g, '');

    navigate(`/checkout/${cleanedIdentifier}`);
  };

  return (
    <S.SearchWrapper enable>
      <S.WrapperBox>
        <S.Row>
          <S.OrderImg src={Order1} />
          <S.Title>Adicione o identificador de pagamento</S.Title>
        </S.Row>

        <BoxForm>
          <S.WrapperBoxForm>
            <S.WrapperInput>
              <Input
                type="text"
                type_input="normal"
                placeholder="Digite o número do identificador"
                control={control}
                onPaste={(event: any) => {
                  const valueToPaste = event.target.value as string

                  resetField('identifier', {
                    defaultValue: valueToPaste
                  })
                }}
                onChange={event => {
                  const masked = applyMaskToIdentifier(event.target.value)
                  setValue('identifier', masked)
                }}
                name="identifier"
                maxLength={14}
                error={formState.errors.identifier?.message as string}
                validate={validateIdentifier}
              />
            </S.WrapperInput>

            <S.WrapperButton>
              <S.Button>
                <Button
                  title="Processar"
                  type_button="primary"
                  disabled={!!Object.keys(formState.errors).length}
                  action={handleSubmit(onSubmitHandler)}
                />
              </S.Button>
            </S.WrapperButton>
          </S.WrapperBoxForm>
        </BoxForm>
      </S.WrapperBox>
    </S.SearchWrapper>
  );
};
