import React, { Fragment, useState } from "react";
import Logo from "assets/images/icons/logo-header.svg";
import * as S from "./styles";
import { useWhitelabel } from "hooks/useWhitelabel";
import { Row, Skeleton } from "antd";
import { id_establishment_default } from "config/establishment";
import { useLocation } from "react-router-dom";

type NavBarProps = {
  isLoading?: boolean;
}

type LinkActive = "home" | "what" | "parcelamos" | "help"

export const NavBar: React.FC<NavBarProps> = ({ isLoading = false }) => {
  const location = useLocation()
  const hashDefault = location.hash.substring(1) as LinkActive|| "home" 
  const [linkActive, setLinkActive] = useState<LinkActive>(hashDefault);
  const [responsive, setResponsive] = useState<boolean>(false);
  const { client } = useWhitelabel();
  const is_payment_process =
    window.location.href.toString().includes("/parceiro") ||
    window.location.href.toString().includes("/payment_online") ||
    window.location.href.toString().includes("boleto");

  const userId = client?.id_usuario

  const myFunction = () => {
    setResponsive(!responsive);
  };

  if (isLoading) {
    return (
      <Row
        style={{
          display: "flex",
          width: "auto",
          flexDirection: "column",
        }}
      >
        <Skeleton.Button
          size="large"
          style={{ width: "100%", height: 62 }}
          active
        />
      </Row>
    )
  }

  if (userId && userId !== id_establishment_default) {
    return (
      <Fragment>
        <S.MarginTop />
        <S.NavBarClient responsive={responsive}>
          <S.ALogoClient href={`/payment_online/${userId}`}>
            <S.ImgLogoClient src={client?.main_logo_url} />
          </S.ALogoClient>
        </S.NavBarClient>
      </Fragment>
    );
  } else {
    return (
      <>
        <S.Section>
          <S.TopNav responsive={responsive}>
            <S.WrapperTopNav>
              <S.ALogo href="/">
                <S.ImgLogo src={Logo} />
              </S.ALogo>

              <S.AHeader
                href="/#home"
                active={linkActive === "home"}
                responsive={responsive}
                margin={responsive}
                onClick={() => setLinkActive("home")}
              >
                Home
                <S.BorderGradient />
              </S.AHeader>

              <S.AHeader
                href="/#what"
                active={linkActive === "what"}
                responsive={responsive}
                onClick={() => setLinkActive("what")}
                hide={is_payment_process}
              >
                O que fazemos?
                <S.BorderGradient />
              </S.AHeader>

              <S.AHeader
                href="/#help"
                responsive={responsive}
                active={linkActive === "help"}
                onClick={() => setLinkActive("help")}
                hide={is_payment_process}
              >
                Ajuda
                <S.BorderGradient />
              </S.AHeader>

              <S.SectionHeaderEnd responsive={responsive}></S.SectionHeaderEnd>

              <S.WrapperIcon>
                <S.Icon
                  responsive={responsive}
                  href="javascript:void(0);"
                  onClick={() => myFunction()}
                >
                  <S.I className="fa fa-bars" />
                </S.Icon>
              </S.WrapperIcon>
            </S.WrapperTopNav>
          </S.TopNav>
        </S.Section>

        <S.Margin responsive={responsive} />
      </>
    );
  }
};
