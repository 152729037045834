import React, { useState } from "react";

import * as S from "./styles";
import { BoxForm, Button, Currency, Input, Select } from "components";
import { useForm } from "react-hook-form";
import { Modal } from "antd";
import { SchemaDataBank, SchemaDataUser } from "models/Schemas/TransferBank";
import { yupResolver } from "@hookform/resolvers/yup";
import { cleanMoneyMask, dinheiroMask } from "masks-br";
import { isCnpjMask } from "utils/mask";
import { EnumStages } from "../types";
import BrazilianBanks from "bancos-brasileiros";
import { useCreateTransferBank } from "../providers/create-transfer-bank.provider";
import usePayment from "hooks/usePayment";
import { toast } from "react-toastify";

export interface IGetDataBank {
  setStage: (value: number) => void;
  enable?: boolean;
}

export enum EnumSteps {
  DATA_BANK = 1,
  DATA_USER = 2,
}

const GetDataBank: React.FC<IGetDataBank> = ({ setStage, enable }) => {
  const { dataBank, dataUser, setDataBank, setDataUser, saveTransferBank } =
    usePayment();
  const { mutate: createTransferBank, isLoading: isLoadingCreateTransferBank } =
    useCreateTransferBank({
      onSuccess: (data) => {
        saveTransferBank(data.data);
        handleNextStage();
      },
      onError: (error) => {
        console.error(error);
        toast.error("Revise os dados e tente novamente.");
        setSteps(EnumSteps.DATA_BANK);
      },
    });
  const [steps, setSteps] = useState<EnumSteps>(EnumSteps.DATA_BANK);
  const [openSummary, setOpenSummary] = useState<boolean>(false);

  const {
    control: controlBank,
    formState: { errors: errorsBank },
    handleSubmit: handleSubmitBank,
  } = useForm({
    resolver: yupResolver(SchemaDataBank),
    mode: "all",
  });

  const {
    control: controlUser,
    formState: { errors: errorsUser },
    handleSubmit: handleSubmitUser,
    watch,
  } = useForm({
    resolver: yupResolver(SchemaDataUser),
    mode: "all",
  });

  const recipient_document = watch("recipient_document");

  const onSubmitHandler = handleSubmitBank((data: any) => {
    setDataBank({
      ...data,
      amount_total: cleanMoneyMask(data.amount_total),
      bank_code: data.bank.split(" | ")[0],
      bank_name: data.bank.split(" | ")[1],
    });
    setOpenSummary(true);
  });

  const onSubmitHandlerUser = handleSubmitUser((data: any) => {
    setDataUser({
      ...data,
    });
    setOpenSummary(true);
  });

  const handleNextStep = () => {
    setSteps(steps + 1);
  };

  const handleNextStage = () => {
    setStage(EnumStages.CLIENT_IDENTIFICATION);
  };

  const handleFinished = async () => {
    if (steps === EnumSteps.DATA_BANK) {
      setOpenSummary(false);
      handleNextStep();
      return;
    }

    setOpenSummary(false);

    createTransferBank({
      ...dataBank,
      ...dataUser,
      recipient_phone: dataUser.recipient_phone.replace(/\D/g, ""),
      recipient_document: dataUser.recipient_document.replace(/\D/g, ""),
      amount_total: cleanMoneyMask(dataBank.amount_total),
    });
  };

  const handleNameTypeAccount = (type: string) => {
    switch (type) {
      case "checking_account":
        return "Conta corrente";
      case "savings_account":
        return "Conta poupança";
      default:
        return "";
    }
  };

  return (
    <S.WrapperGetDataBank enable={enable}>
      <S.WrapperBox enable={steps === EnumSteps.DATA_BANK}>
        <BoxForm>
          <S.WrapperBoxForm>
            <S.TitleForm>
              Informe os dados bancários do beneficiário
            </S.TitleForm>

            <S.WrapperBoxFormInput>
              <S.WrapperInput>
                <Select
                  control={controlBank}
                  name="bank"
                  error={errorsBank?.bank?.message as string}
                >
                  <S.Option value="" disabled selected>
                    Selecione o bank
                  </S.Option>

                  {BrazilianBanks.map((bank) => {
                    return (
                      <S.Option
                        key={bank.COMPE}
                        value={bank.COMPE + " | " + bank.ShortName}
                      >
                        {bank.COMPE + " | " + bank.ShortName}
                      </S.Option>
                    );
                  })}
                </Select>
              </S.WrapperInput>

              <S.WrapperInput>
                <Select
                  placeholder="Tipo de conta"
                  control={controlBank}
                  name="account_type"
                  error={errorsBank?.account_type?.message as string}
                >
                  <S.Option value="" disabled selected>
                    Informe o tipo de conta
                  </S.Option>
                  <S.Option value="checking_account">Conta corrente</S.Option>
                  <S.Option value="savings_account">Conta poupança</S.Option>
                </Select>
              </S.WrapperInput>

              <S.WrapperBoxFormInputDigit>
                <S.WrapperInput>
                  <Input
                    type="text"
                    type_input="normal"
                    placeholder="Agência"
                    control={controlBank}
                    name="agency_number"
                    maxLength={4}
                    error={errorsBank?.agency_number?.message as string}
                  />
                </S.WrapperInput>

                <S.WrapperInput>
                  <Input
                    type="text"
                    type_input="normal"
                    placeholder="Digito da agência"
                    control={controlBank}
                    name="agency_number_digit"
                    maxLength={1}
                    error={errorsBank?.agency_number_digit?.message as string}
                  />
                </S.WrapperInput>
              </S.WrapperBoxFormInputDigit>

              <S.WrapperBoxFormInputDigit>
                <S.WrapperInput>
                  <Input
                    type="text"
                    type_input="normal"
                    placeholder="Número da conta"
                    control={controlBank}
                    name="account_number"
                    maxLength={6}
                    error={errorsBank?.account_number?.message as string}
                  />
                </S.WrapperInput>

                <S.WrapperInput>
                  <Input
                    type="text"
                    type_input="normal"
                    placeholder="Digito da conta"
                    control={controlBank}
                    name="account_number_digit"
                    maxLength={1}
                    error={errorsBank?.account_number_digit?.message as string}
                  />
                </S.WrapperInput>
              </S.WrapperBoxFormInputDigit>

              <S.WrapperInput>
                <Currency
                  type="text"
                  type_input="normal"
                  placeholder="Valor"
                  control={controlBank}
                  name="amount_total"
                  error={errorsBank?.amount_total?.message as string}
                />
              </S.WrapperInput>

              <S.WrapperInput>
                <Input
                  type="text"
                  type_input="normal"
                  placeholder="Descricao"
                  control={controlBank}
                  name="description"
                  error={errorsBank?.description?.message as string}
                  maxLength={80}
                />
              </S.WrapperInput>

              <S.WrapperInput>
                <Input
                  type="text"
                  type_input="normal"
                  placeholder="Código de referência"
                  control={controlBank}
                  name="reference_id"
                  error={errorsBank?.reference_id?.message as string}
                  maxLength={20}
                />
              </S.WrapperInput>
            </S.WrapperBoxFormInput>

            <S.WrapperButton>
              <S.Button>
                <Button
                  title="Continuar"
                  type_button="primary"
                  action={onSubmitHandler}
                />
              </S.Button>
            </S.WrapperButton>
          </S.WrapperBoxForm>
        </BoxForm>
      </S.WrapperBox>
      <S.WrapperBox enable={steps === EnumSteps.DATA_USER}>
        <BoxForm>
          <S.WrapperBoxForm>
            <S.TitleForm>Informe os dados pessoais do beneficiário</S.TitleForm>

            <S.WrapperBoxFormInput>
              <S.WrapperInput>
                <Input
                  type="text"
                  type_input="normal"
                  placeholder="Nome"
                  control={controlUser}
                  name="recipient_name"
                  error={errorsUser?.recipient_name?.message as string}
                />
              </S.WrapperInput>

              <S.WrapperInput>
                <Input
                  type="text"
                  type_input="normal"
                  placeholder="CPF/CNPJ"
                  control={controlUser}
                  name="recipient_document"
                  mask={isCnpjMask(recipient_document)}
                  error={errorsUser?.recipient_document?.message as string}
                />
              </S.WrapperInput>

              <S.WrapperInput>
                <Input
                  type="text"
                  type_input="normal"
                  placeholder="Email"
                  control={controlUser}
                  name="recipient_email"
                  error={errorsUser?.recipient_email?.message as string}
                />
              </S.WrapperInput>

              <S.WrapperInput>
                <Input
                  type="text"
                  type_input="normal"
                  placeholder="Phone"
                  control={controlUser}
                  name="recipient_phone"
                  mask={"(99) 99999-9999"}
                  error={errorsUser?.recipient_phone?.message as string}
                />
              </S.WrapperInput>
            </S.WrapperBoxFormInput>

            <S.WrapperButton>
              <S.Button>
                <Button
                  title="Continuar"
                  type_button="primary"
                  action={onSubmitHandlerUser}
                  loading={isLoadingCreateTransferBank}
                />
              </S.Button>
            </S.WrapperButton>
          </S.WrapperBoxForm>
        </BoxForm>
      </S.WrapperBox>
      <Modal
        title="Resumo da transferência"
        open={openSummary}
        onCancel={() => setOpenSummary(false)}
        onOk={() => handleFinished()}
        okText="Confirmar"
        cancelText="Cancelar"
      >
        <S.WrapperSummary enable={steps === EnumSteps.DATA_USER}>
          <S.WrapperSummaryItem>
            <S.SummaryItemTitle>Nome do beneficiário</S.SummaryItemTitle>
            <S.SummaryItemValue>{dataUser.recipient_name}</S.SummaryItemValue>
          </S.WrapperSummaryItem>
          <S.WrapperSummaryItem>
            <S.SummaryItemTitle>CPF/CNPJ</S.SummaryItemTitle>
            <S.SummaryItemValue>
              {dataUser.recipient_document}
            </S.SummaryItemValue>
          </S.WrapperSummaryItem>
          <S.WrapperSummaryItem>
            <S.SummaryItemTitle>Email</S.SummaryItemTitle>
            <S.SummaryItemValue>{dataUser.recipient_email}</S.SummaryItemValue>
          </S.WrapperSummaryItem>
          <S.WrapperSummaryItem>
            <S.SummaryItemTitle>Telefone</S.SummaryItemTitle>
            <S.SummaryItemValue>{dataUser.recipient_phone}</S.SummaryItemValue>
          </S.WrapperSummaryItem>
        </S.WrapperSummary>
        <S.WrapperSummary enable={steps === EnumSteps.DATA_BANK}>
          <S.WrapperSummaryItem>
            <S.SummaryItemTitle>Banco</S.SummaryItemTitle>
            <S.SummaryItemValue>{dataBank.bank_name}</S.SummaryItemValue>
          </S.WrapperSummaryItem>
          <S.WrapperSummaryItem>
            <S.SummaryItemTitle>Tipo de conta</S.SummaryItemTitle>
            <S.SummaryItemValue>
              {handleNameTypeAccount(dataBank.account_type)}
            </S.SummaryItemValue>
          </S.WrapperSummaryItem>
          <S.WrapperSummaryItem>
            <S.SummaryItemTitle>Agência</S.SummaryItemTitle>
            <S.SummaryItemValue>
              {dataBank.agency_number} - {dataBank.agency_number_digit}
            </S.SummaryItemValue>
          </S.WrapperSummaryItem>
          <S.WrapperSummaryItem>
            <S.SummaryItemTitle>Número da conta</S.SummaryItemTitle>
            <S.SummaryItemValue>
              {dataBank.account_number} - {dataBank.account_number_digit}
            </S.SummaryItemValue>
          </S.WrapperSummaryItem>
          <S.WrapperSummaryItem>
            <S.SummaryItemTitle>Valor</S.SummaryItemTitle>
            <S.SummaryItemValue>
              {dinheiroMask(cleanMoneyMask(dataBank.amount_total))}
            </S.SummaryItemValue>
          </S.WrapperSummaryItem>
          <S.WrapperSummaryItem>
            <S.SummaryItemTitle>Descrição</S.SummaryItemTitle>
            <S.SummaryItemValue>{dataBank.description}</S.SummaryItemValue>
          </S.WrapperSummaryItem>
          <S.WrapperSummaryItem>
            <S.SummaryItemTitle>Código de referencia</S.SummaryItemTitle>
            <S.SummaryItemValue>{dataBank.reference_id}</S.SummaryItemValue>
          </S.WrapperSummaryItem>
        </S.WrapperSummary>
      </Modal>
    </S.WrapperGetDataBank>
  );
};

export default GetDataBank;
