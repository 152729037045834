import { IRadioButtonProps } from "models";
import React from "react";
import CheckBox from "assets/images/icons/checkbox.svg";
import * as S from "./styles";

export const RadioButton: React.FC<IRadioButtonProps> = ({
  text,
  status,
  setStatus,
  ...props
}) => {
  return (
    <S.WrapperRadio
      {...props}
      onClick={() => {
        setStatus();
      }}
    >
      {status ? <S.CircleActive src={CheckBox} /> : <S.Circle />}
      <S.TextRadio>{text}</S.TextRadio>
    </S.WrapperRadio>
  );
};
