import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 508px;
  background: linear-gradient(153.22deg, #ffc706 0%, #adff00 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 128px 20px;
  box-sizing: border-box;
  margin-bottom: 100px;
`;

export const Title = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 64px;
  color: #ffffff;
  margin: 0;
  margin-bottom: 48px;
`;

export const WrapperCards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  max-width: 580px;
  min-width: 300px;
  background-color: #ffffff;
  box-shadow: 0px 0px 42px #ffffff;
  border-radius: 10px;
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  @media screen and (max-width: 425px) {
    flex-direction: column;
  }
`;

export const Img = styled.img``;

export const WrapperTextsCards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 40px;

  @media screen and (max-width: 425px) {
    margin-left: 0px;
  }
`;

export const TitleCard = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 32px;
  margin: 0;

  font-feature-settings: "salt" on, "liga" off;

  background: linear-gradient(153.22deg, #ffc706 0%, #adff00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

export const SubTitleCard = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  font-feature-settings: "salt" on, "liga" off;
  color: #18191f;
  margin: 0;
`;

export const ImgArrow = styled.img`
  margin: 20px;

  @media screen and (max-width: 1023px) {
    transform: rotate(90deg);
  }
`;
