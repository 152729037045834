import React, { useEffect } from "react";

import { ICardProps } from "./types";
import { IInstallmentItem } from "models";
import usePayment from "hooks/usePayment";
import { Input, Select } from "components";
import { truncTwoDecimals } from "utils/billet";
import { formatCurrency, isCnpjMask, maskCvv } from "utils/mask";

import * as S from "./styles";

export const CreditCard: React.FC<ICardProps> = ({
  amount,
  enable,
  showSummary,
}) => {
  const {
    installmentsToUse,
    setPaymentCard,
    paymentCard,
    formCreditCard,
    setEnableCheckout,
    taxSearchDebitCar,
  } = usePayment();

  const {
    control,
    formState: { errors },
    trigger,
  } = formCreditCard;

  async function checkFieldInFormCard() {
    const haveElement = Object.values(paymentCard).length;
    const fieldsName = Object.keys(paymentCard);
    const totalFieldsForValidation = 7;

    if (haveElement) {
      const isAllFieldsFilled = Object.values(paymentCard).every(
        (item) => item
      );

      if (isAllFieldsFilled) {
        const isValidChangedFields = await trigger(fieldsName);
        const allFieldsIsValid =
          isValidChangedFields && haveElement === totalFieldsForValidation;

        setEnableCheckout(allFieldsIsValid);
      }
    }
  }

  useEffect(() => {
    checkFieldInFormCard();
  }, [paymentCard]);

  if (!installmentsToUse.length || installmentsToUse[0] === undefined) {
    return null;
  }

  return (
    <S.Container enable={enable}>
      <S.Row>
        <S.WrapperRight active={enable}>
          <S.WrapperHeader>
            <S.Title>Dados do cartão </S.Title>
          </S.WrapperHeader>

          <S.RowInputs>
            <S.WrapperInput>
              <Select
                onChange={(e: any) => {
                  const installment = installmentsToUse.find(
                    ({ id_charge_option }) =>
                      id_charge_option === e.target.value
                  ) as IInstallmentItem;

                  setPaymentCard({
                    ...paymentCard,
                    id_charge_option: e.target.value,
                    installment,
                  });
                }}
                name="installments"
                control={control}
                error={errors?.installments?.message as string}
              >
                {installmentsToUse.map((installment) => {
                  return (
                    <S.Option
                      key={installment.id_charge_option}
                      value={installment.id_charge_option}
                      // onClick={() => setPaymentCard({ ...paymentCard, installment })}
                    >
                      {installment.description}
                    </S.Option>
                  );
                })}
              </Select>
            </S.WrapperInput>
          </S.RowInputs>

          <S.WrapperInput>
            <Input
              type="text"
              type_input="normal"
              placeholder="Número do cartão"
              onChange={(e: any) =>
                setPaymentCard({
                  ...paymentCard,
                  number: e.target.value,
                })
              }
              name="number"
              control={control}
              error={errors?.number?.message as string}
              mask={"9999 9999 9999 9999"}
            />
          </S.WrapperInput>

          <S.RowInputs>
            <S.WrapperInput>
              <Input
                type="text"
                type_input="normal"
                placeholder="Validade"
                onChange={(e: any) =>
                  setPaymentCard({
                    ...paymentCard,
                    due_date: e.target.value,
                  })
                }
                name={"due_date"}
                mask={"99/99"}
                control={control}
                error={errors?.due_date?.message as string}
              />
            </S.WrapperInput>
            <S.MarginInputs />
            <S.WrapperInput>
              <Input
                type="text"
                type_input="normal"
                placeholder="CVV / CVC"
                onChange={(e: any) =>
                  setPaymentCard({
                    ...paymentCard,
                    cvv: e.target.value,
                  })
                }
                alwaysShowMask={false}
                mask={maskCvv(paymentCard.cvv)}
                maskChar=""
                name={"cvv"}
                control={control}
                error={errors?.cvv?.message as string}
              />
            </S.WrapperInput>
          </S.RowInputs>

          <S.WrapperInput>
            <Input
              type="text"
              uppercase={true}
              type_input="normal"
              placeholder="Titular do cartão"
              onChange={(e: any) =>
                setPaymentCard({
                  ...paymentCard,
                  name: e.target.value.toUpperCase(),
                })
              }
              name="name"
              control={control}
              error={errors?.name?.message as string}
            />
          </S.WrapperInput>

          <S.WrapperInput>
            <Input
              type="text"
              type_input="normal"
              placeholder="CPF / CNPJ do titular"
              onChange={(e: any) =>
                setPaymentCard({
                  ...paymentCard,
                  cpfCnpj: e.target.value,
                })
              }
              mask={isCnpjMask(paymentCard.cpfCnpj)}
              maskChar=""
              name="cpfCnpj"
              control={control}
              error={errors?.cpfCnpj?.message as string}
            />
          </S.WrapperInput>

          {showSummary && (
            <S.BoxFee>
              <S.RowFee>
                <strong>Valor do Débito</strong>
                {formatCurrency(truncTwoDecimals(amount))}
              </S.RowFee>
              <S.RowFee>
                <strong>Taxa de Serviço</strong>
                {formatCurrency(taxSearchDebitCar.fee_tax_amount)}
              </S.RowFee>
              <S.RowFee>
                <strong>Total</strong>
                {formatCurrency(taxSearchDebitCar.sum_total_payment)}
              </S.RowFee>
              <S.RowFee>
                <strong>CET</strong>
                {taxSearchDebitCar.cet?.toString().replace(".", ",") + "%"}
              </S.RowFee>
            </S.BoxFee>
          )}
        </S.WrapperRight>
      </S.Row>
    </S.Container>
  );
};
