import React from "react";
import { OrderDetails } from "views/OrderDetails";
import { Routes as RouterRoutes, Route, BrowserRouter } from "react-router-dom";
import { HomeInstallment, Partner, Billet, Home, PixView } from "views";
import NotFound from "views/NotFound";
import PolicyOfPrivatePage from "views/Policys/policy-of-private";
import TermsOfUsePage from "views/Policys/terms-of-use";
import Simulator from "views/Simulator";
import { TransferBank } from "views/Transfer";
import { WhitelabelRoutePrivated } from "./whitelabel-privated.routes";
import { Identifier } from "views/Identifier";

const Routes: React.FC = () => {
  return (
    <BrowserRouter>
      <RouterRoutes>
        <Route
          index
          element={
            <WhitelabelRoutePrivated>
              <Home />
            </WhitelabelRoutePrivated>
          }
        />
        <Route
          element={
            <WhitelabelRoutePrivated>
              <Home />
            </WhitelabelRoutePrivated>
          }
          path="/home"
        />

        <Route
          element={<HomeInstallment />}
          path="/payment_online/:id_usuario?"
        />

        <Route element={<Partner />} path="/parceiro/:id_usuario?" />

        <Route element={<PixView />} path="/pix/:id_usuario?" />

        <Route element={<Billet />} path="/boleto/:id_usuario?" />

        <Route element={<Identifier />} path="/identificador/:id_usuario?" />

        <Route element={<OrderDetails />} path="/checkout/:id_checkout" />

        <Route element={<Simulator />} path="/simulador/:id_usuario?" />

        <Route element={<TransferBank />} path="/transferencia/:id_usuario?" />

        <Route element={<TermsOfUsePage />} path="/termos-de-uso" />
        <Route
          element={<PolicyOfPrivatePage />}
          path="/politica-de-privacidade"
        />

        <Route element={<NotFound />} path="/*" />
      </RouterRoutes>
    </BrowserRouter>
  );
};

export default Routes;
