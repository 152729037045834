import { api } from "services";
import {
  CreateOrUpdateAccountRequest,
  CreateOrUpdateAccountResponse,
} from "./dto/create-or-update-account.dto";
import reCaptcha from "services/RecaptchaService";

export async function createOrUpdateAccount(
  data: CreateOrUpdateAccountRequest,
  id_establishment: string
): Promise<CreateOrUpdateAccountResponse> {
  try {
    const token = await reCaptcha.getRecaptchaToken();

    const response = await api.put<CreateOrUpdateAccountResponse>(
      `/client`,
      data,
      {
        headers: {
          "x-recaptcha-token": token,
          "id-establishment": id_establishment,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
}
