import { init as initApm } from "@elastic/apm-rum";

const serverUrl = process.env.REACT_APP_APM_SERVER_URL?.trim();

export const apm = serverUrl
  ? initApm({
      serverUrl,
      serviceName: "web-portal",
      serviceVersion: "1.0.0",
      environment: process.env.REACT_APP_ENVIRONMENT?.trim() || "development",
      distributedTracingOrigins: [
        "https://sandbox.api.parcelamostudo.tech",
        "https://api.parcelamostudo.tech",
      ],
    })
  : undefined;
