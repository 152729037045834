import React from "react";
import Money from "assets/images/icons/money.svg";
import Money2 from "assets/images/icons/money-2.svg";
import Arrow from "assets/images/icons/arrow-down-what.svg";
import * as S from "./styles";

export const SeeHowSimpleSection: React.FC = () => {
  return (
    <S.Container id="what">
      <S.Title>Veja como é simples</S.Title>
      <S.WrapperCards>
        <S.Card>
          <S.Img src={Money} />
          <S.WrapperTextsCards>
            <S.TitleCard>Parcele qualquer conta</S.TitleCard>
            <S.SubTitleCard>
              Você não vai pagar aquela conta à vista e se apertar o mês todo,
              né? Parcele!
            </S.SubTitleCard>
          </S.WrapperTextsCards>
        </S.Card>
        <S.ImgArrow src={Arrow} />
        <S.Card>
          <S.Img src={Money2} />
          <S.WrapperTextsCards>
            <S.TitleCard>Pequenas parcelinhas</S.TitleCard>
            <S.SubTitleCard>
              É muito melhor pagar um pequeno valor quando precisamos de fôlego,
              não é mesmo?
            </S.SubTitleCard>
          </S.WrapperTextsCards>
        </S.Card>
      </S.WrapperCards>
    </S.Container>
  );
};
