import styled from "styled-components";
import { deviceMaxWidth } from "theme/theme";

export const WrapperGetDataBank = styled.div<{ enable?: boolean }>`
  display: ${(props) => (props.enable ? "flex" : "none")};
  width: 100%;
  justify-content: center;
`;
export const WrapperBox = styled.div<{ enable?: boolean }>`
  display: ${(props) => (props.enable ? "block" : "none")};
  width: 60%;
  height: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
  @media ${deviceMaxWidth.mobileL} {
    width: 100%;
  }
`;

export const WrapperBoxForm = styled.div`
  width: 100%;
  padding: 24px 24px;
  box-sizing: border-box;
`;

export const WrapperBoxFormInput = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;

  @media ${deviceMaxWidth.mobileL} {
    display: flex;
    flex-direction: column;
  }
`;

export const WrapperBoxFormInputDigit = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 4px;
`;

export const WrapperInput = styled.div<{ disabled?: boolean }>`
  display: ${(props) => (props.disabled ? "none" : "block")};
  width: 100%;
  margin-bottom: 18px;
`;

export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: flex-end;
  gap: 8px;

  @media ${deviceMaxWidth.mobileL} {
    flex-direction: column-reverse;
    gap: 4px;
  }
`;

export const Button = styled.div`
  width: 114px;
  align-self: flex-end;
`;

export const TitleForm = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #312f30;
  margin: 0;
  margin-bottom: 21px;
`;

export const Option = styled.option``;

export const WrapperSummary = styled.div<{ enable?: boolean }>`
  display: ${(props) => (props.enable ? "flex" : "none")};
  padding: 8px 4px;
  flex-direction: column;
  justify-content: start;
`;
export const WrapperSummaryItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
`;

export const SummaryItemTitle = styled.p`
  font-family: "Inter";
  font-weight: 700;
`;
export const SummaryItemValue = styled.p`
  background-color: #f2f2f2;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: 100%;
  padding: 8px;
`;
