import styled from "styled-components";
import { deviceMaxWidth } from "theme/theme";

export const UserInformationFormWrapper = styled.div<{ enable?: boolean }>`
  width: clamp(320px, 100%, 800px);
  height: 100%;
  flex-direction: column;
  display: ${({ enable }) => (enable ? "flex" : "none")};

  padding: clamp(8px, 2%, 20px);

  flex-wrap: wrap;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 24px;

  @media ${deviceMaxWidth.tablet} {
    justify-content: center;
  }
`;

export const OrderImg = styled.img``;

export const Title = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  color: #212121;
  padding-left: 24px;
  margin: 0;
`;

export const Body = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 10px;
  height: 120%;
  margin: 30px 0px;
  align-items: center;
  justify-content: space-between;

  @media ${deviceMaxWidth.tablet} {
    justify-content: center;
  }
`;

export const BodyCollum = styled.div`
  width: 100%;
  max-width: 360px;
  height: 100%;
  flex-direction: column;
`;

export const WrapperInput = styled.div`
  display: flex;
  width: auto;
  margin-bottom: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const Option = styled.option``;

export const FooterForm = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: center;
  justify-content: flex-start;

  @media screen and (max-width: 1000px) {
    width: auto;
    margin-bottom: 10px;
  }

  @media screen and (max-width: 400px) {
    width: auto;
  }
`;

export const RowTextsSwitch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: auto;
`;

export const TextSwitchRequired = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: rgba(255, 0, 0, 0.3);
  margin: 0;
  margin-right: 10px;
`;

export const TextSwitchNoRequired = styled.p`
  color: rgba(45, 54, 67, 0.3);
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  padding: 0 8px;
  margin: 0;
  text-align: start;
`;

export const TextSwitch = styled.span`
  padding: 0 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: rgba(45, 54, 67, 1);
  text-align: start;
  display: flex;
  flex-wrap: wrap;

  a {
    margin-left: 5px;
  }

  @media screen and (max-width: 1000px) {
    font-size: 14px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  max-width: 115px;
  min-width: 115px;
  height: auto;
  align-items: flex-end;
  justify-content: start;
  align-self: end;
  margin-top: 20px;
`;

export const CepOrCityInputContainer = styled.div`
  width: 71%;
`;

export const HomeNumberOrStateInputContainer = styled.div`
  width: 25%;
`;
