import { ICurrencyProps } from "models";
import React, { useState } from "react";
import * as S from "./styles";
import { Controller } from "react-hook-form";
import { convertToMoney } from "utils/billet";

export const Currency: React.FC<ICurrencyProps> = ({
  type_input,
  control,
  label,
  error,
  hiden_error,
  textalign = "left",
  border_radius_left = true,
  border_radius_right = true,
  alter_value = true,
  disabledWrite = false,
  ...props
}) => {
  const [showError, setShowError] = useState<boolean>(false);

  const format = (numStr: any) => {
    if (numStr === "") return "";
    return convertToMoney(numStr).toLocaleString("pt-br", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  return (
    <S.WrapperInput>
      {label && <S.Label type_input={type_input}>{label}:</S.Label>}
      <Controller
        control={control}
        name={props.name as string}
        render={({
          field: { onChange, value, name },
          fieldState: { error },
        }) => {
          return (
            <S.Row>
              <S.TextCurrency
                type_input={error ? "error" : type_input}
                value={value}
                disabled={props.disabled}
              >
                R$
              </S.TextCurrency>
              <S.Input
                format={format}
                removeFormatting={(value) => value}
                getCaretBoundary={(value) => [false]}
                placeholder="0,00"
                name={name}
                type_input={error ? "error" : type_input}
                textalign={textalign}
                value={value}
                border_radius_left={border_radius_left as boolean}
                border_radius_right={border_radius_right as boolean}
                onChange={(e: any) => {
                  onChange(e);
                  props.onChange ? props.onChange(e) : null;
                }}
                disabled={props.disabled || disabledWrite}
              />
            </S.Row>
          );
        }}
      />
      <S.TextError onMouseEnter={() => setShowError(!showError)}>
        {hiden_error ? "" : error}
      </S.TextError>
      <S.ErrorShowComplete
        show={showError}
        onMouseOut={() => setShowError(!showError)}
      >
        {hiden_error ? "" : error}
      </S.ErrorShowComplete>
    </S.WrapperInput>
  );
};
