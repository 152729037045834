import React from "react";
import { ContainerTitle, ContainerText, Container } from "./styles";

const TermsOfUse = () => (
  <Container>
    <ContainerTitle>
      <h1>Termos de Uso</h1>
    </ContainerTitle>
    <ContainerText>
      <p>
        Estes Termos de Uso (“Termos de Uso”) estabelecem os termos e condições
        de acesso e uso do serviço de assinatura digital de contratos e/ou
        outros documentos eletrônicos oferecido por PARCELAMOS TUDO PONTOCOM
        SOLUCOES EM PAGAMENTO LTDA, sociedade empresária limitada inscrita no
        CNPJ/MF sob o nº 36.063.350/0001-44, com sede na Rua Iguatemi, n.º 354,
        Itaim Bibi, São Paulo – SP, CEP 01.451-010, neste ato representada na
        forma de seu estatuto social.
        <h2>1. OBJETO</h2>
        1.1 Estes Termos de Uso regulam o acesso e uso do Serviço pelo
        correspondente usuário (“Usuário”) quando o aplicativo de assinatura
        digital (“Aplicativo”) e/ou o website (“Website”) da Parcelamos Tudo
        e/ou outros softwares, serviços, plataformas, ferramentas, conteúdos
        e/ou recursos correlatos são instalados, acessados e/ou utilizados.
        <p>
          1.2 O USUÁRIO MANIFESTARÁ ELETRONICAMENTE SUA ACEITAÇÃO ÀS CONDIÇÕES
          DESTES TERMOS DE USO E DA CORRESPONDENTE POLÍTICA DE PRIVACIDADE E
          SEGURANÇA DE DADOS AO SELECIONAR A OPÇÃO “LI E CONCORDO COM OS TERMOS
          DE USO DESSES SERVIÇOS” DISPONÍVEL NAS PÁGINAS DE CADASTRO DO
          APLICATIVO (“ACEITE ELETRÔNICO”).
        </p>
        <p>
          1.3 A aposição do Aceite Eletrônico pelo Usuário implica em sua
          aceitação expressa, automática, plena, sem reservas ou ressalvas, de
          todas as disposições destes Termos de Uso e da Política de Privacidade
          e Segurança de Dados aplicável ao Serviço, conforme eventualmente
          alterados.
        </p>
        <p>
          1.4 Os Termos de Uso e a correspondente Política de Privacidade e
          Segurança de Dados aplicável ao Serviço estarão disponíveis nos canais
          eletrônicos de acesso e uso do Aplicativo, inclusive via celular e
          Website.
        </p>
        <p>
          1.5 Estes Termos de Uso, além de regularem contratualmente a relação
          entre as partes, devem ser entendidos também como licença de uso dos
          softwares vinculados ao Serviço, os quais são regidos pelo direito
          autoral e por demais leis aplicáveis. Para a utilização de qualquer
          forma do Serviço, é obrigatório aceitar estes Termos de Uso e a
          respectiva Política de Privacidade e Segurança de Dados. O acesso ou
          uso do Serviço sem esta aceitação ou mediante infração constitui não
          apenas violação destes Termos de Uso, como também violação do direito
          autoral relacionado ao Serviço.
        </p>
        <p>
          1.6 A Parcelamos Tudo se reserva o direito de, a seu exclusivo
          critério e sem aviso prévio, modificar os Termos de Uso e a Política
          de Privacidade e Segurança de Dados, incluindo novas condições e/ou
          restrições, devendo comunicar isto ao Usuário através do Aplicativo
          e/ou do Website. A continuidade do acesso e/ou uso do Serviço pelo
          Usuário implicará em automática aceitação de eventuais atualizações
          destes.
        </p>
        <p>
          1.7 A versão válida e eficaz destes Termos de Uso é aquela publicada
          atualmente pelo Aplicativo e/ou pelo Website, respeitados direitos
          adquiridos, atos jurídicos perfeitos e coisas julgadas.
        </p>
        <p>
          1.8 O Usuário deverá sempre ler atentamente estes Termos de Uso e a
          Política de Privacidade e Segurança de Dados e não poderá escusar-se
          deles alegando ignorância sobre ao seu teor, inclusive quanto a
          eventuais modificações.
        </p>
        <p>
          1.9 Certos serviços e conteúdos oferecidos com relação ao Serviço
          podem ser objeto de termos de uso específicos. Neste caso, os termos
          de uso específico podem substituir, complementar ou modificar estes
          Termos de Uso. No caso de conflito entre estes Termos de Uso e termos
          de uso específicos aplicáveis à prestação do Serviço, as condições
          especiais deverão prevalecer sobre as gerais. As condições especiais
          também deverão sempre ser lidas pelo Usuário. Outros avisos e
          comunicados poderão ser realizados ao longo da prestação do Serviço,
          os quais serão considerados condições especiais e prevalecerão sobre
          estes Termos de Uso.
        </p>
        <h2>2. DISPONIBILIDADE DOS SERVIÇOS</h2>
        2.1 A Parcelamos Tudo envidará seus melhores esforços para assegurar e
        desenvolver a qualidade dos Serviços.
        <p>
          2.2 A Parcelamos Tudo se compromete a empregar seus melhores esforços,
          dentro de razoabilidade técnica e econômica, para manter os Serviços
          disponíveis aos Usuários ininterruptamente e realizar paradas
          programadas para manutenção fora do horário comercial. Entretanto,
          poderão existir situações fora do controle razoável da Parcelamos Tudo
          que venham a determinar de forma imprevista a suspensão da
          disponibilidade dos Serviços, tais como casos fortuitos ou de força
          maior.
        </p>
        <p>
          2.3 Para uso do Serviço, os Usuários deverão providenciar, por sua
          própria iniciativa e custo, acesso à Internet e telefones celulares
          compatíveis.
        </p>
        <h2>3. RESPONSABILIDADES DO USUÁRIO PELO USO DOS SERVIÇOS</h2>
        3.1 Ao se cadastrar, o Usuário procederá à abertura de uma conta para
        sua identificação perante a Parcelamos Tudo (“Conta”). O Usuário deverá
        ser maior de idade e/ou emancipado, sendo que a responsabilidade por
        qualquer falsidade, inexatidão ou omissão de dados na abertura e
        manutenção da Conta é exclusivamente do Usuário.
        <p>3.2 O Usuário compreende e reconhece que:</p>
        <p>
          3.2.1 Possui capacidade jurídica para celebrar estes Termos de Uso e
          utilizar o Serviço, sendo que menores de idade deverão ser assistidos
          ou representados por seus pais, tutores ou curadores, na forma da lei,
          os quais serão considerados responsáveis por todos os atos praticados
          pelos menores;
        </p>
        <p>
          3.2.2 Toda informação oferecida pelo Usuário para acessar e usar o
          Serviço deverá ser verdadeira;
        </p>
        <p>
          3.2.3 O Usuário garante a autenticidade de todos os dados que informar
          através do preenchimento dos formulários disponibilizados e é de sua
          exclusiva responsabilidade manter qualquer informação fornecida à
          Parcelamos Tudo permanentemente atualizada de forma a sempre refletir
          os dados reais do Usuário;
        </p>
        <p>
          3.2.4 O fornecimento de declarações falsas ou inexatas constitui
          violação destes Termos de Uso, bem como revogação da licença de
          utilização do Serviço, além de poder configurar em crimes de
          identidade falsa (artigo 307 do Código Penal) ou falsidade ideológica
          (artigo 299 do Código Penal);
        </p>
        <p>
          3.2.5 O Usuário será sempre o único e exclusivo responsável pela sua
          conduta e por danos por ela causados no âmbito da prestação do
          Serviço, quanto a ele e quanto a terceiros, sendo também o único
          responsável pelo uso de seu celular para acesso à Conta e ao Serviço,
          sendo esta ação pessoal e intransferível, devendo o Usuário indenizar
          aqueles que sofrerem danos e/ou prejuízos pela utilização incorreta
          e/ou fraudulenta dos seus aparelhos;
        </p>
        <p>
          3.2.6 A Parcelamos Tudo poderá averiguar as informações prestadas pelo
          Usuário e, caso detecte qualquer irregularidade, poderá suspender o
          seu acesso até que tal irregularidade seja corrigida e/ou expedir
          aviso de suspensão de acesso;
        </p>
        <p>
          3.2.7 Ao utilizar o Serviço, tanto o Usuário quanto os terceiros
          envolvidos na assinatura digital de contratos e/ou outros documentos
          (“Signatários”) enviarão à Parcelamos Tudo informações (incluindo, sem
          limitação, dados e fotos pessoais), autorizando a Parcelamos Tudo a
          coletar e usar as informações fornecidas para a administração de sua
          base de dados, bem como para registrá-las e armazená-las em suporte
          físico ou intangível, incluindo, mas não se limitando a arquivamento
          magnético, eletrônico, digital, e/ou qualquer outro meio possível de
          armazenamento de informação;
        </p>
        <p>
          3.2.8 Todos os direitos, evidências, indícios, atestados, registros,
          provas técnicas e demais resultados decorrentes da utilização dos
          Serviços são estendidos aos Signatários dos contratos e/ou outros
          documentos;
        </p>
        <p>
          3.2.9 As comunicações e transações eletrônicas entre Usuários,
          Signatários e Parcelamos Tudo poderão se constituir em evidências
          probantes e materializadas dos atos perpetrados com utilização dos
          Serviços;
        </p>
        <p>
          3.2.10 Os presentes Termos de Uso vinculam Usuários a Signatários;
        </p>
        <p>
          3.2.11 Leu, entendeu e está de pleno acordo com todas as condições
          destes Termo de Uso.
        </p>
        <p>
          3.3 Usuário poderá utilizar os Serviços enquanto houver créditos na
          sua Conta.
        </p>
        <p>
          3.4 O Usuário se compromete a utilizar o Serviço de boa-fé e em
          conformidade com todos os preceitos legais, regulamentares e
          contratuais porventura aplicáveis, bem como com a ordem pública, a
          moral e os bons costumes e as normas de conduta geralmente aceitas.
          Qualquer violação a este item poderá sujeitar o Usuário à exclusão da
          lista de usuários do Serviço, sem prejuízo de outras sanções
          administrativas, civis e/ou penais eventualmente aplicáveis.
        </p>
        <h2>4. SEGURANÇA E PRIVACIDADE DE DADOS E DOCUMENTOS</h2>
        4.1 A Parcelamos Tudo se obriga a, por meio de mecanismos físicos e
        tecnológicos, não divulgar os dados cadastrais dos Usuários e dos
        Signatários. Tais mecanismos atendem padrões razoáveis de cuidado,
        considerando-se as possibilidades técnicas e economicamente razoáveis da
        tecnologia aplicável à Internet, como o uso de firewall para proteção
        contra acessos indevidos. No entanto, a Parcelamos Tudo recomenda
        fortemente que os Usuários adotem medidas de segurança em seu celular,
        tais como a instalação de programa antivírus e de firewall contra
        invasões.
        <p>
          4.2 Para a prestação do Serviço a Parcelamos Tudo adota níveis de
          segurança dentro dos requisitos legais para a proteção dos dados
          pessoais. Entretanto, é importante que o usuário tenha ciência de que
          pela própria natureza e características técnicas da Internet, essa
          proteção não é infalível e encontra-se sujeita à violação pelas mais
          diversas práticas maliciosas. Os tratamentos de dados pessoais
          conduzidos pela Parcelamos Tudo são regidos por sua política de
          privacidade. Os tratamentos de dados pessoais conduzidos pela
          Parcelamos Tudo para a prestação do Serviço são regidos por sua
          Política de Privacidade e Segurança de Dados.
        </p>
        <h2>5. LIMITAÇÕES E EXCLUSÃO DE GARANTIAS E DE RESPONSABILIDADES</h2>
        5.1 Por razões técnicas e de operação, não é possível garantir a
        disponibilidade e continuidade das plataformas de prestação do Serviço,
        bem como evitar sua falibilidade.
        <p>
          5.2 A Parcelamos Tudo não será responsável, em nenhuma hipótese, por
          danos ocorridos aos Usuários e/os Signatários decorrentes de qualquer
          causa fora do controle razoável de Parcelamos Tudo, tais como, mas não
          se limitando a:
        </p>
        <p>
          5.2.1 Divergência de dados dos Usuários e/ou Signatários que
          impossibilite o uso dos Serviços;
        </p>
        <p>
          5.2.2 Utilização de celulares que impeçam a realização de assinaturas
          digitais mediante uso dos Serviços;
        </p>
        <p>
          5.2.3 Falha de comunicação com a Internet por parte dos Usuários e/ou
          Signatários;
        </p>
        <p>5.2.4 Problemas no processamento de pagamento dos Serviços;</p>
        <p>5.2.5 Casos fortuitos ou de força maior.</p>
        <p>
          5.3 A Parcelamos Tudo não se responsabiliza por quaisquer custos,
          prejuízos ou danos que sejam causados aos Usuários, Signatários ou
          quaisquer terceiros em decorrência da utilização dos Serviços e/ou
          pelo conteúdo e finalidade dos contratos e/ou outros documentos
          eletrônicos que sejam assinados através do Serviço e, por esta razão,
          não controla, verifica nem atesta o teor ou a legalidade de tal
          conteúdo, que é de responsabilidade total e exclusiva do Usuário e dos
          Signatários.
        </p>
        <p>
          5.4 A Parcelamos Tudo não se responsabiliza por quaisquer custos,
          prejuízos ou danos de qualquer natureza que possam decorrer da
          incorreta identidade do Usuário, nos casos de utilização indevida e/ou
          fraudulenta dos celulares com os quais será feita a assinatura de
          contratos e/ou outros documentos.
        </p>
        <p>
          5.5 A Parcelamos Tudo não tem obrigação de controlar e não controla,
          autoriza nem se responsabiliza caso o Usuário venha a utilizar os
          Serviços: (i) para quaisquer fins ou meios ilegais, difamatórios,
          discriminatórios, ofensivos, obscenos, injuriosos, caluniosos,
          violentos, ou de assédio, ameaça ou uso de falsa identidade; (ii) cujo
          conteúdo seja ilegal ou em violação da moral, bons costumes ou da
          ordem pública.
        </p>
        <p>
          5.6 A Parcelamos Tudo igualmente não se responsabiliza pelos
          resultados pretendidos ou verificados com a utilização dos Serviços.
        </p>
        <p>
          5.7 A Parcelamos Tudo não realizará a guarda nem o arquivamento de
          qualquer contrato e/ou outro documento inserido (uploaded) para fins
          de assinatura por meio do Aplicativo. Por esta razão, o Usuário e/ou
          os Signatários dos contratos e/ou outros documentos deverão tomar
          todas as medidas necessárias para a guarda e arquivamento dos
          contratos e/ou outros documentos assinados através do Aplicativo.
        </p>
        <p>
          5.8 O Usuário não poderá de nenhuma forma empregar mecanismos técnicos
          que de qualquer forma subvertam a regular utilização do Serviço, sem a
          prévia consulta aos gestores da Parcelamos Tudo. Esses mecanismos
          incluem a utilização de robôs (robots, ou bots), spiders, scripts ou
          qualquer outra forma de acesso automatizado que de alguma forma sirva
          para desvirtuar suas finalidades e propósitos. O emprego desses
          recursos sem autorização prévia implica violação destes Termos de Uso
          e sujeita a parte infringente ao pagamento de danos emergentes e
          lucros cessantes, bem como de multa punitiva que se aplicará mesmo que
          não tenha sido produzido qualquer dano, a ser arbitrada pelo juízo que
          for responsável pelo julgamento da causa.
        </p>
        <p>
          5.9 O Usuário garante e certifica que, ao acessar ou usar o Serviço,
          não está infringindo quaisquer direitos legais, contratuais ou de
          terceiros, bem como quaisquer outros. Quaisquer reclamações de
          terceiros serão de responsabilidade do Usuário, que deverá assumir
          todas e quaisquer responsabilidades legais e processuais com relação à
          reclamação. O Usuário fica ciente que a Parcelamos Tudo denunciará
          todas e quaisquer lides, litígios e disputas no qual for envolvida por
          conta de conteúdos e condutas submetidos ou perpetradas por Usuários
          ou terceiros, na medida permitida pela lei, de modo a não mais ser
          parte da lide, litígio ou disputa. A Parcelamos Tudo também se faculta
          o direito de chamar ao processo qualquer Usuário, em razão de
          quaisquer ações originárias de sua conduta e/ou conteúdo submetido.
          Faculta-se também o direito de responsabilizar diretamente o Usuário
          perpetrador da conduta abusiva, valendo-se para isso de todas os
          recursos legalmente possíveis, incluindo direito de regresso, dentre
          outros. Para tanto, a Parcelamos Tudo irá valer-se dos dados de
          registro bem como quaisquer outros dados técnicos que permitam
          identificar o Usuário, ficando desde já ciente disso o Usuário ou
          terceiros afetados, que não poderão alegar qualquer violação de
          privacidade nesses casos. A Parcelamos Tudo se reserva também o
          direito de cancelar o registro a qualquer tempo de qualquer Usuário,
          por sua única e exclusiva discricionariedade, sem qualquer aviso
          prévio, sempre que for inobservado pelo Usuário o disposto nestes
          Termos de Uso.
        </p>
        <p>
          5.10 Todas as marcas, nomes comerciais e sinais distintivos de
          qualquer espécie presentes nas plataformas de acesso e uso do Serviço,
          incluindo sua própria marca e designação, são pertencentes a seus
          respectivos titulares de direito. Para a utilização de quaisquer
          destas marcas, nomes e sinais, é necessário a obtenção da respectiva
          autorização dos seus titulares.
        </p>
        <h2>6. COMUNICAÇÃO COM A PARCELAMOS TUDO</h2>
        6.1 Todas as notificações entre Usuário e Parcelamos Tudo deverão ser
        feitas única e exclusivamente através do canal de contato com a equipe
        de administração do site.
        <p>
          6.2 Todas as notificações e comunicações por parte da Parcelamos Tudo
          ao Usuário serão consideradas válidas e eficazes, para todos os
          efeitos, quando se derem através de qualquer das seguintes formas:
        </p>
        <p>
          1. envio de mensagem por correio eletrônico a qualquer endereço
          eletrônico fornecido pelo Usuário;
        </p>
        <p>
          2. envio de carta ao domicílio do Usuário quando este tiver fornecido
          um endereço;
        </p>
        <p>
          3. comunicação telefônica ao número fornecido pelo Usuário; e/ou
          através de mensagens postadas nas plataformas de acesso ao Serviço.
        </p>
        <p>
          6.2.1 Neste sentido, todas as notificações que a Parcelamos Tudo
          realizar serão consideradas válidas quando efetuadas empregando os
          dados e através de qualquer dos meios anteriormente destacados. Para
          estes efeitos, o Usuário declara que todos os dados fornecidos são
          válidos e corretos, e como tais serão considerados, comprometendo-se a
          comunicar e atualizar junto à plataforma Parcelamos Tudo todas as
          mudanças relativas a seus dados pessoais. obrigação de sigilo se
          manterá válida inclusive quando do término por qualquer motivo deste
          Contrato. A não observância dos requerimentos mencionados nesta
          cláusula sujeitará o Cliente ao pagamento de indenização nos termos
          deste Contrato e às sanções e pagamento das multas e/ou Perdas, sem
          prejuízo das demais medidas asseguradas em lei às Partes e aos
          terceiros prejudicados.
        </p>
        <h2>7. VIGÊNCIA</h2>
        7.1 O presente Termo tem vigência por prazo indeterminado, tendo seu
        início a partir da data do Aceite Eletrônico por parte do Usuário.
        <h2>8. ENCERRAMENTO DOS SERVIÇOS</h2>
        8.1 A Parcelamos Tudo poderá alterar, adicionar, excluir, interromper ou
        suspender a prestação dos Serviços a qualquer momento desde que
        comunique o Usuário mediante aviso eletrônico, respeitado o direito de
        utilização dos créditos existentes na Conta do Usuário à época do
        cancelamento ou a restituição de tais créditos ao Usuário.
        <p>
          8.2 Será considerado motivo justo para a rescisão imediata e
          unilateral destes Termos de Uso, por parte de Parcelamos Tudo, se a
          utilização dos Serviços ocorrer em violação às disposições do presente
          instrumento, ou ainda, se o Usuário ou os Signatários infringirem o
          ordenamento jurídico vigente, incluindo, mas não se limitando, às
          condutas previstas na cláusula 5.5 deste Termo, sem direito a qualquer
          restituição de créditos do Usuário.
        </p>
        <h2>9. CONSIDERAÇÕES GERAIS</h2>
        9.1 Estes Termos de Uso representam a totalidade da avença entre
        Usuários, Signatários e Parcelamos Tudo, revogando todo e qualquer
        acordo oral ou escrito, anterior ou contemporâneo, firmado entre estes
        com relação ao objeto dos presentes Termos de Uso.
        <p>
          9.2 Usuários, Signatários e Parcelamos Tudo aceitam a força probante,
          validade e eficácia de comunicações eletrônicas para todos os fins e
          efeitos destes Termos de Uso.
        </p>
        <p>
          9.3 Estes Termos de Uso obrigam Usuários, Signatários e Parcelamos
          Tudo e seus sucessores. Sem prejuízo da disposição anterior, o Usuário
          não poderá ceder a terceiros quaisquer direitos decorrentes destes
          Termos de Uso, em nenhuma hipótese. A Parcelamos Tudo poderá, a
          qualquer tempo e a seu exclusivo critério, ceder ou transferir, total
          ou parcialmente, os direitos e obrigações decorrentes do presente
          Contrato.
        </p>
        <p>
          9.4 Nenhuma alteração ou renúncia de direitos relativa aos presentes
          Termos de Uso será válida exceto se expressamente negociada e assinada
          entre Usuários e Parcelamos Tudo. A omissão, por qualquer destes, em
          exigir do outro o cumprimento de qualquer obrigação prevista neste
          instrumento não implica em renúncia ao respectivo direito.
        </p>
        <p>
          9.5 A nulidade de quaisquer disposições dos presentes Termos de Uso
          não prejudicará a validade das demais. Caso qualquer disposição destes
          Termos de Uso seja anulada, declarada nula ou inexigível, no todo ou
          em parte, este documento será considerado modificado com a exclusão ou
          modificação, na extensão necessária, da disposição nula, anulada ou
          inexigível, de modo que se preserve os Termos de Uso como válidos e,
          na medida do possível, de forma consistente com a intenção original
          dos Usuários e da Parcelamos Tudo.
        </p>
        <p>
          9.6 As Partes elegem o foro Comarca da cidade de São Paulo, do Estado
          de São Paulo, como o único competente para dirimir as questões
          decorrentes destes Termos de Uso com renúncia expressa a qualquer
          outro, por mais privilegiado que seja.
        </p>
      </p>
    </ContainerText>
  </Container>
);

export default TermsOfUse;
