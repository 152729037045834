import styled, { css } from "styled-components";
import { deviceMaxWidth } from "theme/theme";

export const SearchWrapper = styled.div<{ enable?: boolean }>`
  width: auto;
  height: auto;
  padding: 20px;
  box-sizing: border-box;
  margin-bottom: 30px;

  display: ${({ enable }) => (enable ? "flex" : "none")};
`;

export const WrapperBox = styled.div`
  width: 100%;
  height: 100%;

  box-sizing: border-box;
`;

export const TitleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;

  @media ${deviceMaxWidth.tablet} {
    justify-content: center;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
`;

export const OrderImg = styled.img``;

export const Title = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  color: #212121;
  padding-left: 24px;
  margin: 0;
`;

export const TitleForm = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  color: #312f30;
  margin: 0;
  margin-bottom: 21px;
`;

export const WrapperBoxForm = styled.div`
  width: 100%;
  padding: 24px 24px;
  box-sizing: border-box;
`;

export const WrapperInput = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: flex-end;
`;

export const Button = styled.div`
  width: 114px;
  align-self: flex-end;
`;

export const Button2 = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export const ResumeWrapper = styled(SearchWrapper)`
  width: 330px;
`;

export const WrapperValue = styled.div<{ value?: number | boolean }>`
  width: 100%;
  border: 1px dashed rgba(33, 33, 33, 0.12);
  border-radius: 4px;
  padding: 10px 10px;
  background-color: rgba(255, 0, 0, 0.05);

  ${({ value }) =>
    value === 0 &&
    css`
      background: rgba(0, 146, 84, 0.1);
    `};

  ${({ value }) =>
    value === false &&
    css`
      background: #fff;
    `};
`;

export const LabelValue = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #212121;
  margin: 0;
  padding-bottom: 5px;
`;

export const Value = styled.div<{ value?: number | boolean }>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  color: #e92215;
  margin: 0;

  ${({ value }) =>
    value === 0 &&
    css`
      color: #009254;
    `};

  ${({ value }) =>
    value === false &&
    css`
      color: #212121;
    `};
`;

export const Dash = styled.div`
  border: 1px dashed #000000;
  margin-bottom: 24px;
`;

export const IdentifyYourself = styled.img``;

export const WrapperIdentifyTexts = styled.div`
  padding-left: 10px;
  padding-right: 10px;
`;

export const TitleIdentify = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #212121;
  margin: 0;
  margin-bottom: 9px;
  text-align: left;
`;

export const SubTitleIdentify = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #212121;
  margin: 0;
  text-align: left;
`;

export const IdentifyYourselfButton = styled.button`
  margin: 0;
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;

export const PaymentWrapper = styled(SearchWrapper)`
  width: clamp(350px, 100%, 800px);
`;

export const WrapperTypePayment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
`;

export const WrapperRadio = styled.div<{ disabled?: boolean }>`
  margin-right: 30px;
  pointer-events: ${(props) => (props.disabled ? "none" : null)};
  opacity: ${(props) => (props.disabled ? "0.5" : null)};

  @media ${deviceMaxWidth.mobileL} {
    display: ${(props) => (props.disabled ? "none" : "flex")};
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const TitleModal = styled.h1``;
export const TitleItemsModal = styled.h3``;

export const TextModal = styled.p``;

export const List = styled.ul``;

export const ListItem = styled.li``;

export const ContainerQrCode = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ContainerQrCodeTimer = styled.div`
  background-color: #f2f2f2;
  padding: 8px;
  border-radius: 4px;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 4px;
`;

export const TextCopy = {
  padding: 4,
  paddingLeft: 12,
  paddingRight: 12,
  background: "#d3d3d3",
  borderRadius: 8,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  fontWeight: 700,
};

export const ButtonPayNow = styled.div`
  width: 165px;
  align-self: flex-end;
  margin-top: 30px;
`;

export const PixInformationContainer = styled.div<{ enable?: boolean }>`
  display: ${({ enable }) => (enable ? "flex" : "none")};
  width: 100%;
  height: auto;
  border-radius: 4px;
  border: 2px solid #91caff;
  background: #e6f4ff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const InfoHeaderRow = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px 20px;
`;

export const InfoTextRow = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 10px;
`;

export const InfoHeader = styled.h2`
  color: #000;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
`;

export const InfoImg = styled.img`
  width: 32px;
  height: 32px;
  margin-right: 10px;
`;

export const InfoText = styled.h2`
  color: #000;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: -0.3px;
  padding-left: 60px;
`;
