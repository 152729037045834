import {
  CreateCheckoutOrder3dsResponse,
  CreateCheckout,
  CreateCheckout3DS,
  CreateCheckoutResponse,
  CreateClientRequest,
  CreateClientResponse,
  CreateCheckout3dsConfirm,
} from "./type";
import reCaptcha from "../RecaptchaService";
import { api } from "services/api";

class PublicBffService {
  private baseUrl: string;

  constructor() {
    this.baseUrl = process.env.REACT_APP_PARCELAMOS_API_URL ?? "";
  }

  async createClient(
    data: CreateClientRequest,
    id_establishment: string
  ): Promise<CreateClientResponse> {
    const token = await reCaptcha.getRecaptchaToken();

    const response = await fetch(`${this.baseUrl}/public/client`, {
      method: "PUT",
      body: JSON.stringify(data),
      headers: {
        "Api-version": "1",
        "Content-Type": "application/json",
        accept: "application/json",
        "x-recaptcha-token": token,
        "id-establishment": id_establishment,
      },
    });

    const data_response = response.json();

    return data_response;
  }
  async createCheckout({
    payload,
    id_establishment,
    browserInfoHeaders,
    token
  }: CreateCheckout): Promise<CreateCheckoutResponse> {
    const headers = {
      "id-establishment": id_establishment,
      "color-depth": browserInfoHeaders?.browser_color_depth,
      "screen-height": browserInfoHeaders?.browser_screen_height,
      "screen-width": browserInfoHeaders?.browser_screen_width,
      "tz": browserInfoHeaders?.browser_tz,
      'user-agent': browserInfoHeaders?.browser_user_agent,
      'java-enabled': browserInfoHeaders?.browser_java_enabled,
      'javascript-enabled': browserInfoHeaders?.browser_javascript_enabled,
      'accept-language': browserInfoHeaders?.browser_language,
      'x-recaptcha-token': token
    }

    const response = await api.post("/checkout", payload, {
      headers,
    });

    return response.data;
  }

  async createCheckoutOrder3ds({
    payload,
    id_establishment,
    token
  }: CreateCheckout3DS) {
    const response = await api.post<CreateCheckoutOrder3dsResponse>(
      "/charge/order/3ds",
      payload,
      {
        headers: {
          "id-establishment": id_establishment,
          'x-recaptcha-token': token
        },
      }
    );

    return response.data;
  }

  async confirmCheckout3ds({
    payload,
    id_establishment,
    id_checkout,
    browserInfoHeaders,
    token
  }: CreateCheckout3dsConfirm): Promise<CreateCheckoutResponse> {
    const headers = {
      "id-establishment": id_establishment,
      "color-depth": browserInfoHeaders?.browser_color_depth,
      "screen-height": browserInfoHeaders?.browser_screen_height,
      "screen-width": browserInfoHeaders?.browser_screen_width,
      "tz": browserInfoHeaders?.browser_tz,
      'user-agent': browserInfoHeaders?.browser_user_agent,
      'java-enabled': browserInfoHeaders?.browser_java_enabled,
      'javascript-enabled': browserInfoHeaders?.browser_javascript_enabled,
      'accept-language': browserInfoHeaders?.browser_language,
      'x-recaptcha-token': token
    }

    const response = await api.post(`/checkout/${id_checkout}/3ds/confirm`, payload, {
      headers,
    });

    return response.data;
  }
}

export default new PublicBffService();
