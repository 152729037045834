import { api } from "services";
import { GetCheckoutInfoResponse } from "./dto/get-checkout-info.dto";
import { useQuery } from "react-query";
import reCaptcha from "services/RecaptchaService";

async function getCheckoutInfo(
  id_checkout: string
): Promise<GetCheckoutInfoResponse> {
  try {
    const token = await reCaptcha.getRecaptchaToken();

    const response = await api.get<GetCheckoutInfoResponse>(
      `/checkout/${id_checkout}`,
      {
        headers: {
          "x-recaptcha-token": token,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
}

export const useGetCheckoutInfo = (id_checkout: string, enabled: boolean) => {
  return useQuery(
    ["get-checkout-info", id_checkout],
    () => getCheckoutInfo(id_checkout),
    {
      staleTime: 1000 * 15,
      enabled,
      refetchInterval: 15000,
    }
  );
};
