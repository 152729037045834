import React from "react";
import { useWhitelabel } from "hooks";
import { Navigate } from "react-router-dom";

interface IWhitelabelRoutePrivated {
  children: JSX.Element;
}

export const WhitelabelRoutePrivated: React.FC<IWhitelabelRoutePrivated> = ({
  children,
}) => {
  const { isActived, client } = useWhitelabel();

  if (isActived) {
    return <Navigate to={`/payment_online/${client?.id_usuario}`} replace />;
  }

  return children;
};
