import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 0px 84px;
  box-sizing: border-box;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media screen and (max-width: 598px) {
    padding: 0px 20px;
  }
`;
export const Bg = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  top: 0;
  z-index: -10;
`;

export const Title = styled.p`
  min-width: 300px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  margin: 0;
  margin-top: 48px;

  color: #18191f !important;

  @media screen and (max-width: 598px) {
    font-size: 36px;
    min-width: 0px;
  }
`;

export const Title2 = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  margin: 0;

  font-feature-settings: "salt" on, "liga" off;

  background: linear-gradient(153.22deg, #ffc706 0%, #adff00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media screen and (max-width: 400px) {
    font-size: 36px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 86px;
  align-items: center;
  @media screen and (max-width: 598px) {
    flex-direction: column;
  }
`;

export const Left = styled.div`
  width: 40%;
  height: 100%;
  @media screen and (max-width: 598px) {
    width: 100%;
  }
`;

export const Right = styled.div`
  width: 60%;
  height: 100%;
  @media screen and (max-width: 598px) {
    width: 100%;
  }
`;

export const RightImage = styled.img`
  width: 100%;
  @media screen and (max-width: 598px) {
    margin-top: 24px;
  }
`;

export const LeftTitle = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #444549;
  margin: 0;
`;

export const LeftSubTitle = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  color: rgba(49, 47, 48, 0.8);
  margin: 0;
  margin-top: 24px;
`;

export const TextDownload = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  color: rgba(49, 47, 48, 0.8);
  margin: 0;
  margin-top: 94px;
`;

export const LinkStore = styled.a`
  text-decoration: none;
`;

export const ButtonApple = styled.button`
  width: 50%;
  border: 0;
  cursor: pointer;
  margin-top: 24px;
`;

export const ButtonAndroid = styled.button`
  width: 50%;
  border: 0;
  cursor: pointer;
  margin-top: 24px;
`;

export const IconStore = styled.img`
  width: 100%;
`;
