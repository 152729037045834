import { api } from "services/api";
import { useMutation } from "react-query";
import { OptionUseMutation } from "config/react-query";
import { id_establishment_default } from "config/establishment";
import reCaptcha from "services/RecaptchaService";
import {
  CreateTransferRequest,
  CreateTransferResponse,
} from "./dto/create-transfer-bank.dto";

export const useCreateTransferBank = (
  options?: OptionUseMutation<CreateTransferResponse>
) => {
  return useMutation("get-debits", createTransferBank, options);
};

async function createTransferBank(
  data: CreateTransferRequest
): Promise<CreateTransferResponse> {
  const token = await reCaptcha.getRecaptchaToken();

  const response = await api.post("/transfer/", data, {
    headers: {
      "id-establishment": id_establishment_default,
      "x-recaptcha-token": token,
    },
  });

  return response.data;
}
