import styled, { css } from "styled-components";

export const WrapperRadio = styled.button`
  background-color: transparent;
  border: 0;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
`;

export const Circle = styled.div`
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  background-color: #f9fafb;
  border: 1px solid #afbaca;
  border-radius: 22px;
`;

export const CircleActive = styled.img`
  width: 20px;
  height: 20px;
`;

export const TextRadio = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #2d3643;
  padding-left: 8px;
  margin: 0;
`;
