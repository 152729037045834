import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 40px;
  width: 40px;

  border-radius: 24px;
`;

export const Title = styled.span`
  font-weight: 600;
  font-size: 17px;
  line-height: 20.4px;
  color: black;
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 16.8px;
  color: #6b7280;
`;
