import React, { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { createPortal } from "react-dom";
import LoadingOverlay from "react-loading-overlay";
import { UseQueryOptions, useQueries } from "react-query";

import { IStagesBillet } from "models";
import usePayment from "hooks/usePayment";
import { Button, BankslipItem } from "components";
import {
  HandleError,
  Pix,
  getPixPublic,
} from "services";

import Order1 from "assets/images/icons/order-1.svg";
import * as S from "./styles";

const THREE_MINUTES_IN_MILISECONDS = 60 * 3 * 1000;

export const SearchPix: React.FC<IStagesBillet> = ({
  setStage,
  enable,
  idEstablishment,
}) => {
  const {
    dataPaymentBillet,
    setPaymentBilletData,
    updateRulesFromQuery,
  } = usePayment();

  const queryParameters = new URLSearchParams(window.location.search);
  const code = queryParameters.get("code");
  const [codeQueries, setCodeQueries] = useState<
    UseQueryOptions<Pix & HandleError>[]
  >([]);

  const codeList = useMemo(() => {
    const codes = code?.split(";");
    return Array.from(new Set(codes));
  }, [code]);

  useEffect(() => {
    const fixedInstallmentParam = queryParameters.get("fixedInstallment");
    const fixedInstallment = fixedInstallmentParam
      ? Number(fixedInstallmentParam)
      : undefined;
    updateRulesFromQuery({
      fixedInstallment: Number.isNaN(fixedInstallment)
        ? undefined
        : fixedInstallment,
    });
  }, []);

  const codeResponses = useQueries(codeQueries);

  const isLoading: boolean =
    codeResponses.some((code) => code.isLoading) ||
    codeResponses.some((code) => code.isFetching);


  useEffect(() => {
    if (codeQueries.length) return;

    const codesLength = codeList.length;

    if (codesLength) {
      setCodeQueries(
        codeList.map((emv) => ({
          queryKey: ["code", emv],
          queryFn: () => getPixPublic(emv, idEstablishment),
          staleTime: THREE_MINUTES_IN_MILISECONDS,
          refetchOnWindowFocus: false,
          refetchOnMount: false,
          retry: false,
        }))
      );
    }
  }, []);

  useEffect(() => {
    const allCodesIsFetched = codeResponses.every(
      (codeItem) => codeItem.isFetched
    );

    if (allCodesIsFetched) {
      const codesSuccessfuly = codeResponses
        .filter((codeItem) => codeItem.isSuccess)
        .map((codeItem) => codeItem.data);

      setPaymentBilletData({
        ...dataPaymentBillet,
        pix: [
          ...dataPaymentBillet.pix,
          ...(codesSuccessfuly as Pix[]),
        ],
      });
    }
  }, [codeResponses.every((item) => item.isFetched)]);

  return (
    <S.SearchWrapper enable={enable}>
      {createPortal(
        <LoadingOverlay
          active={isLoading}
          text="Carregando seus pix..."
          spinner
          styles={{
            wrapper(base) {
              return {
                ...base,
                width: "100vw",
                position: "absolute",
                top: 0,
                bottom: 0,
                display: isLoading ? "block" : "none",
              };
            },
          }}
        />,
        document.body
      )}

      <S.WrapperBox>
        <S.Row>
          <S.OrderImg src={Order1} />
          <S.Title>Adicione seus pix</S.Title>
        </S.Row>

        {dataPaymentBillet?.pix
          ?.map((pix, index) => (
            <BankslipItem
              key={index}
              pix={pix}
            />
          ))}

        <S.WrapperButton>
          <S.Button3>
            <Button
              title="Continuar"
              type_button="primary"
              action={() => setStage(2)}
              disabled={
                !dataPaymentBillet?.pix?.length
              }
            />
          </S.Button3>
        </S.WrapperButton>
      </S.WrapperBox>
    </S.SearchWrapper>
  );
};
