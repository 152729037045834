import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContainerTitle = styled.div`
  display: flex;
  margin-top: 121px;
  margin-bottom: 31px;

  justify-content: center;
  align-items: center;

  @media (max-width: 425px) {
  }

  h1 {
    font-weight: bold;
    font-size: 25px;
    color: #3e3c3e;

    @media (max-width: 425px) {
    }
  }
`;

export const ContainerText = styled.div`
  display: flex;
  width: 90%;
  height: auto;
  margin: 0 auto;
  margin-bottom: 120px;
  text-align: justify;

  p {
    font-weight: normal;
    font-size: 18px;
    color: #3e3c3e;

    @media (max-width: 425px) {
    }
  }

  h2 {
    font-weight: bold;
    font-size: 18px;
    color: #3e3c3e;
    margin-top: 30px;
    margin-bottom: 20px;

    @media (max-width: 425px) {
    }
  }
`;
