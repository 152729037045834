import React from "react";
import * as S from "./styles";
import { NavBar, ButtonsStartInstallment, Loading } from "components";
import { useParams } from "react-router-dom";
import { useWhitelabel } from "hooks/useWhitelabel";
import { useGetWhiteLabel } from "services/ParcelamosService";

export const HomeInstallment = () => {
  const { id_usuario } = useParams();
  const { client } = useWhitelabel();

  const establishment_id =
    id_usuario ??
    client?.id_usuario ??
    process.env.REACT_APP_PARCELAMOS_TUDO_ESTABLISHMENT;

  const { isLoading } = useGetWhiteLabel(establishment_id);

  return (
    <Loading isLoading={isLoading} size="large">
      {isLoading ? (
        <S.WrapperLoading />
      ) : (
        <>
          <NavBar />

          <ButtonsStartInstallment />
        </>
      )}
    </Loading>
  );
};
