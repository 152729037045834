import React, { useState, useEffect, useMemo } from "react";
import * as S from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import ArrowRight from "assets/images/icons/arrow-right-gradient.svg";
import {
  BoxBorderGradient,
  Button,
  Currency,
  Loading,
  NavBar,
  Select,
} from "components";
import { useGoHome } from "views/Partner/utils";
import { useForm } from "react-hook-form";
import { EnumPaymentForm, ListSimuladorResponse } from "services/types";
import { useWhitelabel } from "hooks/useWhitelabel";
import { cleanMoneyMask } from "masks-br";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import {
  fetchOptionsSimulator,
  useGetWhiteLabel,
} from "services/ParcelamosService";
import { BsDot } from "react-icons/bs";
import { yupResolver } from "@hookform/resolvers/yup";
import { SchemaSimulator } from "models/Schemas/Simulator";

const Simulator: React.FC = () => {
  const navigate = useNavigate();

  const goBack = () => useGoHome(navigate);
  const { id_usuario } = useParams();
  const { client } = useWhitelabel();
  const { isLoading: isLoadingWhitelabel } = useGetWhiteLabel(
    id_usuario ?? (client?.id_usuario as string)
  );

  const {
    userIdWhitelabel: establishment_id,
    userNameWhitelabel: establishment_name,
  } = useWhitelabel();

  const [dataOptionSimulator, setDataOptionSimulator] = useState(
    {} as ListSimuladorResponse
  );

  const { mutate: getOptionsSimulator, isLoading } = useMutation(
    fetchOptionsSimulator,
    {
      onSuccess: (data) => {
        if (data.data.length === 0) {
          toast.error(
            "Simulação não realizada. Tente com outra forma de pagamento."
          );
        }
        setDataOptionSimulator(data);
      },
      onError: (error) => {
        toast.error("Não foi possível realizar a simulação, tente novamente.");
      },
    }
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(SchemaSimulator),
    mode: "all",
  });

  const payment_form = watch("payment_form");

  const hasDataOptionSimulator = useMemo(() => {
    if (dataOptionSimulator && dataOptionSimulator.data) {
      return dataOptionSimulator.data.length > 0;
    }

    return false;
  }, [dataOptionSimulator]);

  useEffect(() => {
    setValue("payment_form", EnumPaymentForm.CREDIT);
  }, []);

  const handleOptionsSimulator = handleSubmit((data) => {
    const { amount, payment_form } = data;

    getOptionsSimulator({
      amount: cleanMoneyMask(amount),
      payment_form: parseInt(payment_form),
      establishment_id,
    });
  });

  const handleSelectPaymentForm = (payment_form: string) => {
    if (parseInt(payment_form) === EnumPaymentForm.CREDIT) {
      return "Crédito";
    }
    if (parseInt(payment_form) === EnumPaymentForm.DEBIT) {
      return "Débito";
    }

    return "Pix";
  };

  return (
    <Loading isLoading={isLoadingWhitelabel} size="large">
      <NavBar />
      <S.Wrapper disabled={!!client}>
        <S.Button>
          <Button title={`Voltar`} type_button="outlined" onClick={goBack} />
        </S.Button>
      </S.Wrapper>
      <S.SimulatorWrapper>
        <S.Title>Simulador</S.Title>
        <S.SubTitle>
          Faça a simulação de parcelamento de suas custas junto ao{" "}
          {establishment_name}
        </S.SubTitle>

        <S.WrapperBoxBorderGradient disabled={hasDataOptionSimulator}>
          <BoxBorderGradient>
            <S.SubTitle>Informe o valor</S.SubTitle>

            <S.WrapperInput>
              <Currency
                type="text"
                type_input="normal"
                control={control}
                name="amount"
                error={errors?.amount?.message as string}
              />
            </S.WrapperInput>

            <S.WrapperInput>
              <Select
                control={control}
                name="payment_form"
                error={errors?.payment_form?.message as string}
                required
              >
                <S.Option
                  key={EnumPaymentForm.CREDIT}
                  value={EnumPaymentForm.CREDIT}
                >
                  Crédito
                </S.Option>
                <S.Option
                  key={EnumPaymentForm.DEBIT}
                  value={EnumPaymentForm.DEBIT}
                >
                  Débito
                </S.Option>
                <S.Option key={EnumPaymentForm.PIX} value={EnumPaymentForm.PIX}>
                  Pix
                </S.Option>
              </Select>
            </S.WrapperInput>

            <S.Buttons>
              <Button
                title="Calcular"
                type_button="primary"
                onClick={handleOptionsSimulator}
                loading={isLoading}
              />
            </S.Buttons>
          </BoxBorderGradient>
        </S.WrapperBoxBorderGradient>
        <S.ContainerGridButtons disabled={!hasDataOptionSimulator}>
          {dataOptionSimulator?.data?.map((conditional, index) => (
            <S.ContainerButton key={index}>
              <S.InstallmentButton>
                <S.ContainerInsideButton>
                  <S.TextButtonBold>
                    {handleSelectPaymentForm(payment_form)}
                  </S.TextButtonBold>
                  <BsDot />
                  <S.TextButton>{conditional.installment_number}x</S.TextButton>
                  <BsDot />
                  <S.TextButton>{conditional.installment_amount}</S.TextButton>
                </S.ContainerInsideButton>
                <S.ContainerInsideButton>
                  <S.SubTextButton>Valor Total</S.SubTextButton>
                  <BsDot />
                  <S.SubTextButton>{conditional.total_amount}</S.SubTextButton>
                </S.ContainerInsideButton>
              </S.InstallmentButton>
            </S.ContainerButton>
          ))}
        </S.ContainerGridButtons>
      </S.SimulatorWrapper>
      <S.FooterWrapper disabled={!hasDataOptionSimulator}>
        <S.Button>
          <Button
            title="Refazer Simulação"
            type_button="secondary"
            onClick={() => setDataOptionSimulator({} as ListSimuladorResponse)}
          />
        </S.Button>
        <S.Button>
          <Button
            title="Parcelar"
            type_button="call_action"
            icon_right={ArrowRight}
            onClick={() => navigate(`/payment_online/${establishment_id}`)}
          />
        </S.Button>
      </S.FooterWrapper>
    </Loading>
  );
};

export default Simulator;
