export type StagesProps = {
  enabledOpacity?: number;
};

export enum EnumStages {
  SEARCH = 1,
  CLIENT_IDENTIFICATION = 2,
  PAYMENT_FORM = 3,
  SUMMARY = 4,
  ORDER_DETAILS = 5,
}
