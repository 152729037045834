import { TTypeButton } from "models";
import styled, { css } from "styled-components";
import tinycolor from "tinycolor2";

export const Button = styled.button<{
  type_button: TTypeButton;
  height?: number;
  loading?: boolean;
}>`
  width: 100%;
  height: ${({ height }) => height}px;
  border-radius: 6px;
  border-width: 0;
  padding: 0;

  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: center;
  position: relative;
  background-color: transparent;

  ${({ loading }) =>
    loading &&
    css`
      align-items: center;
    `};

  ${({ type_button }) =>
    type_button === "primary" &&
    css`
      background: ${({ theme }) => theme.colors.background_gradient};
    `};

  ${({ type_button }) =>
    type_button === "gradient" &&
    css`
      background: linear-gradient(153.22deg, #ffc706 0%, #adff00 100%);
      &:hover {
        box-shadow: 0 0 11px rgba(255, 255, 255, 0.2);
      }
    `};

  ${({ type_button }) =>
    type_button === "dark" &&
    css`
      background: rgba(0, 0, 0, 0.2);

      &:hover {
        box-shadow: 0 0 11px rgba(255, 255, 255, 0.2);
      }
    `};

  ${({ type_button }) =>
    type_button === "outlined" &&
    css`
      background: transparent;
    `};

  ${({ type_button }) =>
    type_button === "primary_error" &&
    css`
      background-color: #e92215;
    `};

  ${({ type_button, disabled }) =>
    type_button === "call_action" &&
    css`
      background-color: #312f30;
      border-radius: 15px;
      &:hover {
        background-color: #515050;
      }

      ${() =>
        disabled &&
        css`
          &:hover {
            background-color: #312f30;
          }
        `};
    `};

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    opacity: 0.2;
    cursor: default;
  }
`;

export const Title = styled.p<{
  type_button: TTypeButton;
  disabled: boolean;
  paddingVertical?: number;
  paddingHorizontal?: number;
  font_size?: number;
  font_weight?: number;
  align?: "flex-start" | "flex-end" | "center" | "space-between";
  height?: number;
}>`
  font-family: "Inter";
  font-style: normal;
  font-size: ${({ font_size }) => font_size}px;
  font-weight: ${({ font_weight }) => font_weight};
  letter-spacing: -0.3px;
  color: #fff;

  width: 100%;
  height: ${({ height }) => height}px;
  border-radius: 6px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: ${({ align }) => align};
  box-sizing: border-box;
  padding: ${({ paddingVertical, paddingHorizontal }) =>
    paddingVertical + "px " + paddingHorizontal + "px"};
  z-index: 1;

  ${({ type_button, disabled }) =>
    type_button.indexOf("primary") > -1 &&
    css`
      &:hover {
        background-color: rgba(0, 0, 0, 0.3);
      }

      ${() =>
        disabled &&
        css`
          &:hover {
            background-color: transparent;
          }
        `};
    `};

  ${({ type_button }) =>
    type_button === "secondary" &&
    css`
      background-color: #fff;
      border: 1px solid
        ${({ theme }) =>
          tinycolor(theme.colors.primary).isLight()
            ? theme.colors.secondary
            : theme.colors.primary};
      color: ${({ theme }) =>
        tinycolor(theme.colors.primary).isLight()
          ? theme.colors.secondary
          : theme.colors.primary};
    `};

  ${({ type_button }) =>
    type_button === "gradient" &&
    css`
      color: #312f30;
    `};

  ${({ type_button }) =>
    type_button === "dark" &&
    css`
      color: #fff;
    `};

  ${({ type_button }) =>
    type_button === "secondary_error" &&
    css`
      background-color: #fff;
      border: 1px solid #e92215;
      color: #e92215;
    `};

  ${({ type_button }) =>
    type_button === "outlined" &&
    css`
      background-color: #fff;
      border: 1px solid #D7DFE9;
      color: #455468;
      border-radius: 6px;
    `};

  ${({ type_button, disabled }) =>
    (type_button === "outlined" || type_button.indexOf("secondary") > -1) &&
    css`
      &:hover {
        background-color: rgba(33, 33, 33, 0.05);
      }

      ${() =>
        disabled &&
        css`
          &:hover {
            background-color: transparent;
          }
        `};
    `};

  ${({ type_button }) =>
    type_button === "call_action" &&
    css`
      background: linear-gradient(153.22deg, #ffc706 0%, #adff00 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    `};
`;

export const IconRight = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 10px;
`;

export const IconLeft = styled.img`
  margin-right: 13px;
`;
