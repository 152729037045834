import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  NavBar,
  Button,
  Loading,
  PaymentForm,
  Summary,
  UserInformationForm,
  CardFlags,
} from "components";
import { SdkPt } from "models";
import { Search } from "./Search";
import { EnumStages } from "./types";
import usePayment from "hooks/usePayment";
import { useWhitelabel } from "hooks/useWhitelabel";
import {
  EnumOriginPayment,
  EnumTypeDebit,
  MappedDebitsToGetInstallments,
} from "services/CreditCard";
import { useGetWhiteLabel } from "services/ParcelamosService";

import Order1 from "assets/images/icons/order-1.svg";
import Order2 from "assets/images/icons/order-2.svg";
import Order3 from "assets/images/icons/order-3.svg";
import * as S from "./styles";
import { id_establishment_default } from "config/establishment";

export const Billet = () => {
  const { id_usuario } = useParams();
  const { client } = useWhitelabel();
  const establishment_id = id_usuario ?? client?.id_usuario;
  const { isLoading } = useGetWhiteLabel(establishment_id as string);
  const [sdkPT, setSdkPT] = useState<SdkPt>({} as SdkPt);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);

  const { state } = useLocation();

  const [stage, setStage] = useState<EnumStages>(EnumStages.SEARCH);
  const {
    paymentForm,
    dataPaymentBillet,
    setPaymentForm,
    getInstallments,
    clearPaymentBilletData,
  } = usePayment();

  const getIconStage = (stage: EnumStages) =>
    [Order1, Order2, Order3][stage - 1];

  const goBack = () => {
    setStage(stage - 1);
  };

  function handleChangeStage(nextStage: EnumStages) {
    if (nextStage === EnumStages.PAYMENT_FORM) {
      const debits: MappedDebitsToGetInstallments =
        dataPaymentBillet.billets.map((billet) => ({
          type: EnumTypeDebit.BILLET,
          id_bill: billet.id_bill,
        }));

      const request = {
        idEstablishment: client?.id_usuario || id_usuario,
        data: { origin: EnumOriginPayment.ONLINE, debits },
      };

      getInstallments(request, {
        onError(error: any) {
          toast.error(error?.response?.data?.message);
        },
      }).then(() => {
        setStage(nextStage);
      });
      return;
    }

    setStage(nextStage);
  }

  useEffect(() => {
    if (state) {
      handleChangeStage(state.stage);
    }
  }, [state]);

  useEffect(() => {
    return clearPaymentBilletData;
  }, []);

  return (
    <Loading isLoading={isLoading} size="large" backgroundNone>
      <NavBar />
      <S.Wrapper>
        <S.Button>
          {stage === EnumStages.SEARCH || stage === EnumStages.ORDER_DETAILS ? (
            <></>
          ) : (
            <Button
              title={`Voltar para etapa`}
              type_button="outlined"
              icon_right={getIconStage(stage - 1)}
              onClick={goBack}
              disabled={buttonDisabled}
            />
          )}
        </S.Button>
      </S.Wrapper>

      <S.WrapperStages>
        <Search
          setStage={handleChangeStage}
          enable={stage === EnumStages.SEARCH}
          hasBilletActions={client?.action_button_billet}
          idEstablishment={
            id_usuario ?? client?.id_usuario ?? id_establishment_default
          }
        />
        <UserInformationForm
          enable={stage === EnumStages.CLIENT_IDENTIFICATION}
          setStage={handleChangeStage}
          stage={stage}
        />
        <PaymentForm
          setStage={handleChangeStage}
          paymentForm={paymentForm}
          setPaymentForm={setPaymentForm}
          showSummary={false}
          enable={stage === EnumStages.PAYMENT_FORM}
          setSdkPT={setSdkPT}
        />
        <Summary
          route="boleto"
          paymentForm={paymentForm}
          enable={stage === EnumStages.SUMMARY}
          stage={stage}
          goBack={goBack}
          sdkPT={sdkPT}
          setButtonDisabled={setButtonDisabled}
          buttonDisabled={buttonDisabled}
          whitelabelUserId={id_usuario}
        />

        {stage !== EnumStages.ORDER_DETAILS ? <CardFlags /> : null}
      </S.WrapperStages>
    </Loading>
  );
};
