import * as yup from "yup";

export const SchemaDataBank = yup.object().shape({
  bank: yup.string().required("Informe um banco válido."),
  account_type: yup.string().required("Informe um tipo de conta válido."),
  agency_number: yup.string().required("Informe uma agência válida."),
  agency_number_digit: yup.string().required("Informe um dígito válido."),
  account_number: yup.string().required("Informe um número de conta válido."),
  account_number_digit: yup.string().required("Informe um dígito válido."),
  amount_total: yup.string().required("Informe um valor válido."),
  description: yup.string(),
  reference_id: yup.string(),
});

export const SchemaDataUser = yup.object().shape({
  recipient_name: yup
    .string()
    .required("Informe um nome válido.")
    .min(3, "Informe um nome válido."),
  recipient_document: yup
    .string()
    .required("Informe um documento válido.")
    .min(11, "Informe um documento válido."),
  recipient_email: yup
    .string()
    .required("Informe um email válido.")
    .email("Informe um email válido."),
  recipient_phone: yup
    .string()
    .required("Informe um telefone válido.")
    .min(11, "Informe um telefone válido."),
});
