import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  background-color: #ffffff;
  border: 1px solid rgba(33, 33, 33, 0.12);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  padding: 24px;
  box-sizing: border-box;
  position: relative;
`;

export const Row = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
`;

export const Div = styled.div``;

export const IconBarCode = styled.img`
  margin-right: 10px;
`;

export const Name = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  margin: 0;
`;

export const Value = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: #212121;
  margin: 0;
  margin-bottom: 10px;
`;

export const Label = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: rgba(33, 33, 33, 0.5);
  margin: 0;
  margin-bottom: 10px;
  box-sizing: border-box;
  flex-wrap: wrap;
`;

export const Dot = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(33, 33, 33, 0.5);
  margin: 0;
  padding: 0 10px;
  padding-bottom: 10px;
  box-sizing: border-box;
  flex-wrap: wrap;
`;

export const TextLabel = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: rgba(33, 33, 33, 0.5);
  margin: 0;
  margin-bottom: 10px;
  box-sizing: border-box;
  word-break: break-all;
`;

export const ButtonOpen = styled.button`
  position: absolute;
  right: 24px;
  top: 24px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;

export const ButtonDelete = styled.button`
  position: absolute;
  right: 24px;
  top: 62px;
  background-color: transparent;
  border: 0;
  cursor: pointer;
`;

export const Icon = styled.img``;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`;
