import { api } from "services";
import { useQuery } from "react-query";

export interface GetCheckoutIdByIdentifierResponse {
  id_checkout: string;
}

type UseGetCheckoutIdOptions = {
  enabled?: boolean;
};

async function getCheckoutIdByIdentifier(
  identifier: string
): Promise<GetCheckoutIdByIdentifierResponse> {
  try {
    const response = await api.get<GetCheckoutIdByIdentifierResponse>(
      `/checkout/identifier/${identifier}`,
    );

    return response.data;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
}

export const useGetCheckoutIdByIdentifierInfo = (identifier: string, options: UseGetCheckoutIdOptions = {}) => {
  return useQuery(
    ["get-checkout-id-by-identifier", identifier],
    () => getCheckoutIdByIdentifier(identifier),
    {
      staleTime: 1000 * 15,
      enabled: options?.enabled ?? true,
      refetchInterval: 15000,
    }
  );
};
