import { format, parseISO } from "date-fns";
import prBrLocale from "date-fns/locale/pt-BR";
import Payment from "payment";

export function formatCurrency(value: number): string {
	const stringValue = `${value}`;
	const [integerPart, decimalPart] = stringValue.split(".");
	const formattedIntegerPart = integerPart.replace(
		/\B(?=(\d{3})+(?!\d))/g,
		"."
	);
	const formattedDecimalPart = decimalPart
		? `,${decimalPart.padEnd(2, "0")}`
		: ",00";
	return `R$ ${formattedIntegerPart}${formattedDecimalPart}`;
}

export function formatMoney(value: string | number): string {
	const stringValue = `${value}`;
	const [integerPart, decimalPart] = stringValue.split(".");
	const formattedIntegerPart = integerPart.replace(
		/\B(?=(\d{3})+(?!\d))/g,
		"."
	);
	const formattedDecimalPart = decimalPart
		? `,${decimalPart.padEnd(2, "0")}`
		: ",00";
	return `${formattedIntegerPart}${formattedDecimalPart}`;
}

export function formatDecimal(value: number | string): string {
	return parseFloat(
		`${Number(parseFloat(`${value}`.replace(/\D/g, "")).toFixed(2)) / 100}`
	).toFixed(2);
}

export function maskBankSlip(bankSlipNumber: string): string {
	const numericBankSlipNumber = bankSlipNumber.replace(/[^\d]/g, "");
	const length = numericBankSlipNumber.length;

	// Return the original input if the length is not within the expected range
	if (length < 44 || length > 48) {
		return bankSlipNumber;
	}

	const groupSizes = [5, 10, 10, 10, 10, 2];
	const groups = [];
	let i = 0;

	// Split the numeric string into groups of the appropriate sizes
	for (const size of groupSizes) {
		const group = numericBankSlipNumber.substr(i, size);
		groups.push(group);
		i += size;
	}

	// Build the masked bank slip number string using the groups and separators
	const separators = [".", " ", ".", " ", ".", " "];
	let maskedBankSlipNumber = "";

	for (let j = 0; j < groups.length; j++) {
		maskedBankSlipNumber += groups[j];
		if (separators[j]) {
			maskedBankSlipNumber += separators[j];
		}
	}

	return maskedBankSlipNumber.trim();
}

export function formatShortToDateString(short?: string) {
	if (!short) {
		return;
	}

	return format(parseISO(short), "dd 'de' MMMM yyyy", {
		locale: prBrLocale,
	});
}

export const cleanMoneyMask = (value: string): number => {
	return parseFloat(
		String(value)
			.replace(/[R$.\-() /]/g, "")
			.replace(",", ".")
	);
};

export const isCnpjMask = (value: string): string => {
	if (value?.length < 15) {
		return "999.999.999-999";
	}

	return "99.999.999/9999-99";
};

export const maskCvv = (value: string): string => {
	return Payment.fns.cardType(value) === "amex" ? "9999" : "999";
};

export const cpfSensibleMask = (value: string): string => {
	return value
		?.replace(/\D/g, "")
		.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.***.***-$4");
};

export const creditCardSensibleMask = (value: string): string => {
	return value
		?.replace(/\D/g, "")
		.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, "**** $4");
};

export const formatCountdown = (value: string) => {
	return value
		?.replace(/hours/g, "horas")
		.replace(/minutes/g, "minutos")
		.replace(/seconds/g, "s");
};

/**
 * Phone Number Mask
 * @example
 * formatPhoneNumber('+5511965708288')
 * // returns '+55 (11) 96570-8288'
 * @param {string} number Number With 14 characters to mask
 */
export const formatPhoneNumber = (number: string) => {
	const digits = number.replace(/\D/g, '');
	
	const countryCode = digits.substring(0, 2);
	const areaCode = digits.substring(2, 4);
	const centralPart = digits.substring(4, 9);
	const finalPart = digits.substring(9, 13);
	
	return `+${countryCode} (${areaCode}) ${centralPart}-${finalPart}`;
}