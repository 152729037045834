import { GetPlateDebitsResponse } from "services/types";
import { GetAllDebitsRequest } from "./dto/get-debits.dto";
import { api } from "services/api";
import { useMutation } from "react-query";
import { OptionUseMutation } from "config/react-query";
import { id_establishment_default } from "config/establishment";
import reCaptcha from "services/RecaptchaService";

export const useGetDebits = (
  options?: OptionUseMutation<GetPlateDebitsResponse>
) => {
  return useMutation("get-debits", getDebits, options);
};

async function getDebits({
  id_establishment,
  license_plate,
  renavam,
  document,
  state,
}: GetAllDebitsRequest): Promise<GetPlateDebitsResponse> {
  const token = await reCaptcha.getRecaptchaToken();

  const response = await api.get("/partner/detran/debits", {
    params: {
      license_plate,
      renavam,
      document,
      state,
    },
    headers: {
      "id-establishment": id_establishment || id_establishment_default,
      "x-recaptcha-token": token
    },
  });

  return response.data;
}
