import { ISelectProps } from "models";
import React, { useState } from "react";
import * as S from "./styles";
import { Controller } from "react-hook-form";

export const Select: React.FC<ISelectProps> = ({
  label,
  width,
  border_radius_left = true,
  control,
  error,
  hiden_error,
  border_radius_right = true,
  type_select,
  children,
  ...props
}) => {
  const [showError, setShowError] = useState<boolean>(false);

  return (
    <S.WrapperSelect>
      <Controller
        control={control}
        name={props.name as string}
        render={({
          field: { onChange, value, name },
          fieldState: { error },
        }) => (
          <S.Row>
            {label && <S.Label>{label}:</S.Label>}
            <S.Select
              {...props}
              width={width}
              name={name}
              type_select={error ? "error" : type_select}
              border_radius_left={border_radius_left}
              border_radius_right={border_radius_right}
              value={value}
              onChange={(e: any) => {
                onChange(e);
                props.onChange ? props.onChange(e) : null;
              }}
            >
              {children}
            </S.Select>
          </S.Row>
        )}
      />
      <S.TextError onMouseEnter={() => setShowError(!showError)}>
        {hiden_error ? "" : error}
      </S.TextError>
      <S.ErrorShowComplete
        show={showError}
        onMouseOut={() => setShowError(!showError)}
      >
        {hiden_error ? "" : error}
      </S.ErrorShowComplete>
    </S.WrapperSelect>
  );
};
