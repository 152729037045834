import styled from "styled-components";
import { deviceMaxWidth } from "theme/theme";

export const Title = styled.p`
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0px;
`;

export const SubTitle = styled.p`
  color: #6b7280;
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0px;
  margin-top: 5px;
`;

export const StatusImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;

export const PaymentTrackingContainer = styled.div`
  margin-top: 30px;
  display: flex;

  @media ${deviceMaxWidth.tablet} {
    flex-direction: column;
    width: 100%;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

export const CardGrid = styled.div<{
  color: string;
}>`
  display: flex;
  width: 100%;
  min-height: 40px;
  align-items: center;
  justify-content: center;

  border: 1px;
  border-color: ${({ color }) => color};
  border-style: dashed;
  border-left-color: transparent;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-left-color: ${({ color }) => color};
  }

  &:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  @media ${deviceMaxWidth.tablet} {
    border-left-color: ${({ color }) => color};
    border-top-color: transparent;

    &:first-child {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 0;
      border-top-color: ${({ color }) => color};
    }

    &:last-child {
      border-top-right-radius: 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;
