import { IInputProps } from "models";
import React, { useState } from "react";
import HidePassword from "assets/images/icons/hide-password.svg";
import ShowPassword from "assets/images/icons/show-password.svg";
import LockKey from "assets/images/icons/lock-key.svg";
import * as S from "./styles";
import { Controller } from "react-hook-form";

import { celularMask } from "masks-br";

export const Input: React.FC<IInputProps> = ({
  icon_left,
  icon_right,
  type_input,
  control,
  label,
  error,
  hiden_error,
  money,
  textAlign = "left",
  border_radius_left = true,
  border_radius_right = true,
  required = false,
  hide = false,
  uppercase = false,
  validate,
  ...props
}) => {
  const is_phone = type_input === "phone";
  const [showError, setShowError] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);

  function handleToogleVisiblePassword() {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  }

  return (
    <S.WrapperInput>
      {label && <S.Label type_input={type_input}>{label}:</S.Label>}
      <Controller
        control={control}
        name={props.name as string}
        rules={{
          validate,
        }}
        render={({
          field: { onChange, value, name },
          fieldState: { error },
        }) => {
          return (
            <S.Row hide={hide}>
              {is_phone && (
                <S.SelectArea>
                  <option>BR +55</option>
                </S.SelectArea>
              )}
              {icon_left && <S.IconLeft src={icon_left} />}
              <S.Input
                {...props}
                type={showPassword ? "text" : props.type}
                maskChar=""
                name={name}
                type_input={error ? "error" : type_input}
                textAlign={textAlign}
                value={is_phone && value ? celularMask(value) : value}
                icon_left={icon_left || props.type === "password"}
                icon_right={icon_right || props.type === "password"}
                phone={is_phone}
                border_radius_left={border_radius_left}
                border_radius_right={border_radius_right}
                onChange={(e: any) => {
                  onChange(e);
                  props.onChange ? props.onChange(e) : null;
                }}
                uppercase={uppercase}
              />
              {icon_right && <S.IconRight src={icon_right} />}
              {props.type === "password" && (
                <S.ButtonPasswordLeft>
                  <S.IconPassword src={LockKey} />
                </S.ButtonPasswordLeft>
              )}
              {props.type === "password" && (
                <S.ButtonPassword onClick={handleToogleVisiblePassword}>
                  <S.IconPassword
                    src={showPassword ? HidePassword : ShowPassword}
                  />
                </S.ButtonPassword>
              )}
            </S.Row>
          );
        }}
      />
      <S.TextError onMouseEnter={() => setShowError(!showError)}>
        {hiden_error ? "" : error}
      </S.TextError>
      <S.ErrorShowComplete
        show={showError}
        onMouseOut={() => setShowError(!showError)}
      >
        {hiden_error ? "" : error}
      </S.ErrorShowComplete>
    </S.WrapperInput>
  );
};
