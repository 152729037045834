import styled, { css } from "styled-components";

export const Container = styled.div<{ enable?: boolean }>`
  width: 100%;
  display: ${({ enable }) => (enable ? "flex" : "none")};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const WrapperLeft = styled.div`
  width: 160px;
`;

export const Item = styled.button<{ active?: boolean }>`
  width: 160px;
  background-color: rgba(33, 33, 33, 0.05);
  border-radius: 10px 0px 0px 10px;
  padding: 20px 20px;
  box-sizing: border-box;
  margin-bottom: 15px;
  border: 0;
  cursor: ${({ active }) => (active ? "default" : "pointer")};

  ${({ active }) =>
    !active &&
    css`
      box-sizing: border-box;
      width: 140px;
      border: 1px solid rgba(33, 33, 33, 0.12);
      border-radius: 10px;
      background-color: transparent;
    `};
`;

export const Title = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
  margin: 0;
`;

export const ButtonAdd = styled.div`
  width: 160px;
  padding-right: 20px;
  box-sizing: border-box;
`;

export const WrapperRight = styled.div<{ active?: boolean }>`
  width: 100%;
  padding: 20px 20px;
  box-sizing: border-box;
  background-color: rgba(33, 33, 33, 0.05);
  display: ${({ active }) => (active ? "block" : "none")};
`;

export const WrapperHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
  margin-bottom: 20px;
`;

export const ButtonDelete = styled.button`
  background-color: transparent;
  border: 0;
  position: absolute;
  right: 0px;
`;

export const Icon = styled.img``;

export const WrapperInput = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

export const RowInputs = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const MarginInputs = styled.div`
  margin: 0 7.5px;
`;

export const WrapperButton = styled.div`
  width: 100%;
  display: flex;
  align-self: center;
  justify-content: flex-end;
`;

export const ButtonPayNow = styled.div`
  width: 165px;
  align-self: flex-end;
  margin-top: 30px;
`;

export const WrapperCardsIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`;

export const CardsIcons = styled.img``;

export const Option = styled.option``;

export const BoxFee = styled.div`
  margin-top: 32px;
  padding: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px dashed rgba(33, 33, 33, 0.12);
  background-color: #FFF;
  border-radius: 4px;
`;

export const RowFee = styled.div`
  width: 100%;
  padding: 12px 0;
  margin: 0 auto;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(33, 33, 33, 0.12);
`;