import { Button, Input, Select } from "components";
import React from "react";
import ArrowRight from "assets/images/icons/arrow-right-gradient.svg";
import * as S from "./styles";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { useGetDetrans } from "views/Partner/providers/get-detrans.provider";
import { id_establishment_default } from "config/establishment";

interface IBoxInstallment {
  removeMargin?: boolean;
}

export const BoxInstallment: React.FC<IBoxInstallment> = ({ removeMargin }) => {
  const navigate = useNavigate();
  const { data: detrans } = useGetDetrans({
    id_establishment: id_establishment_default,
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "all",
  });

  const onSubmitHandler = async (data: any) => {
    const onlyNumbersRegex = /^[0-9]+$/;
    const isRenavan = onlyNumbersRegex.test(data.license_plate);

    if (data.digitable_line) {
      navigate(`/boleto?barcode=${data.digitable_line}`);
    }
    if (!isRenavan && data.id_detran) {
      navigate(
        `/parceiro?license_plate=${data.license_plate}&id_detran=${data.id_detran}`
      );
    }
    if (isRenavan && data.id_detran) {
      navigate(
        `/parceiro?renavam=${data.license_plate}&id_detran=${data.id_detran}`
      );
    }
  };

  const onSubmitError = async (data: any) => {
    console.error(errors);
  };

  return (
    <S.Container removeMargin={removeMargin}>
      <S.TitleInstallment>Bora parcelar?</S.TitleInstallment>
      <S.SubTitleInstallment>
        Cole aquela linha digitável ou insira a placa do seu veículo, daí
        fazemos nossa mágica acontecer!
      </S.SubTitleInstallment>

      <S.WrapperInput>
        <Input
          type="text"
          type_input="normal"
          placeholder="Código de barras"
          control={control}
          name="digitable_line"
          error={errors?.digitable_line?.message as string}
          // validate={checkDigitableLineBankslipRegex}
        />
      </S.WrapperInput>

      <S.TextOr>ou</S.TextOr>

      <S.WrapperInputSelect>
        <Input
          type_input="input_widget"
          placeholder="Placa do veículo"
          border_radius_right={false}
          control={control}
          name="license_plate"
          error={errors?.plate?.message as string}
        />
        <S.WrapperSelectSize style={{ width: 124 }}>
          <Select
            id="id_detran"
            border_radius_left={false}
            type_select="select_widget"
            control={control}
            name="id_detran"
            error={errors?.state?.message as string}
          >
            <S.Option value="" disabled selected>
              UF
            </S.Option>
            {detrans?.data
              .filter((detran) => detran.integrated)
              .map((detran, index) => {
                return (
                  <S.Option key={index} value={detran.id_partner}>
                    {detran.state}
                  </S.Option>
                );
              })}
          </Select>
        </S.WrapperSelectSize>
      </S.WrapperInputSelect>
      {/*

      <S.TextOr>ou</S.TextOr>

      <S.WrapperInput>
        <Select
          type_select="select_widget"
          control={control}
          name="agreement"
          error={errors?.agreement?.message as string}
        >
          <S.Option value="" disabled selected>
            Escolha o convênio
          </S.Option>
          {data?.map((item, index) => {
            return <S.Option key={index}>{item}</S.Option>;
          })}
        </Select>
      </S.WrapperInput> */}

      <S.UsagePolicies>
        Ao prosseguir, você concorda com nossas{" "}
        <S.UsagePoliciesLink>
          <Link to="/termos-de-uso" target="_blank">
            Políticas de Uso
          </Link>{" "}
          e{" "}
          <Link to="/politica-de-privacidade" target="_blank">
            Privacidade.
          </Link>
        </S.UsagePoliciesLink>
      </S.UsagePolicies>

      <S.ButtonInstallments>
        <Button
          title="Continuar"
          type_button="call_action"
          icon_right={ArrowRight}
          action={handleSubmit(onSubmitHandler, onSubmitError)}
        />
      </S.ButtonInstallments>
    </S.Container>
  );
};
