import styled, { css } from "styled-components";

export const Container = styled.button`
  background-color: transparent;
  border: 0;
  cursor: pointer;
  min-width: 276px;
  margin-bottom: 8px;
`;

export const Wrapper = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Switch = styled.div<{ active: boolean }>`
  width: 50px;
  min-width: 50px;
  height: 28px;
  background: #1b4dff;
  border-radius: 20px;
  display: flex;
  align-items: center;
  position: relative;

  ${({ active }) =>
    !active &&
    css`
      background: #afbaca;
    `};
`;

export const Circle = styled.div<{ active: boolean }>`
  width: 22px;
  height: 22px;
  border-radius: 22px;
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(45, 54, 67, 0.05);
  position: absolute;
  right: 3px;

  ${({ active }) =>
    !active &&
    css`
      left: 3px;
      right: unset;
    `};
`;
