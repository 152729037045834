import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.div`
  width: fit-content;

  @media screen and (max-width: 768px) {
    padding: 0px 20px;
  }
`;

export const Title = styled.h1`
  font-size: 200px;
  font-weight: 700;
  font-feature-settings: "salt" on, "liga" off;

  background: linear-gradient(153.22deg, #ffc706 0%, #adff00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  margin-bottom: 20px;
  text-align: center;
`;

export const SubTitle = styled.h2`
  font-size: 30px;
  font-weight: 700;
  color: #312f30;
  margin-bottom: 20px;
  text-align: center;
`;
