import React from 'react';

import * as S from './styles';

import { ReactComponent as Elo } from 'assets/images/icons/elo.svg';
import { ReactComponent as Visa } from 'assets/images/icons/visa.svg';
import { ReactComponent as Mastercard } from 'assets/images/icons/master.svg';
import { ReactComponent as Pix } from 'assets/images/icons/pix.svg';
import { ReactComponent as Pci } from 'assets/images/icons/pci-dss.svg';

export function CardFlags() {
  return (
    <S.Container>
      <S.WrapperFlags>
        <Elo />
        <Visa />
        <Mastercard />
        <Pix />
        <Pci />
      </S.WrapperFlags>

      <p>Este pagamento utiliza a autenticação 3DS ou EMV 3DS. Portanto o banco emissor do seu Cartão exigirá uma confirmação desta transação através de Token ou uma confirmação no aplicativo do seu banco. Este processo aumenta a segurança e confiabilidade na sua transação.</p>
    </S.Container>
  );
}