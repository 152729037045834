import { EnumPaymentForm } from "services/types";

export const regexBillet = /^[0-9]{44,48}$/;

export const removeAccentsAndSpecialCharacteres = (str: string) => {
  return str.replace(/[^a-zA-Z0-9]/g, "");
};

export const checkDigitableLineBankslipRegex = (digitable_line: string) => {
  const cleanDigitableLine = removeAccentsAndSpecialCharacteres(digitable_line);
  return regexBillet.test(cleanDigitableLine) || "Linha digitável inválida";
};

export const convertToMoney = (v: any) => {
  if (v) {
    if (typeof v === "number") {
      v = parseFloat(`${v}`).toFixed(2);
    } else {
      v = `${v}`.replace(/\D/g, "");
    }
    if (v) {
      if (v.length === 1) {
        v = `0.0${v}`;
      } else {
        v = v?.substring(0, v.length - 2) + "." + v?.substring(v.length - 2);
      }
      return parseFloat(v);
    } else {
      return 0;
    }
  } else {
    return 0;
  }
};

export const truncTwoDecimals = (value: number) => {
  return Math.trunc(value * 100) / 100;
};

export const roundTwoDecimals = (value: number) => {
  return Math.round(value * 100) / 100;
};

export const parsePaymentForm = (paymentForm: EnumPaymentForm) => {
  switch (paymentForm) {
    case EnumPaymentForm.CREDIT:
      return "CREDIT_CARD";
    case EnumPaymentForm.DEBIT:
      return "DEBIT_CARD";
    case EnumPaymentForm.PIX:
      return "PIX";
    default:
      return "CREDIT_CARD";
  }
};
