import { ICurrencyProps, TTypeInput } from "models";
import { NumberFormatBase } from "react-number-format";
import styled, { css } from "styled-components";

export const WrapperInput = styled.div`
  width: 100%;
  position: relative;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.p<{ type_input: TTypeInput }>`
  color: #5e718d;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  margin-bottom: 5px;

  ${({ type_input }) =>
    type_input === "error" &&
    css`
      color: #e92215;
    `};
`;

export const Input = styled(NumberFormatBase)<ICurrencyProps>`
  width: 100%;
  height: 46px;
  background: #ffffff;
  border: 1px solid #afbaca;
  border-radius: 6px;
  padding: 0px 14px;
  padding-left: 35px;
  text-align: ${({ textalign }) => textalign};
  color: #8897ae;

  ${({ type }) =>
    type === "password" &&
    css`
      padding: 0 49px;
    `};

  ${({ border_radius_left }) =>
    !border_radius_left &&
    css`
      border-radius: 0px 6px 6px 0px;
      border-left-width: 0;
    `};

  ${({ border_radius_right }) =>
    !border_radius_right &&
    css`
      border-radius: 6px 0px 0px 6px;
      border-right-width: 0;
    `};

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #8897ae;
  caret-color: #5e718d;
  outline: none;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  ::placeholder {
    color: #8897ae;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #8897ae;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #8897ae;
  }

  ${({ value }) =>
    value &&
    css`
      color: #5e718d;
      border: 1px solid #5e718d;
    `};

  &:focus-visible {
    border: 1px solid #5e718d;
  }

  &:disabled {
    border: 1px solid #d7dfe9;
    color: #afbaca;

    ::placeholder {
      color: #afbaca;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #afbaca;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #afbaca;
    }
  }

  ${({ type_input, border_radius_left, border_radius_right }) =>
    type_input === "input_widget" &&
    css`
      background-color: #f8f8f8;
      border-radius: 15px;
      border-radius: 5px;
      color: rgba(33, 33, 33, 1);
      border: 0px !important;
      caret-color: rgba(33, 33, 33, 1);

      ${() =>
        !border_radius_left &&
        css`
          border-radius: 0px 5px 5px 0px;
          border-left-width: 0;
        `};

      ${() =>
        !border_radius_right &&
        css`
          border-radius: 5px 0px 0px 5px;
          border-right-width: 0;
        `};

      ::placeholder {
        color: rgba(33, 33, 33, 0.35);
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: rgba(33, 33, 33, 0.35);
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: rgba(33, 33, 33, 0.35);
      }

      &:disabled {
        color: rgba(33, 33, 33, 0.35);

        ::placeholder {
          color: rgba(33, 33, 33, 0.35);
          opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: rgba(33, 33, 33, 0.35);
        }

        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: rgba(33, 33, 33, 0.35);
        }
      }
    `};

  ${({ type_input, border_radius_left, border_radius_right }) =>
    type_input === "input_widget_error" &&
    css`
      background-color: #f8f8f8;
      border-radius: 15px;
      border-radius: 5px;
      border: 0px !important;
      color: #e92215;
      caret-color: #e92215;

      ${() =>
        !border_radius_left &&
        css`
          border-radius: 0px 5px 5px 0px;
          border-left-width: 0;
        `};

      ${() =>
        !border_radius_right &&
        css`
          border-radius: 5px 0px 0px 5px;
          border-right-width: 0;
        `};

      &:focus-visible {
        border: 1px solid #ffa19b;
      }

      ::placeholder {
        color: #e92215;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #e92215;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #e92215;
      }

      &:disabled {
        border: 1px solid #ffa19b;
        color: #e92215;

        ::placeholder {
          color: #e92215;
          opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #e92215;
        }

        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #e92215;
        }
      }
    `};

  ${({ type_input }) =>
    type_input === "error" &&
    css`
      background-color: #fff5f4;
      border: 1px solid #ffa19b;
      border-radius: 6px;
      color: #e92215;
      caret-color: #e92215;

      &:focus-visible {
        border: 1px solid #ffa19b;
      }

      ::placeholder {
        color: #e92215;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #e92215;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #e92215;
      }

      &:disabled {
        border: 1px solid #ffa19b;
        color: #e92215;

        ::placeholder {
          color: #e92215;
          opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #e92215;
        }

        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #e92215;
        }
      }
    `};

  ${({ border_radius_left }) =>
    !border_radius_left &&
    css`
      border-radius: 0px 6px 6px 0px !important;
      border-left-width: 0 !important;
    `};

  ${({ border_radius_right }) =>
    !border_radius_right &&
    css`
      border-radius: 6px 0px 0px 6px !important;
      border-right-width: 0 !important;
    `};
`;

export const TextCurrency = styled.p<{
  type_input: TTypeInput;
  value?: string;
  disabled?: boolean;
}>`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #8897ae;
  caret-color: #5e718d;
  outline: none;
  margin: 0;
  left: 14px;
  position: absolute;

  ${({ value }) =>
    value &&
    css`
      color: #5e718d;
    `};

  ${({ disabled }) =>
    disabled &&
    css`
      color: #afbaca;
    `};

  ${({ type_input, disabled }) =>
    type_input === "input_widget" &&
    css`
      color: rgba(33, 33, 33, 1);

      ${() =>
        disabled &&
        css`
          color: rgba(33, 33, 33, 0.35);
        `};
    `};

  ${({ type_input }) =>
    type_input === "input_widget_error" &&
    css`
      color: #e92215;
    `};

  ${({ type_input }) =>
    type_input === "error" &&
    css`
      color: #e92215;
    `};
`;

export const ButtonPassword = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  background-color: transparent;
  border-width: 0;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 15px;
`;

export const ButtonPasswordLeft = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  background-color: transparent;
  border-width: 0;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 15px;
`;

export const IconPassword = styled.img`
  width: 20px;
  height: 20px;
`;

export const IconRight = styled.img`
  width: 24px;
  height: 24px;
  margin-left: 10px;
`;

export const IconLeft = styled.img`
  width: 24px;
  height: 24px;
  position: absolute;
  left: 15px;
`;

export const ErrorShowComplete = styled.p<{ show?: boolean }>`
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #e92215;
  margin: 0;
  position: absolute;
  visibility: hidden;
  z-index: 1000;

  ${({ show }) =>
    show &&
    css`
      visibility: visible;
      /* top: 10px; */
      background-color: #fff;
      color: #e92215;
    `};
`;

export const TextError = styled.p`
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #e92215;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  position: absolute;
`;
