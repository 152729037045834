import React, { useMemo } from "react";

import FusionCharts from "fusioncharts";
import Maps from "fusioncharts/fusioncharts.maps";
import Brazil from "fusionmaps/maps/fusioncharts.brazil";
import WC from "fusionmaps/maps/fusioncharts.worldwithcountries";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import ReactFC from "react-fusioncharts";

interface IMapProps {
  detrans?: { id_detran: string; uf: string }[];
  data: { id: string; color: string; state: string }[];
}

ReactFC.fcRoot(FusionCharts, Maps, WC, Brazil, FusionTheme);

export const BrazilMap: React.FC<IMapProps> = ({ data }) => {
  const dataSource = useMemo(
    () => ({
      chart: {
        showLabels: "0",
        canvasbordercolor: "#ffffff",
        bordercolor: "#fff",
        showlegend: "0",
        showshadow: "0",
        entityfillhovercolor: "#212121",
        entityfillcolor: "#e9e9e9",
        entityborderThickness: "3",
        legendallowdrag: "0",
        legendshadow: "0",
        hoverFillalpha: "20",
        showborder: "1",
      },
      data: data,
    }),
    [data]
  );

  const chartConfigs = {
    type: "brazil",
    width: window.innerWidth < 400 ? 300 : "100%",
    height: window.innerWidth < 400 ? 300 : 450,
    dataFormat: "json",
    dataSource: dataSource,
    backgroundColor: "#000",
  };

  if (data.length > 0) {
    return <ReactFC {...chartConfigs} />;
  } else {
    return null;
  }
};
