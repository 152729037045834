import { format } from "date-fns";
import { PaymentRequest } from "services/types";
import { object, array, string, number } from "yup";

export const isExpired = (month: number, year: number) => {
  const d = new Date();
  if (year > parseInt(format(d, "yy"))) {
    return true;
  } else if (year === parseInt(format(d, "yy"))) {
    if (month >= parseInt(format(d, "MM"))) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export default class ValidationError extends Error {
  public readonly statusCode: number;
  public readonly name: string;
  public readonly message: string;

  constructor(message: string) {
    super();

    Error.captureStackTrace(this, ValidationError);

    this.statusCode = 400;
    this.message = message;
    this.name = "ValidationError";
  }
}

export async function paymentRequestValidator(
  body: PaymentRequest
): Promise<void> {
  const schema = object().shape({
    token: string().required(),
    session_id: string().required(),
    id_usuario: string().notRequired(),
    billets: array()
      .of(
        object().shape({
          identificador: string(),
          codigo_barras: string().required(),
          linha_digitavel: string(),
          valor_original: number().required(),
          valor_taxa: number().required(),
          valor_desconto: number().required(),
          valor_total: number().required(),
        })
      )
      .required(),
    paymentCards: array()
      .of(
        object().shape({
          id_condicao_pagamento: number().required(),
          type: number().required(),
          amount: number().required(),
          installment: number().required(),
          installment_amount: number().required(),
          name: string().required(),
          cvc: string().required(),
          expiry_month: string().required(),
          expiry_year: string().required(),
          document: string().required(),
          birthDate: string().required(),
          phone: string().required(),
          number: string().required(),
          security_code: string().required(),
        })
      )
      .required(),
    personalInformation: object()
      .shape({
        name: string().required(),
        document: string().required(),
        email: string().required(),
        phone: string().required(),
        birth_date: string().required(),
      })
      .required(),
    billingAddress: object()
      .shape({
        postalCode: string().required(),
        street: string().required(),
        complement: string(),
        district: string().required(),
        city: string().required(),
        state: string().required(),
        country: string().required(),
        number: string().required(),
      })
      .required(),
    partnerSearch: object()
      .shape({
        parceiro: string(),
        id_parceiro: string(),
        estado: string(),
        document: string(),
        renavam: string(),
        cod_busca: string(),
        license_plate: string(),
      })
      .notRequired(),
  });

  try {
    const response = await schema.validate(body);
  } catch (err: any) {
    throw new ValidationError(err.message);
  }
}
