import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 34px;

  @media screen and (max-width: 866px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const WrapperContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  max-width: 1440px;

  @media screen and (max-width: 866px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }
`;

export const ButtonLeft = styled.button`
  max-width: 408px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(5px);
  border-radius: 15px;
  border-style: solid;
  background: ${({ theme }) => theme.colors.background_border_gradient};
  border: 2px solid transparent;
  box-sizing: border-box;
  padding: 20px 40px;
  margin-right: 8px;
  cursor: pointer;

  @media screen and (max-width: 866px) {
    margin-right: 0px;
    margin-bottom: 16px;
  }
`;

export const ButtonRight = styled(ButtonLeft)`
  margin-left: 8px;
  margin-right: unset;

  @media screen and (max-width: 866px) {
    margin-left: 0px;
  }
`;

export const RowIconTitleButton = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

export const IconButton = styled.img`
  background-color: #fff;
`;

export const TitleButton = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #212121;
  text-align: left;
  margin: 0;
  padding-left: 10px;
`;

export const SubTitleButton = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #212121;
  text-align: left;
  margin: 0;
`;
