export type StagesProps = {
  enabledOpacity?: number;
};

export enum EnumStages {
  SEARCH = 1,
  CLIENT_IDENTIFICATION = 2,
  PAYMENT_FORM = 3,
  SUMMARY = 4,
  ORDER_DETAILS = 5,
}

export enum TypePayment {
  BILLET = "boleto",
  PARTNER = "parceiro",
}

export type Installment = {
  coeficiente_final: number;
  id_condicao_pagamento: number;
};

export const EnumStage = {
  SEARCH: {
    search: false,
    abstract: true,
    payment: true,
  },
  ABSTRACT: {
    search: true,
    abstract: false,
    payment: true,
  },
  PAYMENT: {
    search: true,
    abstract: true,
    payment: false,
  },
};

export const EnumPosibiltySearchInputDetran = {
  LICENSE_PLATE: {
    license_plate: true,
    renavam: false,
    chassi: false,
    document: false,
  },
  RENAVAM: {
    license_plate: false,
    renavam: true,
    chassi: false,
    document: false,
  },
  CHASSI: {
    license_plate: false,
    renavam: false,
    chassi: true,
    document: false,
  },
  DOCUMENT: {
    license_plate: false,
    renavam: false,
    chassi: false,
    document: true,
  },
  LICENSE_PLATE_AND_RENAVAM: {
    license_plate: true,
    renavam: true,
    chassi: false,
    document: false,
  },
  LICENSE_PLATE_AND_CHASSI: {
    license_plate: true,
    renavam: false,
    chassi: true,
    document: false,
  },
  LICENSE_PLATE_AND_DOCUMENT: {
    license_plate: true,
    renavam: false,
    chassi: false,
    document: true,
  },
  RENAVAM_AND_CHASSI: {
    license_plate: false,
    renavam: true,
    chassi: true,
    document: false,
  },
  RENAVAM_AND_DOCUMENT: {
    license_plate: false,
    renavam: true,
    chassi: false,
    document: true,
  },
  ALL: {
    license_plate: true,
    renavam: true,
    chassi: true,
    document: true,
  },
  RENAVAM_AND_DOCUMENT_AND_LICENSE_PLATE: {
    license_plate: true,
    renavam: true,
    chassi: false,
    document: true,
  },
};

export type Stage = {
  search: boolean;
  abstract: boolean;
  payment: boolean;
};

export type PosibilitySearchInputDetran = {
  license_plate: boolean;
  renavam: boolean;
  chassi: boolean;
  document: boolean;
};
