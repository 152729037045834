import React, { useEffect, useState, useMemo } from "react";
import { toast } from "react-toastify";
import { createPortal } from "react-dom";
import { useForm } from "react-hook-form";
import LoadingOverlay from "react-loading-overlay";
import { UseQueryOptions, useMutation, useQueries } from "react-query";

import { IStagesBillet } from "models";
import usePayment from "hooks/usePayment";
import { checkDigitableLineBankslipRegex } from "utils/billet";
import { BoxForm, Input, Button, BankslipItem } from "components";
import {
  BilletResponseType,
  HandleError,
  getBillet,
  getBilletPublic,
} from "services";

import Order1 from "assets/images/icons/order-1.svg";
import * as S from "./styles";

const THREE_MINUTES_IN_MILISECONDS = 60 * 3 * 1000;

export const Search: React.FC<IStagesBillet> = ({
  setStage,
  enable,
  idEstablishment,
  hasBilletActions = true,
}) => {
  const {
    dataPaymentBillet,
    setPaymentBilletData,
    removeBillet,
    updateMaxInstallmentNumber,
    updateRulesFromQuery,
  } = usePayment();

  const queryParameters = new URLSearchParams(window.location.search);
  const barcode = queryParameters.get("barcode");
  const [showInput, setShowInput] = useState<boolean>(true);
  const [barcodeQueries, setBarcodeQueries] = useState<
    UseQueryOptions<BilletResponseType & HandleError>[]
  >([]);

  const barcodesList = useMemo(() => {
    const barcodes = barcode?.split(";");
    return Array.from(new Set(barcodes));
  }, [barcode]);

  const hasOneBarcode = useMemo(() => {
    return barcodesList.length === 1;
  }, [barcodesList]);

  useEffect(() => {
    const fixedInstallmentParam = queryParameters.get("fixedInstallment");
    const fixedInstallment = fixedInstallmentParam
      ? Number(fixedInstallmentParam)
      : undefined;
    updateRulesFromQuery({
      fixedInstallment: Number.isNaN(fixedInstallment)
        ? undefined
        : fixedInstallment,
    });
  }, []);

  const barcodesResponses = useQueries(barcodeQueries);

  const isLoadingBarcodes: boolean =
    barcodesResponses.some((barcode) => barcode.isLoading) ||
    barcodesResponses.some((barcode) => barcode.isFetching);

  const { mutate: fetchBillet, isLoading: isLoadingBillet } = useMutation(
    (data: string) => getBillet(data, idEstablishment),
    {
      retry: false,
      onSuccess: (data) => {
        const billetHasAlreadyAdded = dataPaymentBillet.billets.some(
          (billet) => billet.id_bill === data.id_bill
        );

        if (billetHasAlreadyAdded) {
          toast.warning("Esse boleto já foi adicionado.");
          setValue("digitable_line", "");
          setShowInput(false);
          return;
        }

        const billets = [
          ...dataPaymentBillet.billets,
          { ...data, isBillet: true },
        ];

        setPaymentBilletData({
          ...dataPaymentBillet,
          billets,
        });
        updateMaxInstallmentNumber(billets);
        setValue("digitable_line", "");
        setShowInput(false);
      },
    }
  );

  const isLoading = isLoadingBarcodes || isLoadingBillet;

  const {
    handleSubmit,
    formState: { errors },
    setValue,
    control,
  } = useForm({
    mode: "all",
  });

  useEffect(() => {
    if (barcodeQueries.length) return;

    const barcodesLength = barcodesList.length;

    if (barcodesLength) {
      setBarcodeQueries(
        barcodesList.map((barcode) => ({
          queryKey: ["barcode", barcode],
          queryFn: () => getBilletPublic(barcode, idEstablishment),
          staleTime: THREE_MINUTES_IN_MILISECONDS,
          refetchOnWindowFocus: false,
          refetchOnMount: false,
          retry: false,
        }))
      );
    }
  }, []);

  useEffect(() => {
    const allBarcodeIsFetched = barcodesResponses.every(
      (barcode) => barcode.isFetched
    );

    if (allBarcodeIsFetched) {
      const barcodesSuccessfuly = barcodesResponses
        .filter((barcode) => barcode.isSuccess)
        .map((barcode) => ({
          ...barcode.data,
          isBillet: true,
        }));

      if (barcodesSuccessfuly.length) {
        setShowInput(false);

        updateMaxInstallmentNumber([
          ...dataPaymentBillet.billets,
          ...(barcodesSuccessfuly as BilletResponseType[]),
        ]);
      }

      setPaymentBilletData({
        ...dataPaymentBillet,
        billets: [
          ...dataPaymentBillet.billets,
          ...(barcodesSuccessfuly as BilletResponseType[]),
        ],
      });
    }
  }, [barcodesResponses.every((item) => item.isFetched)]);

  const onSubmitHandler = async ({ digitable_line }: any) => {
    await fetchBillet(digitable_line);
  };

  return (
    <S.SearchWrapper enable={enable}>
      {createPortal(
        <LoadingOverlay
          active={!hasOneBarcode && isLoadingBarcodes}
          text="Carregando seus boletos..."
          spinner
          styles={{
            wrapper(base) {
              return {
                ...base,
                width: "100vw",
                position: "absolute",
                top: 0,
                bottom: 0,
                display: !hasOneBarcode && isLoadingBarcodes ? "block" : "none",
              };
            },
          }}
        />,
        document.body
      )}

      <S.WrapperBox>
        <S.Row>
          <S.OrderImg src={Order1} />
          <S.Title>Adicione seus boletos</S.Title>
        </S.Row>

        {dataPaymentBillet?.billets
          ?.filter((billet) => billet.isBillet)
          ?.map((billet, index) => (
            <BankslipItem
              key={index}
              billet={billet}
              removeBillet={
                hasBilletActions ? () => removeBillet(index) : undefined
              }
            />
          ))}

        <BoxForm hide={showInput}>
          <S.WrapperBoxForm>
            <S.TitleForm>
              Cole aquela linha digitável. Daí fazemos nossa mágica acontecer!
            </S.TitleForm>

            <S.WrapperInput>
              <Input
                type="text"
                type_input="normal"
                placeholder="Linha digitável"
                control={control}
                name="digitable_line"
                error={errors?.digitable_line?.message as string}
                validate={checkDigitableLineBankslipRegex}
              />
            </S.WrapperInput>

            <S.WrapperButton>
              <S.Button>
                {dataPaymentBillet.billets.length > 0 ? (
                  <Button
                    title="Cancelar"
                    type_button="secondary"
                    action={() => setShowInput(false)}
                  />
                ) : null}
              </S.Button>
              <S.Button>
                <Button
                  title="Processar"
                  type_button="primary"
                  loading={hasOneBarcode ? isLoading : isLoadingBillet}
                  disabled={!!Object.keys(errors).length}
                  action={handleSubmit(onSubmitHandler)}
                />
              </S.Button>
            </S.WrapperButton>
          </S.WrapperBoxForm>
        </BoxForm>

        <S.WrapperButton>
          <S.Button3 style={{ marginRight: 10 }}>
            {hasBilletActions && (
              <Button
                title="Adicionar mais um boleto"
                type_button="secondary"
                action={() => setShowInput(true)}
                disabled={showInput}
                paddingHorizontal={20}
              />
            )}
          </S.Button3>
          <S.Button3>
            <Button
              title="Continuar"
              type_button="primary"
              action={() => setStage(2)}
              disabled={
                !dataPaymentBillet?.billets?.filter((billet) => billet.isBillet)
                  ?.length
              }
            />
          </S.Button3>
        </S.WrapperButton>
      </S.WrapperBox>
    </S.SearchWrapper>
  );
};
