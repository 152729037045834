import axios from "axios";
import { IViaCep } from "models/ZipCode";

export type FullAdressForm = {
  address: string;
  city: string;
  neighborhood: string;
  state: string;
  zip_code: string;
};

export async function getFullAdressByCep(cep: string): Promise<FullAdressForm> {
  try {
    const fullAddress = {} as FullAdressForm;

    if (cep && cep.replace(/\D/g, "").length === 8) {
      await axios
        .get(`https://viacep.com.br/ws/${cep.replace(/\D/g, "")}/json/`)
        .then((response) => {
          const dataCep = response.data as IViaCep;

          fullAddress["address"] = dataCep.logradouro;
          fullAddress["neighborhood"] = dataCep.bairro;
          fullAddress["city"] = dataCep.localidade;
          fullAddress["state"] = dataCep.uf;
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      fullAddress["address"] = "";
      fullAddress["neighborhood"] = "";
      fullAddress["city"] = "";
      fullAddress["state"] = "";
    }

    return fullAddress;
  } catch (error: any) {
    throw new Error(error.response.data);
  }
}
