// @ts-nocheck

type RenderWidgetOptions = {
  callback: (response: string) => void
  errorCallback?: () => void
  expiresCallback?: () => void
}
export default class reCaptcha {
  static getRecaptchaToken(): Promise<string> {
    return new Promise((res, rej) => {
      grecaptcha.enterprise.ready(function () {
        grecaptcha.enterprise
          .execute(process.env.REACT_APP_PARCELAMOS_RECAPTCHA_TOKEN as string, {
            action: "LOGIN",
          })
          .then(function (token: string) {
            res(token);
          })
          .catch((err: Error) => {
            rej(err);
          });
      });
    });
  }

  static renderWidget(elementId: string, options: RenderWidgetOptions) {
    return grecaptcha.enterprise.render(elementId, {
      sitekey: process.env.REACT_APP_PARCELAMOS_RECAPTCHA_TOKEN_CHECKBOX as string,
      theme: "light",
      callback: options.callback,
      "error-callback": options.errorCallback,
      "expired-callback": options.expiresCallback
    });
  }
}
