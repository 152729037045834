import { api } from "services/api";
import { useQuery } from "react-query";
import { RequestGetDetrans, ResponseGetDetrans } from "./dto/get-detrans.dto";
import { id_establishment_default } from "config/establishment";

export const useGetDetrans = (data: RequestGetDetrans) => {
  return useQuery(["get-detrans", data], () => getDetrans(data), {
    staleTime: 1000 * 60 * 10,
    retry: false,
  });
};

async function getDetrans({
  type,
  state,
  production,
  display,
  page,
  page_size,
  id_establishment,
}: RequestGetDetrans): Promise<ResponseGetDetrans> {
  const response = await api.get("/partner", {
    params: {
      type,
      state,
      production,
      display,
      page: page || 1,
      page_size: page_size || 30,
    },
    headers: {
      "id-establishment": id_establishment || id_establishment_default,
    },
  });

  return response.data;
}
