export function translateErrors(message: string) {
  if (message.length === 0) {
    return null;
  }

  if (
    message.includes("must be shorter than or equal to 11 characters") ||
    message.includes("must be longer than or equal to 9 characters")
  ) {
    return "O renavan deve conter entre 9 e 11 dígitos";
  }
  if (message.includes("Vehicle not found")) {
    return "Renavan do veículo não encontrado";
  }

  return `Erro: ${message} `;
}
