import Master from "assets/images/icons/master.svg";
import Elo from "assets/images/icons/elo.svg";
import Visa from "assets/images/icons/visa.svg";
import Amex from "assets/images/icons/amex.svg";
import Dinners from "assets/images/icons/diners-club.svg";
import Hipercard from "assets/images/icons/hipercard.svg";
import CreditCard from "assets/images/icons/credit-card.svg";
import Payment from "payment";

type Card = {
  cardNumber?: string;
  cardName?: string;
};

export function checkFlag(card: Card) {
  let cardTypes;

  if (card.cardNumber) {
    cardTypes = Payment.fns.cardType(card.cardNumber);
  } else {
    cardTypes = card.cardName;
  }

  if (!cardTypes) return CreditCard;

  if (cardTypes.toLowerCase() === "visa") {
    return Visa;
  } else if (cardTypes.toLowerCase() === "mastercard") {
    return Master;
  } else if (cardTypes.toLowerCase() === "amex") {
    return Amex;
  } else if (cardTypes.toLowerCase() === "diners") {
    return Dinners;
  } else if (cardTypes.toLowerCase() === "elo") {
    return Elo;
  } else if (cardTypes.toLowerCase() === "hipercard") {
    return Hipercard;
  }

  return CreditCard;
}
