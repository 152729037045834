import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 1196px;
  background: #312f30;
  padding: 64px 20px;
  box-sizing: border-box;
`;

export const Title = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  line-height: 58px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  margin-bottom: 128px;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 32px;

  @media screen and (max-width: 900px) {
    justify-content: center;
    align-items: center;
    padding-right: 0;
  }
`;

export const Column2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 32px;

  @media screen and (max-width: 900px) {
    justify-content: center;
    align-items: center;
    padding-left: 0;
    margin-top: 15px;
  }
`;

export const WrapperCards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  min-width: 300px;
  max-width: 577px;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: 0px 24px 24px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(2px);
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 24px 24px;
  box-sizing: border-box;
  margin-bottom: 16px;
`;

export const Img = styled.img``;

export const WrapperTextsCards = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 24px;

  @media (max-width: 425px) {
    margin-left: 10px;
  }
`;

export const TitleCard = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #ffffff;
  margin: 0;
`;

export const SubTitleCard = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #acacac;
  margin: 0;
`;

export const ParagraphMinor = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 19px;
  color: #acacac;
  margin: 0;
`;
