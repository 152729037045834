import React from 'react';
import {
  NavBar,
  Loading,
  CardFlags,
} from "components";
import { Search } from "./Search";

import * as S from "./styles";
import { useParams } from "react-router-dom";
import { useWhitelabel } from "hooks";
import { useGetWhiteLabel } from "services";
import { id_establishment_default } from "config/establishment";

export const Identifier = () => {
  const { id_usuario } = useParams();
  const { client } = useWhitelabel();
  const { isLoading } = useGetWhiteLabel(
    id_usuario || (client?.id_usuario as string)
  );
  
  return (
    <Loading isLoading={isLoading} size="large">
      <NavBar />

      <S.WrapperStages>
        <Search
          idEstablishment={
            id_usuario ?? client?.id_usuario ?? id_establishment_default
          }
        />

        <CardFlags />
      </S.WrapperStages>
    </Loading>
  );
};
