import React from "react";
import {
  CreditCardPaymentFailInfoCardContainer,
  Link,
  Row,
  StatusImg,
  SubTitle,
  Title,
} from "./styles";
import ErrorIcon from "../../assets/images/icons/error-circle.svg";
import { useWhitelabel } from "hooks";

export const CreditCardPaymentFailInfoCard: React.FC = () => {
  const { client } = useWhitelabel();
  return (
    <CreditCardPaymentFailInfoCardContainer>
      <Row>
        <StatusImg src={ErrorIcon} />
        <Title>Cobrança</Title>
      </Row>
      <Row>
        <SubTitle>
          Houve um erro na cobrança do seu cartão. Favor inicie um novo carrinho
          com um cartão válido.
        </SubTitle>
      </Row>
      <Row>
        <Link
          href={
            client?.id_usuario ? `/payment_online/${client.id_usuario}` : "/"
          }
        >
          Consultar novamente
        </Link>
      </Row>
    </CreditCardPaymentFailInfoCardContainer>
  );
};
