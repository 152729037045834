import React from "react";
import { ContainerTitle, ContainerText, Container } from "./styles";

const PolicyOfPrivate = () => (
  <Container>
    <ContainerTitle>
      <h1>Política de Privacidade</h1>
    </ContainerTitle>
    <ContainerText>
      <p>
        A Parcelamos Tudo coleta dados pessoais com a finalidade de prestar seus
        serviços. Somos comprometidos a preservar a privacidade e segurança de
        nossos usuários, com tal processamento de dados sendo feito em estrita
        conformidade às leis e regulamentos aplicáveis, em particular com o
        Regulamento Geral de Proteção de Dados da União Europeia (GDPR) e a Lei
        Geral de Proteção de Dados (LGPD). É altamente recomendado que os
        usuários leiam com atenção a presente Política de Privacidade.
        <h2>1. SOBRE A PARCELAMOS TUDO E A POLÍTICA DE PRIVACIDADE</h2>
        Esta Política de Privacidade e Segurança de Dados (“Política”) define as
        diretrizes para o tratamento e proteção das informações pessoais
        coletadas durante o acesso e uso do serviço de assinatura digital de
        contratos, prova de autenticidade de conteúdo WEB outros documentos
        eletrônicos oferecido por PARCELAMOS TUDO PONTOCOM SOLUCOES EM PAGAMENTO
        LTDA, sociedade empresária limitada inscrita no CNPJ/MF sob o nº
        36.063.350/0001-44, com sede na Rua Iguatemi, n.º 354, Itaim Bibi, São
        Paulo – SP, CEP 01.451-010.
        <p>
          Esta Política é complementar aos Termos de Uso que regulam o acesso e
          uso do Serviço pelo correspondente usuário (“Usuário”) quando o
          aplicativo de assinatura digital (“Aplicativo”) e/ou o website
          (“Website”) da Parcelamos Tudo e/ou outros softwares, serviços e/ou
          recursos correlatos são instalados, acessados e/ou utilizados (“Termos
          de Uso”), bem como, estabelece o tratamento que a Parcelamos Tudo
          concede às informações dos Usuários e dos terceiros envolvidos na
          assinatura digital de contratos e/ou outros documentos (“Signatários”)
          que são capturados e/ou armazenadas pela Parcelamos Tudo.
        </p>
        <h2>2. ALTERAÇÕES DA POLÍTICA DE PRIVACIDADE</h2>A Parcelamos Tudo se
        reserva o direito de, a seu exclusivo critério, modificar esta Política,
        incluindo novas regras, condições e/ou restrições, devendo comunicar
        isto ao Usuário através das plataformas de uso do Serviço, inclusive o
        Aplicativo e/ou o Website. A continuidade do acesso e/ou uso do Serviço
        pelo Usuário implicará em automática aceitação de eventuais atualizações
        desta.
        <h2>3. DADOS COLETADOS E SUA FINALIDADE</h2>
        Ao acessar o Aplicativo e/ou o Website, independentemente de cadastro,
        certas informações (dados sobre o navegador utilizado, tipo de
        dispositivo, tempo gasto, endereço de IP, sistema operacional,
        navegador, idioma do navegador, fuso horário, horário local e
        geolocalização) serão armazenados em servidores utilizados pela
        Parcelamos Tudo.
        <p>
          Estas e outras informações pessoais capturadas dos Usuários e
          Signatários são necessárias para sua identificação no contexto do
          acesso e utilização dos Serviços.
        </p>
        <p>
          A fim de prestar seus serviços de coleta de provas sobre conteúdo
          online por meio do aplicativo e plugin PACWeb, a Parcelamos Tudo
          coleta os seguintes dados: nome completo, CPF, endereço de e-mail,
          geolocalização, fuso horário, horário local, endereço de IP, sistema
          operacional, navegador e idioma do navegador. Tais dados são coletados
          para a identificação da autoria do relatório gerado, fornecendo assim
          maior grau de segurança e autenticidade para a prova coletada.
        </p>
        <p>
          Além disso, a Parcelamos Tudo também utiliza dados para fins de
          marketing e comunicação, incluindo a divulgação de produtos, serviços,
          atividades, promoções, campanhas e eventos nos quais a Parcelamos Tudo
          tenha parte, bem como informações de suporte ao usuário, por e-mail
          e/ou SMS.
        </p>
        <h2>4. ARMAZENAMENTO DOS DADOS</h2>
        As informações capturadas ficarão armazenadas e obedecem a padrões
        rígidos de confidencialidade e segurança, tais como a criptografia.
        Entretanto, é importante que o usuário tenha ciência de que pela própria
        natureza e características técnicas da Internet, essa proteção não é
        infalível e encontra-se sujeita à violação pelas mais diversas práticas
        maliciosas.
        <p>
          Tais informações serão utilizadas internamente para fins operacionais
          e estratégicos, envolvendo a administração do Website e do Serviço,
          incluindo, dentre outras hipóteses, realização de estatísticas e
          estudos, análise de tráfego, administração, gestão, ampliação e
          melhoramento das funcionalidades do Serviço, para customização.
        </p>
        <p>
          A Parcelamos Tudo armazenará os relatórios gerados pelo aplicativo e
          plugin PACWeb, a fim de otimizar a prestação dos seus serviços de
          coleta de provas sobre conteúdo online.
        </p>
        <p>
          A Parcelamos Tudo não realizará a guarda nem o arquivamento de
          qualquer contrato e/ou outro documento inserido (uploaded) para fins
          de assinatura por meio do Aplicativo. Por esta razão, o Usuário e/ou
          os Signatários dos contratos e/ou outros documentos deverão tomar
          todas as medidas necessárias para a guarda e arquivamento dos
          contratos e/ou outros documentos assinados através do Aplicativo.
        </p>
        <p>
          Os números de cartões de crédito ou débito fornecido pelos Usuários
          são utilizados somente no processamento dos pagamentos dos Serviços
          prestados, não sendo armazenados em banco de dados.
        </p>
        <h2>5. PRAZO DO ARMAZENAMENTO DOS DADOS</h2>
        No que tange ao serviço de coleta de provas sobre conteúdo online por
        meio do aplicativo e plugin PACWeb, a Parcelamos Tudo armazenará
        criptográficamente os dados referidos no tópico 3 em servidor próprio,
        de modo seguro e protegido contra perdas, má utilizações e acessos não
        autorizados. Estes dados serão utilizados somente para a finalidade
        específica das quais foram coletados e autorizados. Os hashes dos
        relatórios gerados também são armazenados e vinculados às contas de cada
        usuário.
        <p>
          Os demais dados coletados serão armazenados enquanto forem necessários
          para a atividade dos usuários.
        </p>
        <h2>6. RELAÇÃO COM TERCEIROS</h2>A Parcelamos Tudo poderá compartilhar
        os dados pessoais coletados com seus parceiros.
        <p>
          Nenhum documento e/ou informação pessoal será divulgado e/ou
          compartilhado em nenhuma hipótese, exceto se expressamente autorizado
          pelo Usuário e/ou Signatário ou mediante ordem judicial ou por
          determinação legal.
        </p>
        <p>
          Nenhum documento e/ou informação pessoal será vendido e as informações
          pessoais dos Usuários não serão expostas individualmente a quaisquer
          terceiros, exceto conforme estabelecido neste instrumento ou na forma
          da Lei e mediante ordem judicial.
        </p>
        <h2>7. DIREITOS E RESPONSABILIDADES DO USUÁRIO</h2>O Usuário garante e
        se responsabilizar pela veracidade, exatidão, vigência e autenticidade
        das fotos pessoais e outras informações que venha a fornecer para uso do
        Serviço, comprometendo-se a mantê-los atualizados. A Parcelamos Tudo não
        tem qualquer responsabilidade no caso de inserção de dados falsos ou de
        sua inexatidão, podendo, a seu exclusivo critério, suspender e/ou
        cancelar o cadastro do Usuário, a qualquer momento, caso seja detectada
        qualquer inexatidão.
        <p>
          Os usuários da Parcelamos Tudo, em cumprimento ao Regulamento Geral de
          Proteção de Dados, possuem garantidos os direitos à: - Transparência,
          informação, acesso e notificação, de modo a estarem cientes do
          tratamento dado a seus dados; - Retificação de dados incorretos e
          preenchimento de dados incompletos; - Apagamento, que pode ser
          solicitado pelo usuário, além de efetuado automaticamente nos casos
          previstos em lei, como na eventualidade de terem deixado de ser
          necessários para a finalidade pela qual foram coletados; - Oposição
          quanto à possibilidade de recebimento de comunicações diretas via
          e-mail e/ou SMS; - Retirada de consentimento para a coleta e
          utilização de dados, a qualquer tempo; - Não sujeição a decisões
          automatizadas, de modo a solicitar intervenção humana sempre que
          julgarem necessário; - Portabilidade, solicitando a transferência de
          seus dados a entidades terceiras.
        </p>
        <h2>8. CADASTRAMENTO E ACEITE DOS TERMOS E CONDIÇÕES</h2>O cadastramento
        como usuário para utilização do Serviço (“Usuário”) implica em aceitação
        plena e irrevogável de todos os termos e condições vigentes e publicados
        pela Parcelamos Tudo nos canais eletrônicos para aquisição do
        Aplicativo, inclusive aceitação desta Política, conforme eventualmente
        alterados.
        <p>
          Uma vez cadastrado, o Usuário poderá, a qualquer tempo, por meio de
          ferramenta oferecida no Aplicativo e/ou no Website, revisar e alterar
          suas informações de cadastro.
        </p>
        <p>
          O cadastramento do usuário também autoriza a Parcelamos Tudo a
          elaborar relatórios sobre os contratos e/ou os outros documentos
          assinados e disponibilizar estes relatórios ao Usuário e/ou aos demais
          Signatários.
        </p>
        <h2>9. PUBLICIDADE</h2>A Parcelamos Tudo poderá enviar comunicados e
        mensagens publicitárias ao Usuário fazendo uso de todas as tecnologias e
        os meios de comunicação disponíveis, seja por e-mail, SMS, MMS,
        mala-direta e outros. Todos os boletins eletrônicos e mensagens
        publicitárias enviadas por e-mail sempre trarão opção de cancelamento do
        envio daquele tipo de mensagem. O cancelamento será realizado no tempo
        mínimo necessário. As mensagens e notificações relacionadas ao Serviço
        não poderão ser canceladas, exceto se houver o cancelamento do próprio
        cadastro do Usuário.
        <p>
          Caso haja alguma dúvida sobre a Política,{" "}
          <strong>entre em contato conosco.</strong>
        </p>
      </p>
    </ContainerText>
  </Container>
);

export default PolicyOfPrivate;
