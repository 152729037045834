import styled from "styled-components";

export const CreditCardPaymentFailInfoCardContainer = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px dashed #dc2626;
  margin-bottom: 20px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const Title = styled.p`
  color: #000;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
`;

export const StatusImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 6px;
`;

export const SubTitle = styled.p`
  color: #6b7280;
  text-align: center;
  font-family: "Inter";
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0;
`;

export const Link = styled.a`
  color: #2c72ff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;
