/* eslint-disable no-mixed-spaces-and-tabs */
import { formatDuration, intervalToDuration } from "date-fns";
import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

import Clipboard from "assets/images/icons/clipboard.svg";
import { NavBar } from "components";
import { checkFlag } from "utils";
import {
  formatCountdown,
  formatCurrency,
  formatShortToDateString,
} from "utils/mask";
import { useGetCheckoutInfo } from "views/Billet/providers/get-checkout-info.provider";

import { Col, Row, Skeleton } from "antd";
import GreenCheck from "assets/images/icons/green-check.svg";
import YellowExclamation from "assets/images/icons/yellow-exclamation.svg";
import { CreditCardPaymentFailInfoCard } from "components/CreditCardPaymentFailInfoCard";
import { PaymentTracking } from "components/PaymentTracking";
import { useWhitelabel } from "hooks";
import { applyMaskToIdentifier } from "utils/identifier";
import { EnumChargeType } from "views/Billet/providers/dto/create-checkout.dto";
import { useGetCheckoutIdByIdentifierInfo } from "views/Identifier/providers/fetch-identifier.provider";
import * as S from "./styles";

export const OrderDetails: React.FC = () => {
  const { id_checkout } = useParams();
  const [countdownTimer, setCountdownTimer] = useState<number>(0);
  const [showPaymentTab, setShowPaymentTab] = useState<boolean>(true);
  const [checkoutId, setCheckoutId] = useState<string>(id_checkout || "");

  const { setClient, client } = useWhitelabel();
  const identifierLength = 12;

  const { data: checkout_id_response } = useGetCheckoutIdByIdentifierInfo(
    id_checkout ?? "",
    { enabled: id_checkout?.length === identifierLength }
  );

  useEffect(() => {
    if (id_checkout?.length === identifierLength) {
      setShowPaymentTab(false);
      setCheckoutId(checkout_id_response?.id_checkout ?? "");
    } else {
      setShowPaymentTab(true);
      setCheckoutId(id_checkout ?? "");
    }
  }, [id_checkout, checkout_id_response]);

  const { data: orderDetails, isLoading } = useGetCheckoutInfo(
    checkoutId,
    true
  );

  useEffect(() => {
    if (orderDetails?.white_label) {
      document.title = orderDetails.white_label.name;
      const newFavicon = document.createElement("link");
      newFavicon.rel = "icon";
      newFavicon.href = orderDetails.white_label.favicon_url;
      document.head.appendChild(newFavicon);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setClient(orderDetails.white_label as any);
    }
  }, [orderDetails?.white_label]);

  function calculateCountdown(due_date: string) {
    const duration = intervalToDuration({
      start: new Date(),
      end: new Date(due_date),
    });

    const newTime = formatDuration(duration, {
      delimiter: ", ",
      format: ["hours", "minutes", "seconds"],
    });

    return formatCountdown(newTime);
  }

  function getPixPaymentStatus(status: string) {
    switch (status) {
      case "WAITING_CHARGE":
        return (
          <S.PixStatusContainer>
            <S.Spinner />
            <S.PixInfoText>Aguardando pagamento...</S.PixInfoText>
          </S.PixStatusContainer>
        );
      case "CHARGE_DONE":
        return (
          <S.PixStatusContainer>
            <S.StatusImg src={GreenCheck} />
            <S.PixInfoText> Pagamento confirmado!</S.PixInfoText>
          </S.PixStatusContainer>
        );
      case "CHARGE_ERROR":
      case "CHARGE_DENIED":
      case "CHARGE_REVERSED":
      case "CHARGE_CANCELED":
        return (
          <S.PixStatusContainer>
            <S.StatusImg src={YellowExclamation} />
            <S.PixInfoText>Problema ao realizar pagamento!</S.PixInfoText>
          </S.PixStatusContainer>
        );
      case "EXPIRED":
        return (
          <S.PixStatusContainer>
            <S.StatusImg src={YellowExclamation} />
            <S.PixInfoText>Pagamento expirado!</S.PixInfoText>
          </S.PixStatusContainer>
        );
      default:
        return (
          <S.PixStatusContainer>
            Ops, estamos aguardando o status ainda...
          </S.PixStatusContainer>
        );
    }
  }

  const creditCardPaymentFail = useMemo(() => {
    if (!orderDetails?.id_status) return null;

    const cases = [
      "THREEDS_ERROR",
      "RISK_ANALYSIS_REPROVED",
      "CHARGE_NOT_SUCCEEDED",
    ];

    if (
      cases.includes(orderDetails?.id_status) &&
      (orderDetails?.charges[0]?.type === EnumChargeType.CREDIT_CARD ||
        orderDetails?.charges[0]?.type === EnumChargeType.DEBIT_CARD)
    ) {
      return <CreditCardPaymentFailInfoCard />;
    }

    return null;
  }, [orderDetails?.id_status]);

  const fetchPaymentMessage = useMemo(() => {
    const defaultMessage =
      "Já estamos processando seu pagamento, iremos te atualizar via email.";

    if (!orderDetails?.id_status) return defaultMessage;

    const casesSuccess = ["COMPLETED", "CHARGEBACK", "REVERSED", "REMOVED"];
    const casesError = [
      "THREEDS_ERROR",
      "RISK_ANALYSIS_REPROVED",
      "CHARGE_NOT_SUCCEEDED",
    ];
    if (casesSuccess.includes(orderDetails?.id_status)) {
      return "Parabéns, o pagamento foi finalizado!";
    }

    if (casesError.includes(orderDetails?.id_status)) {
      return "Ops, aconteceu um erro durante o seu pagamento!";
    }

    return defaultMessage;
  }, [orderDetails?.id_status]);

  const charges = useMemo(() => {
    if (
      orderDetails?.charges?.find((item) => item.type === EnumChargeType.PIX)
    ) {
      return (
        <S.PixCharge>
          <S.PixChargeLeftColumn>
            <S.CardAndClientLabel>Pix</S.CardAndClientLabel>
            {orderDetails?.charges?.map((charge) => (
              <S.PixChargeContent key={charge.id_charge}>
                <S.PixImg bordered={false} value={charge.pix_qr_code ?? ""} />
                <S.PixChargeInfoContainer>
                  {getPixPaymentStatus(charge.id_status)}
                  <S.AmountRow>
                    <S.PixChargeLabel>Valor</S.PixChargeLabel>
                    <S.AmountValue>
                      {formatCurrency(charge.amount_total)}
                    </S.AmountValue>
                  </S.AmountRow>
                  {charge.due_date && charge.id_status === "WAITING_CHARGE" ? (
                    <>
                      <S.AmountRow>
                        <S.PixChargeLabel>Vencimento</S.PixChargeLabel>
                        <S.AmountValue>
                          {formatShortToDateString(charge?.due_date)}
                        </S.AmountValue>
                      </S.AmountRow>

                      <S.AmountRow>
                        <S.PixChargeLabel>Expiração em</S.PixChargeLabel>

                        <S.AmountValue>
                          <>{calculateCountdown(charge?.due_date)}</>
                        </S.AmountValue>
                      </S.AmountRow>
                    </>
                  ) : null}

                  <S.QrCodeContainer>
                    <S.QrCodeRow>
                      <S.PixChargeLabel>Pix copia e cola</S.PixChargeLabel>
                      <S.CopyButton
                        onClick={() =>
                          navigator.clipboard.writeText(
                            charge?.pix_qr_code ?? ""
                          )
                        }
                      >
                        <S.ClipboardImg src={Clipboard} />
                      </S.CopyButton>
                    </S.QrCodeRow>

                    <S.QrCodetext>{charge?.pix_qr_code}</S.QrCodetext>
                  </S.QrCodeContainer>
                </S.PixChargeInfoContainer>
              </S.PixChargeContent>
            ))}
          </S.PixChargeLeftColumn>
          <S.PixChargeRightColumn>
            <S.CardAndClientLabel>Cliente</S.CardAndClientLabel>
            <S.ClientInfoText>{orderDetails?.client?.name}</S.ClientInfoText>
            <S.ClientInfoText>
              {orderDetails?.client?.document}
            </S.ClientInfoText>
          </S.PixChargeRightColumn>
        </S.PixCharge>
      );
    }
    if (
      orderDetails?.charges?.find(
        (item) =>
          item.type === EnumChargeType.CREDIT_CARD ||
          item.type === EnumChargeType.DEBIT_CARD
      )
    ) {
      const label =
        orderDetails?.charges[0]?.type === EnumChargeType.CREDIT_CARD
          ? "Cartão de Crédito"
          : "Cartão de Débito";
      return (
        <S.CardCharge>
          <S.CardChargeColum>
            <S.CardAndClientLabel>{label}</S.CardAndClientLabel>
            <S.CardInfoRow>
              <S.CardOperatorImage
                src={checkFlag({
                  cardName: orderDetails?.charges?.[0].card_brand,
                })}
              />
              <S.CardInfoText>
                •••• {orderDetails?.charges?.[0].card_last_four_digits}{" "}
              </S.CardInfoText>
            </S.CardInfoRow>
            <S.ValueCardInfoText>
              {orderDetails?.charges?.[0]?.installment_number} x{" "}
              {formatCurrency(orderDetails?.charges?.[0]?.installment_amount)}
            </S.ValueCardInfoText>
          </S.CardChargeColum>
          <S.CardChargeColum>
            <S.CardAndClientLabel>Cliente</S.CardAndClientLabel>
            <S.ClientInfoText>{orderDetails?.client?.name}</S.ClientInfoText>
            <S.ClientInfoText>
              {orderDetails?.client?.document}
            </S.ClientInfoText>
          </S.CardChargeColum>
        </S.CardCharge>
      );
    }

    if (isLoading) {
      return (
        <Skeleton.Button
          size="large"
          style={{ width: "100%", height: 150 }}
          active
        />
      );
    }

    return null;
  }, [orderDetails, countdownTimer, isLoading]);

  const payments = useMemo(() => {
    if (isLoading) {
      return (
        <Skeleton.Button
          size="large"
          style={{ width: "100%", height: 80 }}
          active
        />
      );
    }

    if (!orderDetails?.payments.length) return null;

    return orderDetails.payments.map((payment) => (
      <S.PaymentCard key={payment?.id_payment}>
        <S.PaymentLabel>{payment?.title}</S.PaymentLabel>
        <S.PaymentInfo>{payment?.description}</S.PaymentInfo>

        <S.PaymentCardRow>
          <S.PaymentAmount>{formatCurrency(payment?.amount)}</S.PaymentAmount>
          {payment?.license_plate || payment?.renavam ? (
            <S.VerticalBar />
          ) : null}
          {payment?.license_plate ? (
            <>
              <S.PlateAndRenavanLabel>Placa</S.PlateAndRenavanLabel>
              <S.PlateAndRenavanText>
                {payment?.license_plate}
              </S.PlateAndRenavanText>
            </>
          ) : null}
          {payment?.renavam ? (
            <>
              <S.PlateAndRenavanLabel>Renavam</S.PlateAndRenavanLabel>
              <S.PlateAndRenavanText>{payment?.renavam}</S.PlateAndRenavanText>
            </>
          ) : null}
        </S.PaymentCardRow>
      </S.PaymentCard>
    ));
  }, [orderDetails, isLoading]);

  const footerAmount = useMemo(() => {
    if (orderDetails?.amount && orderDetails?.total_amount) {
      return (
        <S.SectionContainer>
          <S.AmountRow>
            <S.AmountLabel>Subtotal</S.AmountLabel>
            <S.AmountValue>
              {formatCurrency(orderDetails?.amount)}
            </S.AmountValue>
          </S.AmountRow>
          <S.AmountRow>
            <S.AmountLabel>Taxa</S.AmountLabel>
            <S.AmountValue>
              {formatCurrency(orderDetails?.fee_amount)}
            </S.AmountValue>
          </S.AmountRow>
          <S.AmountRow>
            <S.AmountLabel>
              <strong>Total</strong>
            </S.AmountLabel>
            <S.AmountValue>
              <strong>{formatCurrency(orderDetails?.total_amount)}</strong>
            </S.AmountValue>
          </S.AmountRow>
        </S.SectionContainer>
      );
    }
    return (
      <Col span={24}>
        <Skeleton paragraph={{ rows: 3, width: "100%" }} title={false} active />
      </Col>
    );
  }, [orderDetails]);

  const paymentTracking = useMemo(() => {
    if (!isLoading && orderDetails) {
      return (
        <PaymentTracking
          stageStatus={orderDetails?.id_status}
          type={orderDetails?.charges[0].type}
          showPaymentTab={showPaymentTab}
        />
      );
    }

    return (
      <Row
        style={{
          display: "flex",
          width: "auto",
          flexDirection: "column",
          marginTop: 15,
        }}
      >
        <Skeleton.Button
          size="large"
          style={{ width: "100%", height: 80 }}
          active
        />
      </Row>
    );
  }, [orderDetails, isLoading]);

  const pageMessage = useMemo(() => {
    if (!isLoading) {
      return <S.SubText>{fetchPaymentMessage}</S.SubText>;
    }

    return (
      <Skeleton paragraph={{ rows: 1, width: "75%" }} active title={false} />
    );
  }, [orderDetails]);

  useEffect(() => {
    const validateHasPix = orderDetails?.charges?.some(
      (item) => item.type === "PIX"
    );

    if (validateHasPix) {
      setInterval(function () {
        setCountdownTimer((prev) => prev + 1);
      }, 1000);
    }
  }, [orderDetails]);

  return (
    <React.Fragment>
      <NavBar isLoading={isLoading || !client?.id_usuario} />
      <S.PageContent>
        <S.OrderDetailsWrapper>
          <S.BoxTitleAndIdentifier>
            <S.LinearGradientText>Obrigado!</S.LinearGradientText>
            <S.SubText>
              {applyMaskToIdentifier(orderDetails?.identifier)}
            </S.SubText>
          </S.BoxTitleAndIdentifier>

          <S.HeaderText>Detalhes do seu pedido!</S.HeaderText>
          {pageMessage}

          {paymentTracking}

          {payments && (
            <>
              <S.LineDivider />
              <S.SectionContainer>
                <S.ContainerLabel>Pagamentos</S.ContainerLabel>

                {payments}
              </S.SectionContainer>
            </>
          )}

          <S.LineDivider />

          <S.SectionContainer>
            <S.ContainerLabel>Cobrança</S.ContainerLabel>

            {creditCardPaymentFail}

            {charges}
          </S.SectionContainer>

          <S.LineDivider />

          {footerAmount}
        </S.OrderDetailsWrapper>
      </S.PageContent>
    </React.Fragment>
  );
};
