import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  border-style: solid;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(153.22deg, #ffc706 0%, #adff00 100%) border-box;
  border: 4px solid transparent;
  padding: 20px 30px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  box-sizing: border-box;
`;
