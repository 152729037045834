import { cnpj } from "cpf-cnpj-validator";
import { cpf } from "cpf-cnpj-validator";
import * as yup from "yup";

export const SchemaPartnerSearch = yup.object().shape({
  document: yup
    .string()
    .optional()
    .test("validateCpfCnpj", "Documento inválido.", (val: any) => {
      if (val) {
        const valueOnlyNumbers = val.replace(/\D/g, "");

        if (valueOnlyNumbers.length === 11) {
          return cpf.isValid(valueOnlyNumbers);
        }
        if (valueOnlyNumbers.length === 14) {
          return cnpj.isValid(valueOnlyNumbers);
        }
        return false;
      }

      return true;
    }),
  renavam: yup
    .string()
    .optional()
    .test(
      "is-valid-length",
      "Renavam Invalido.",
      (value) => !value || /^\d{9,11}$/.test(value)
    ),
  license_plate: yup
    .string()
    // .required("Placa é obrigatório.")
    .optional()
    .test("validateLicensePlate", "Placa inválida.", (val: any) => {
      if (val) {
        if (val.length === 7) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),
});
