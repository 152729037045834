import styled from "styled-components";

export const WrapperStages = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  padding: 0px 100px;

  @media screen and (max-width: 768px) {
    padding: 0px 20px;
  }
`;

export const Button = styled.div`
  width: fit-content;

  @media screen and (max-width: 768px) {
    padding: 0px 20px;
  }
`;

export const WrapperLoading = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;