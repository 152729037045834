import styled from "styled-components";
import { deviceMinWidth } from "theme/theme";

export const SearchWrapper = styled.div<{ enable?: boolean }>`
	width: clamp(350px, 100%, 530px);
	padding: 0;
	box-sizing: border-box;

	display: ${({ enable }) => (enable ? "flex" : "none")};

	@media ${deviceMinWidth.mobileL} {
		padding: 20px;
	}
`;

export const WrapperLoading = styled.div`
	height: 100vh;
	padding: 16px 28px;
	box-sizing: border-box;
	align-items: center;
	justify-content: center;
	display: flex;
	flex-direction: column;

	margin: 0 auto;
`;

export const WrapperBox = styled.div`
	width: 100%;
	height: 100%;
	padding: 30px 20px;
	box-sizing: border-box;
`;

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 24px;
`;

export const OrderImg = styled.img``;

export const Title = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 28px;
	line-height: 34px;
	color: #212121;
	padding-left: 24px;
	margin: 0;
`;

export const TitleForm = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	line-height: 24px;
	color: #312f30;
	margin: 0;
	margin-bottom: 21px;
`;

export const WrapperBoxForm = styled.div`
	width: 100%;
	padding: 24px 24px;
	box-sizing: border-box;
`;

export const WrapperInput = styled.div`
	width: 100%;
	margin-bottom: 16px;
`;

export const WrapperButton = styled.div`
	width: 100%;
	display: flex;
	align-self: center;
	justify-content: center;
	flex-wrap: wrap;

	@media ${deviceMinWidth.mobileL} {
		justify-content: flex-end;
	}
`;

export const Button = styled.div`
	width: 114px;
	align-self: flex-end;
	margin-right: 10px;
`;

export const Button2 = styled.div`
	width: 100%;
	margin-top: 30px;
`;

export const Button3 = styled.div`
	margin-top: 34px;
`;

export const ResumeWrapper = styled(SearchWrapper)`
	width: 330px;
`;

export const WrapperValue = styled.div`
	border: 1px dashed rgba(33, 33, 33, 0.12);
	border-radius: 4px;
	padding: 10px 10px;
	margin-bottom: 24px;
`;

export const LabelValue = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #212121;
	margin: 0;
	padding-bottom: 5px;
`;

export const Value = styled.div`
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	color: #212121;
	margin: 0;
`;

export const Dash = styled.div`
	border: 1px dashed #000000;
	margin-bottom: 24px;
`;

export const IdentifyYourself = styled.img``;

export const WrapperIdentifyTexts = styled.div`
	padding-left: 10px;
	padding-right: 10px;
`;

export const TitleIdentify = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	color: #212121;
	margin: 0;
	margin-bottom: 9px;
	text-align: left;
`;

export const SubTitleIdentify = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #212121;
	margin: 0;
	text-align: left;
`;

export const IdentifyYourselfButton = styled.button`
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: 0;
	cursor: pointer;
`;

export const PaymentWrapper = styled(SearchWrapper)`
	width: 610px;
`;

export const WrapperTypePayment = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 30px;
`;

export const WrapperRadio = styled.div`
	margin-right: 30px;
`;
