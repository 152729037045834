import React, { useMemo } from 'react';
import { Modal } from "antd";
import { Button } from "components/Button";
import { create, InstanceProps } from 'react-modal-promise'
import { LuInfo } from "react-icons/lu";
import * as S from './styles'

type DialogComponentProps = InstanceProps<unknown, unknown> & {
  button: {
    label: string;
  },
  type?: 'info' | 'error'
  title: string;
  description: string;
}

export function DialogComponent({
  isOpen,
  onResolve,
  button,
  title,
  description,
  type = 'info'
}: DialogComponentProps) {

  const icon = useMemo(() => {
    if (type === 'info') {
      return {
        background: '#3c82f61a',
        color: '#3C82F6'
      }
    }

    return {
      background: '#FEE2E2',
      color: '#DC2626'
    }
  }, [type])

  return (
    <Modal
      open={isOpen}
      closable={false}
      footer={false}
      centered
    >
      <S.Container>
        <div>
          <S.IconContainer style={{ backgroundColor: icon.background }}>
            <LuInfo size={20} color={icon.color} />
          </S.IconContainer>
        </div>
        <div>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
        </div>
      </S.Container>

      <Button title={button.label} type_button="primary" style={{ width: 'max-content', marginTop: 16, marginLeft: 'auto' }} onClick={onResolve} />
    </Modal>
  )
}

const dialog = create(DialogComponent)

export default dialog