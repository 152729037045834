import { ClienteWhitelabel } from "models";
import React, { createContext, useState, useContext, useMemo } from "react";
import { theme } from "theme/theme";
import tinycolor from "tinycolor2";
import { getContrastRatio } from "utils/helperStyle";

interface IWhitelabelContext {
  client?: ClienteWhitelabel;
}

interface CartContextData extends IWhitelabelContext {
  setClient: (data: ClienteWhitelabel) => void;
  cleanClient: () => void;
  services: IServices;
  setUserIdWhitelabel: (data: string) => void;
  userIdWhitelabel: string; // id_establishment
  setUserNameWhitelabel: (data: string) => void;
  userNameWhitelabel: string;
  isActived: boolean;
}

const WhitelabelContext = createContext<CartContextData>({} as CartContextData);

const WhitelabelDefault: IWhitelabelContext = {
  client: undefined,
};

interface WhitelabelProviderProps {
  children: React.ReactNode;
}

export interface IServices {
  pay_billet_installments: boolean;
  label_pay_billet_installments?: string;
  description_pay_billet_installments?: string;

  pay_taxes: boolean;
  label_pay_taxes?: string;
  description_pay_taxes?: string;

  pay_vehicle_debts: boolean;
  label_pay_vehicle_debts?: string;
  description_pay_vehicle_debts?: string;

  recharge_cell_phone: boolean;
  label_recharge_cell_phone?: string;
  description_recharge_cell_phone?: string;

  transfer_bank: boolean;
  label_transfer_bank?: string;
  description_transfer_bank?: string;
}

export const WhitelabelProvider: React.FC<WhitelabelProviderProps> = ({
  children,
}: WhitelabelProviderProps) => {
  const [whitelabelData, setWhitelabelData] = useState<IWhitelabelContext>(
    () => WhitelabelDefault
  );

  const [userIdWhitelabel, setUserIdWhitelabel] = useState<string>(
    process.env.REACT_APP_PARCELAMOS_TUDO_ESTABLISHMENT || ""
  );
  const [userNameWhitelabel, setUserNameWhitelabel] =
    useState<string>("Parcelamos Tudo");

  const [services, setServices] = useState<IServices>({
    pay_billet_installments: true,
    pay_taxes: true,
    pay_vehicle_debts: true,
    recharge_cell_phone: true,
    transfer_bank: false,
  });

  const setClient = (data: ClienteWhitelabel) => {
    setWhitelabelData({
      ...whitelabelData,
      client: data,
    });

    setUserIdWhitelabel(data.id_usuario);
    setUserNameWhitelabel(data.name);

    const color1 = tinycolor(data.primary_color).getBrightness();
    const color2 = tinycolor(data.secondary_color).getBrightness();

    let light = color1 > color2 ? data.secondary_color : data.primary_color;

    while (tinycolor(light).getBrightness() >= 180) {
      light = tinycolor(light).darken(5).toHexString();

      const contrastRatio = getContrastRatio(light, '#ffffff')

      if (contrastRatio >= 2) {
        break; 
      }
    }

    theme.colors = {
      primary: data.primary_color,
      secondary: data.secondary_color,
      background_gradient: `linear-gradient(90deg, ${light} 0%, ${light} 100%)`,
      background_border_gradient: `linear-gradient(white, white) padding-box padding-box, linear-gradient(153.22deg, ${light} 0%, ${light} 100%) border-box border-box`,
    };

    setServices({
      pay_billet_installments: data.pay_billet_installments,
      label_pay_billet_installments: data.label_pay_billet_installments,
      description_pay_billet_installments:
        data.description_pay_billet_installments,
      pay_taxes: data.pay_taxes,
      label_pay_taxes: data.label_pay_taxes,
      description_pay_taxes: data.description_pay_taxes,
      pay_vehicle_debts: data.pay_vehicle_debts,
      label_pay_vehicle_debts: data.label_pay_vehicle_debts,
      description_pay_vehicle_debts: data.description_pay_vehicle_debts,
      recharge_cell_phone: data.recharge_cell_phone,
      label_recharge_cell_phone: data.label_recharge_cell_phone,
      description_recharge_cell_phone: data.description_recharge_cell_phone,
      transfer_bank: data.transfer_bank,
      label_transfer_bank: data.label_transfer_bank,
      description_transfer_bank: data.description_transfer_bank,
    });
  };

  const cleanClient = () => {
    setWhitelabelData(WhitelabelDefault);
  };

  const isActived = useMemo(() => {
    return !!whitelabelData.client;
  }, [whitelabelData]);

  const contextValue = useMemo(
    () => ({
      ...whitelabelData,
      setClient,
      cleanClient,
      services,
      setUserIdWhitelabel,
      setUserNameWhitelabel,
      userIdWhitelabel,
      userNameWhitelabel,
      isActived,
    }),
    [
      whitelabelData,
      setClient,
      cleanClient,
      services,
      setUserIdWhitelabel,
      setUserNameWhitelabel,
      userIdWhitelabel,
      userNameWhitelabel,
      isActived,
    ]
  );

  return (
    <WhitelabelContext.Provider value={contextValue}>
      {children}
    </WhitelabelContext.Provider>
  );
};

export function useWhitelabel(): CartContextData {
  const context = useContext(WhitelabelContext);

  if (!context) {
    throw new Error("WhitelabelContext not provided");
  }

  return context;
}
