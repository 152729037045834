import * as yup from "yup";
import { cnpj, cpf } from "cpf-cnpj-validator";

export const SchemaRegister = yup.object().shape({
  name: yup.string().required("Campo obrigatório"),
  cpf: yup.string().required("Campo obrigatório"),
  email: yup.string().required("Campo obrigatório"),
  phone: yup.string().required("Campo obrigatório"),
  zip_code: yup.string().required("Campo obrigatório"),
  address: yup.string().required("Campo obrigatório"),
  neighborhood: yup.string().required("Campo obrigatório"),
  city: yup.string().required("Campo obrigatório"),
  password: yup.string().required("Campo obrigatório"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password")], "Confirmação de senha incorreta."),
  state: yup.string().required("Campo obrigatório"),
  code_country: yup.string(),
});

export const SchemaRegisterOptional = yup.object().shape({
  name: yup
    .string()
    .required("Campo obrigatório")
    .test("validateFullName", "Digite seu nome e sobrenome.", (val: any) => {
      if (val) {
        const name = val.trim().split(" ");
        const nameSize = name.length;
        if (nameSize > 1) {
          return true;
        } else {
          return false;
        }
      }
    }),
  document: yup
    .string()
    .required("Campo obrigatório")
    .test("validateCpfCnpj", "Documento inválido.", (val: any) => {
      if (val) {
        if (val.length < 15 && cpf.isValid(val)) {
          return true;
        } else if (val.length > 14 && cnpj.isValid(val)) {
          return true;
        } else {
          return false;
        }
      }
    }),
  email: yup
    .string()
    .required("Campo obrigatório")
    .email("Digite um e-mail válido"),
  phone: yup
    .string()
    .required("Campo obrigatório")
    .min(15, "O número deve conter 11 dígitos DDD + telefone")
    .max(15),
  zip_code: yup
    .string()
    .required("Campo obrigatório")
    .matches(/^\d{5}-?\d{3}$/, "CEP Inválido"),
  address: yup.string().required("Campo obrigatório"),
  neighborhood: yup.string().required("Campo obrigatório"),
  city: yup.string().required("Campo obrigatório"),
  state: yup.string().required("Obrigatório"),
  code_country: yup.string(),
  number: yup.number().required("Obrigatório"),
});
