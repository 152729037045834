export const states = [
  {
    id: "001",
    color: "#ADFF00",
    state: "AC",
    logo_url: "",
  },
  {
    id: "002",
    color: "#ADFF00",
    state: "AL",
    logo_url: "",
  },
  {
    id: "003",
    color: "#ADFF00",
    state: "AP",
    logo_url: "",
  },
  {
    id: "004",
    color: "#ADFF00",
    state: "AM",
    logo_url: "",
  },
  {
    id: "005",
    color: "#ADFF00",
    state: "BA",
    logo_url: "",
  },
  {
    id: "006",
    color: "#ADFF00",
    state: "CE",
    logo_url: "",
  },
  {
    id: "008",
    color: "#ADFF00",
    state: "ES",
    logo_url: "",
  },
  {
    id: "009",
    color: "#ADFF00",
    state: "GO",
    logo_url: "",
  },
  {
    id: "010",
    color: "#ADFF00",
    state: "MA",
    logo_url: "",
  },
  {
    id: "011",
    color: "#ADFF00",
    state: "MT",
    logo_url: "",
  },
  {
    id: "012",
    color: "#ADFF00",
    state: "MS",
    logo_url: "",
  },
  {
    id: "013",
    color: "#ADFF00",
    state: "MG",
    logo_url: "",
  },
  {
    id: "014",
    color: "#ADFF00",
    state: "PA",
    logo_url: "",
  },
  {
    id: "015",
    color: "#ADFF00",
    state: "PB",
    logo_url: "",
  },
  {
    id: "016",
    color: "#ADFF00",
    state: "PR",
    logo_url: "",
  },
  {
    id: "017",
    color: "#ADFF00",
    state: "PE",
    logo_url: "",
  },
  {
    id: "018",
    color: "#ADFF00",
    state: "PI",
    logo_url: "",
  },
  {
    id: "019",
    color: "#ADFF00",
    state: "RJ",
    logo_url: "",
  },
  {
    id: "020",
    color: "#ADFF00",
    state: "RN",
    logo_url: "",
  },
  {
    id: "021",
    color: "#ADFF00",
    state: "RS",
    logo_url: "",
  },
  {
    id: "022",
    color: "#ADFF00",
    state: "RO",
    logo_url: "",
  },
  {
    id: "023",
    color: "#ADFF00",
    state: "RR",
    logo_url: "",
  },
  {
    id: "024",
    color: "#ADFF00",
    state: "SC",
    logo_url: "",
  },
  {
    id: "025",
    color: "#ADFF00",
    state: "SP",
    logo_url: "",
  },
  {
    id: "026",
    color: "#ADFF00",
    state: "SE",
    logo_url: "",
  },
  {
    id: "027",
    color: "#ADFF00",
    state: "TO",
    logo_url: "",
  },
] as { id: string; color: string; state: string; logo_url?: string }[];
