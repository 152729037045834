import React from "react";
import Whats from "assets/images/icons/whats.svg";
import FB from "assets/images/icons/fb.svg";
import TT from "assets/images/icons/tt.svg";

import * as S from "./styles";

export const TalkToUsSection: React.FC = () => {
  return (
    <S.Container>
      <S.Text>Fale com a gente!</S.Text>
      <S.IconWhats src={Whats} />
      <S.Text2>+55 (11) 4040-9067</S.Text2>
      <S.Text2>contato@parcelamostudo.com.br</S.Text2>
      <S.Text3>
        Rua Apeninos, 429
        <br />
        Aclimação, São Paulo - SP 01533-000
      </S.Text3>

      <S.LinksContainer>
        <S.CompanyPoliticsAndPrivacyLink to="/termos-de-uso">
          Políticas de Uso
        </S.CompanyPoliticsAndPrivacyLink>{" "}
        <S.CompanyPoliticsAndPrivacyLink
          to=""
          style={{ pointerEvents: "none" }}
        >
          e
        </S.CompanyPoliticsAndPrivacyLink>{" "}
        <S.CompanyPoliticsAndPrivacyLink to="/politica-de-privacidade">
          Privacidade
        </S.CompanyPoliticsAndPrivacyLink>
      </S.LinksContainer>

      <S.Gradient>
        <S.WrapperIcons>
          <S.Link
            href="https://www.facebook.com/parcelamostudo/?locale=pt_BR"
            target="_blank"
          >
            <S.IconFB src={FB} />
          </S.Link>
          <S.Link href="https://twitter.com/parcelamostudo" target="_blank">
            <S.IconTT src={TT} />
          </S.Link>
        </S.WrapperIcons>
        <S.Text4>
          Site desenvolvido com muito amor pela equipe Parcelamos
        </S.Text4>
      </S.Gradient>
    </S.Container>
  );
};
