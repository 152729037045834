import React from "react";
import { NavBar } from "components";
import TermsOfUse from "components/TermsOfUse";
import { Container } from "./styles";

const TermsOfUsePage: React.FC = () => {
  return (
    <div>
      <NavBar />

      <Container>
        <TermsOfUse />
      </Container>
    </div>
  );
};

export default TermsOfUsePage;
