import React, { useEffect, useMemo, useState } from "react";
import * as S from "./styles";
import { BoxForm, Input, Button, Select } from "components";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { BankslipItem } from "components/BankslipItem";
import { IStagesPartner } from "models";

import { Alert, Spin } from "antd";
import usePayment from "hooks/usePayment";
import { yupResolver } from "@hookform/resolvers/yup";
import { SchemaPartnerSearch } from "models/Schemas/Partner";
import { useGetDetrans } from "../providers/get-detrans.provider";
import { useGetDebits } from "../providers/get-debits.provider";
import { DetranItem } from "../providers/dto/get-detrans.dto";
import { translateErrors } from "utils/errors";

export const Search: React.FC<IStagesPartner> = ({
  setStage,
  enable,
  idEstablishment,
}) => {
  const {
    dataPaymentBillet,
    setPaymentBilletData,
    clearPaymentBilletData,
    detran,
    setDetran,
    clearDetran,
    removeDebit,
    setUserId,
    dataPartnerSearch,
    setDataPartnerSearch,
  } = usePayment();

  const fields = ["license_plate", "document", "chassi", "renavam", "detran"];

  const queryParameters = new URLSearchParams(window.location.search);
  const license_plate_query = queryParameters.get("license_plate");
  const renavan_query = queryParameters.get("renavam");
  const id_detran = queryParameters.get("id_detran");

  const [loadingLicensePlate] = useState<boolean>(false);

  const { data: detrans, isLoading: isLoadingGetPartnerDetran } = useGetDetrans(
    {
      id_establishment: idEstablishment,
    }
  );

  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    watch,
    clearErrors,
  } = useForm({
    mode: "all",
    resolver: yupResolver(SchemaPartnerSearch),
  });

  const [license_plate, detranValue, renavam, document] = watch([
    "license_plate",
    "detran",
    "renavam",
    "document",
  ]);

  const isDisabled = useMemo(() => {
    if (detranValue && (license_plate || renavam || document)) return false;

    return true;
  }, [license_plate, detranValue, renavam, document]);

  useEffect(() => {
    if (detrans && id_detran) {
      const detran = detrans.data.find((item) => item.id_partner === id_detran);

      if (detran) {
        setValue("detran", detran.state);
        handleShowLogoDetran(detran.state);

        if (!isLoadingGetPartnerDetran) {
          handleLicensePlateFromQuery(detran);
        }
      }
    }
  }, [detrans]);

  useEffect(() => {
    console.debug("form errors", errors);
  }, [errors]);


  const {
    mutate: getDebits,
    isLoading: isLoadingGetDebts,
    variables,
  } = useGetDebits({
    onSuccess: (data) => {
      if (!data.debits.length) {
        toast.warning("Não foram encontrados débitos para este veículo.");
        return;
      }

      setDataPartnerSearch({
        license_plate: data?.license_plate ?? license_plate,
        renavam: data?.renavam,
        id_parceiro: detran?.id_partner,
        parceiro: `Detran ${variables?.state ?? detran?.state}`,
      });

      setPaymentBilletData({
        ...dataPaymentBillet,
        debits: data.debits,
      });
    },
    onError: (error) => {
      const message = Array.isArray(error.response?.data?.message)
        ? error.response?.data?.message?.[0]
        : error.response?.data?.message;

      toast.warning(translateErrors(message));
    },
    retry: false,
  });

  const fetchDebtsVehicle = async (data: any) => {
    getDebits({
      id_establishment: idEstablishment,
      state: data.detran ? data.detran : detran.state,
      license_plate: data?.license_plate ? data.license_plate : undefined,
      renavam: data?.renavam ? data.renavam : undefined,
      document: data?.document ? data.document?.replace(/\D/g, "") : undefined,
    });
  };

  const fetchDebtsVehicleAgain = async () => {
    if (variables) getDebits(variables);
  };

  const handleShowLogoDetran = (state: string) => {
    const detran = detrans?.data.find(
      (detran) => detran.state === state
    ) as DetranItem;
    setUserId(detran?.id_partner ?? "");
    setDetran(detran);
    clearFormInformations("partial");
  };

  const clearFormInformations = (type: "total" | "partial" = "total") => {
    fields.forEach((field) => {
      if (type === "total" || (type === "partial" && field !== "detran")) {
        setValue(field, "");
      }
    });
    clearErrors();
  };

  const cleanForm = () => {
    clearPaymentBilletData();
    clearFormInformations();
    setDetran({} as DetranItem);
  };

  function handleDetranUrlParse(partner: DetranItem) {
    setValue("detran", partner.state);

    setUserId(partner?.id_partner ?? "");

    setDetran(partner);
  }

  const handleLicensePlateFromQuery = async (partner: DetranItem) => {
    const shouldFetchDebts = !dataPaymentBillet?.debits?.length;

    const handleQueries = async (
      query: string,
      queryType: "renavam" | "license_plate"
    ) => {
      cleanForm();
      setValue(queryType, query);
      handleDetranUrlParse(partner);

      if (shouldFetchDebts) {
        await fetchDebtsVehicle({
          [queryType]: query,
          detran: partner.state,
        });
      }
    };

    if (id_detran) {
      handleDetranUrlParse(partner);
    }

    if (renavan_query && id_detran) {
      await handleQueries(renavan_query, "renavam");
    }

    if (license_plate_query && id_detran) {
      await handleQueries(license_plate_query, "license_plate");
    }
  };

  const hasAlert = useMemo(() => {
    // const detrans_sp = "1f51d736-4b92-4286-bed1-2be768c5dff2";
    const detrans_sp = "34ca1eba-146d-4d11-8b05-ff5c24cd1120";

    if (dataPartnerSearch.id_parceiro !== detrans_sp) {
      return null;
    }

    return (
      <Alert
        message="Atenção"
        description="A consulta é limitada em até 15 resultados pela SEFAZ. Para visualizar os outros débitos, é necessário o pagamento destes."
        type="warning"
      />
    );
  }, [dataPartnerSearch]);

  useEffect(() => clearDetran());

  return (
    <S.SearchWrapper enable={enable}>
      <S.WrapperBox>
        <S.Row style={{ padding: 0, justifyContent: "center" }}>
          <S.Title style={{ padding: 0 }}>Pesquise seus débitos</S.Title>
        </S.Row>
        <S.Row hide={!dataPaymentBillet?.debits?.length}>
          <S.WrapperValue hide={!license_plate}>
            <S.LabelValue>Placa</S.LabelValue>
            <S.Value>{license_plate}</S.Value>
          </S.WrapperValue>
          <S.WrapperValue hide={!dataPartnerSearch.renavam}>
            <S.LabelValue>Renavam</S.LabelValue>
            <S.Value>{dataPartnerSearch.renavam}</S.Value>
          </S.WrapperValue>
        </S.Row>

        {dataPaymentBillet?.debits?.map((debit) => (
          <BankslipItem
            key={debit.id_debit}
            debit={debit}
            removeBillet={() => removeDebit(debit.id_debit)}
            defaultOpen="open"
          />
        ))}

        <BoxForm hide={!dataPaymentBillet?.debits.length}>
          <Spin spinning={loadingLicensePlate}>
            <S.WrapperBoxForm>
              <S.TitleForm>Informe os dados para consultarmos.</S.TitleForm>

              <S.WrapperImage disabled={!detran?.image_src}>
                <S.ImgDetran src={detran?.image_src} />
              </S.WrapperImage>

              <S.WrapperInput>
                <Select
                  control={control}
                  name="detran"
                  error={errors?.detran?.message as string}
                  onChange={(e) => handleShowLogoDetran(e.target.value)}
                >
                  <S.Option value="" disabled selected>
                    Detran
                  </S.Option>
                  {detrans?.data
                    .filter((detran) => detran.integrated)
                    .map((detran, index) => {
                      return (
                        <S.Option
                          key={index}
                          value={detran.state.toUpperCase()}
                        >
                          {`Detran ${detran.state}`}
                        </S.Option>
                      );
                    })}
                </Select>
              </S.WrapperInput>

              {detran?.fields?.map((field) => (
                <S.WrapperInput key={field.input_key}>
                  <Input
                    type="text"
                    type_input="normal"
                    placeholder={field.label}
                    name={field.input_key.toLocaleLowerCase()}
                    control={control}
                    required={field.required}
                    error={
                      errors[field.input_key.toLocaleLowerCase()]
                        ?.message as string
                    }
                  />
                </S.WrapperInput>
              ))}
              <S.WrapperButton>
                <S.Button>
                  <Button
                    title="Pesquisar"
                    type_button="primary"
                    loading={isLoadingGetDebts}
                    disabled={isDisabled || !!Object.keys(errors).length}
                    action={handleSubmit(fetchDebtsVehicle)}
                  />
                </S.Button>
              </S.WrapperButton>
            </S.WrapperBoxForm>
          </Spin>
        </BoxForm>

        {hasAlert}

        <S.WrapperButton>
          <S.Button3>
            <Button
              title="Limpar"
              type_button="secondary"
              action={() => cleanForm()}
            />
          </S.Button3>
          <S.Button3 hide={!dataPaymentBillet?.debits?.length}>
            <Button
              title="Pesquisar novamente"
              type_button="secondary"
              loading={isLoadingGetDebts}
              action={() => fetchDebtsVehicleAgain()}
            />
          </S.Button3>
          <S.Button3>
            <Button
              title="Continuar"
              type_button="primary"
              action={() => setStage(2)}
              disabled={!dataPaymentBillet?.debits?.length}
            />
          </S.Button3>
        </S.WrapperButton>
      </S.WrapperBox>
    </S.SearchWrapper>
  );
};
