import styled, { keyframes } from "styled-components";
import { QRCode } from "antd";
import { deviceMaxWidth } from "theme/theme";

export const PageContent = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const OrderDetailsWrapper = styled.div`
  display: "flex";
  width: clamp(300px, 100%, 800px);
  height: 100%;
  flex-direction: column;
  padding: 10px;
`;

export const LinearGradientText = styled.p`
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  background: linear-gradient(90deg, #ffc706 0.43%, #adff00 99.68%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0px;
`;

export const HeaderText = styled.p`
  color: #212121;
  font-family: "Inter";
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin: 0px 0px 5px 0px;
`;

export const SubText = styled.p`
  color: #6b7280;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 0px 0px 5px 0px;
`;

export const LineDivider = styled.div`
  width: auto;
  height: 1px;
  background: #e5e7eb;
  margin: 30px 0px;
`;

export const SectionContainer = styled.div`
  display: flex;
  width: auto;
  height: auto;
  flex-direction: column;
`;

export const ContainerLabel = styled.p`
  align-self: stretch;
  color: #000;
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const AmountRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-self: stretch;

  margin: 5px 0px;
`;

export const AmountLabel = styled.p`
  color: #111827;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
`;

export const AmountValue = styled.p`
  color: #374151;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0px;
`;

export const PaymentCard = styled.div`
  display: flex;
  padding: 10px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border: 1px dashed var(--2-dark-outline, rgba(33, 33, 33, 0.12));
  border-radius: 4px;
  margin-top: 10px;
`;

export const PaymentLabel = styled.p`
  color: #4b5563;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.3px;
  margin: 0px;
`;

export const PaymentInfo = styled.p`
  color: #6b7280;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
  margin: 0px;
`;

export const PaymentCardRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const PaymentAmount = styled.p`
  color: var(--2-Dark-full, #212121);
  font-family: "Inter";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.3px;
  margin: 0px;
`;

export const VerticalBar = styled.div`
  width: 1px;
  height: 14px;
  background: #e5e7eb;
`;

export const PlateAndRenavanLabel = styled.p`
  color: #111827;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.3px;
  margin: 0px;
`;

export const PlateAndRenavanText = styled.p`
  color: #6b7280;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
  margin: 0px;
`;

export const CardCharge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
`;

export const CardChargeColum = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const CardAndClientLabel = styled.p`
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  margin-bottom: 15px;
`;

export const CardInfoRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;
export const CardOperatorImage = styled.img`
  width: 34px;
  height: 24px;
  fill: var(--White, #fff);
`;

export const CardInfoText = styled.p`
  color: #6b7280;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
`;

export const ValueCardInfoText = styled.p`
  color: var(--2-Dark-full, #212121);
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.3px;
  margin: 0px;
`;

export const ClientInfoText = styled.p`
  align-self: stretch;
  color: #6b7280;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
  margin-bottom: 5px;
`;

export const PixInfoText = styled.p`
  color: #6b7280;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`;

export const PixCharge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;

  @media ${deviceMaxWidth.tablet} {
    flex-direction: column;
  }
`;

export const PixChargeLeftColumn = styled.div`
  display: flex;
  width: auto;
  flex-direction: column;
  align-items: flex-start;
`;

export const PixChargeRightColumn = styled.div`
  display: flex;
  width: 45%;
  flex-direction: column;
  align-items: flex-start;
`;

export const PixChargeContent = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;

  @media ${deviceMaxWidth.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

export const PixImg = styled(QRCode)`
  min-width: 138px;
  min-height: 138px;
  margin-top: -11px;
  margin-left: -11px;
`;

export const PixChargeInfoContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 10px;

  @media ${deviceMaxWidth.tablet} {
    margin-left: 0;
  }
`;

export const PixChargeLabel = styled.p`
  color: #111827;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.3px;
  margin: 0px;
`;

export const BoxTitleAndIdentifier = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

export const PixChargeText = styled.p`
  color: #6b7280;
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
  margin: 0px;
`;

export const PixStatusContainer = styled.div`
  display: flex;
  padding: 7px;
  align-items: center;
  justify-content: flex-start;

  border-radius: 4px;
  border: 1px dashed #e5e7eb;
  margin-bottom: 5px;

  @media ${deviceMaxWidth.tablet} {
    justify-content: center;
  }
`;

export const QrCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
`;

export const QrCodeRow = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;

export const QrCodetext = styled.p`
  display: flex;
  color: #6b7280;
  font-family: "Inter";
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.3px;
  overflow-wrap: anywhere;
`;

export const CopyButton = styled.button`
  border: none;
  background-color: none;
  pointer-events: all;
  background: none;
  border: none;
  cursor: pointer;
`;

export const ClipboardImg = styled.img``;

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 2px solid grey;
  border-right: 2px solid grey;
  border-bottom: 2px solid grey;
  border-left: 4px solid black;
  background: transparent;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const StatusImg = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 5px;
`;
