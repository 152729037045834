import {
  BilletPublicResponseType,
  BilletResponseType,
  TypeBillet,
} from "services/types";

export function mapBillFromPublic(
  data: BilletPublicResponseType
): BilletResponseType {
  return {
    amount: {
      original: data.amount.original,
      additional: data.amount.additional,
      discount: data.amount.discount,
      total: data.amount.total,
    },
    digitable: data.digitable,
    assignor: data.assignor as string,
    due_date: data.due_date as string,
    id_bill: data.id_bill,
    isBillet: true,
    payer: {
      document: data.payer?.document as string,
      name: data.payer?.name as string,
    },
    recipient: {
      name: data.recipient?.name as string,
      document: data.recipient?.document as string,
    },
    type: data.type as TypeBillet,
  };
}
