export interface Charge {
  id_charge_option: string;
  card?: {
    number: string;
    exp_month: string;
    exp_year: string;
    security_code: string;
    holder_name: string;
    three_ds?: {
      provider: string;
      id: string;
      encrypt_card: string;
    };
  };
}
export interface CreateCheckoutRequest {
  id_client: string;
  charges: Charge[];
}

export enum CheckoutIdStatus {
  "INITIATED" = "INITIATED",
  "CREATED" = "CREATED",
  "3DS_VALIDATION_REQUESTED" = "3DS_VALIDATION_REQUESTED",
  "3DS_APPROVED" = "3DS_APPROVED",
  "3DS_REPROVED" = "3DS_REPROVED",
  "3DS_ERROR" = "3DS_ERROR",
  "RISK_ANALYSIS_STARTED" = "RISK_ANALYSIS_STARTED",
  "RISK_ANALYSIS_APPROVED" = "RISK_ANALYSIS_APPROVED",
  "RISK_ANALYSIS_REPROVED" = "RISK_ANALYSIS_REPROVED",
  "CHARGE_WAITING" = "CHARGE_WAITING",
  "CHARGE_SUCCEEDED" = "CHARGE_SUCCEEDED",
  "CHARGE_NOT_SUCCEEDED" = "CHARGE_NOT_SUCCEEDED",
  "PAYMENT_STARTED" = "PAYMENT_STARTED",
  "PAYMENT_DONE" = "PAYMENT_DONE",
  "PAYMENT_ERROR" = "PAYMENT_ERROR",
  "COMPLETED" = "COMPLETED",
  "IN_DISPUTE" = "IN_DISPUTE",
  "CHARGEBACK" = "CHARGEBACK",
  "REVERSED" = "REVERSED",
  "REMOVED" = "REMOVED",
  "PAYMENT_WAITING" = "PAYMENT_WAITING",
}

export enum EnumChargeType {
  "CREDIT_CARD" = "CREDIT_CARD",
  "DEBIT_CARD" = "DEBIT_CARD",
  "PIX" = "PIX",
  "BILL" = "BILL",
  "TRANSFER" = "TRANSFER",
}
export interface CreateCheckoutResponse {
  id_checkout: string;
  id_status: CheckoutIdStatus;
  client_id: string;
  establishment_id: string;
  type: string;
  amount: number;
  fee_amount: number;
  total_amount: number;
  created_at: string;
  charges: {
    bill_code?: string;
    id_charge: string;
    id_status: ChargeIdStatusType;
    pix_qr_code?: string;
    source: string;
    type: EnumChargeType;
    due_date?: string;
    amount_total: number;
    installment_number: number;
    installment_amount: number;
  }[];
  payments: {
    id_payment: string;
    title: string;
    description: string;
    amount: number;
    license_plate: string;
  }[];
  client: {
    name: string;
    document: string;
  };
}

export enum ChargeIdStatusType {
  "WAITING_CHARGE" = "WAITING_CHARGE",
  "CHARGE_DONE" = "CHARGE_DONE",
  "CHARGE_ERROR" = "CHARGE_ERROR",
  "CHARGE_REVERSED" = "CHARGE_REVERSED",
  "CHARGE_CANCELED" = "CHARGE_CANCELED",
  "CHARGE_DENIED" = "CHARGE_DENIED",
  "EXPIRED" = "EXPIRED",
}
