import { IButtonProps } from 'models';
import React from 'react';
import ReactLoading from 'react-loading';
import * as S from './styles';

export const Button: React.FC<IButtonProps> = ({
  title,
  action,
  icon_right,
  icon_left,
  type_button,
  align = 'center',
  paddingVertical = 0,
  paddingHorizontal = 30,
  height = 48,
  font_size = 16,
  font_weight = 500,
  loading,
  color_loading,
  type_loading,
  ...props
}) => {
  return (
    <S.Button
      onClick={!loading ? action : () => ''}
      type_button={type_button}
      height={height}
      loading={loading}
      {...props}
    >
      {loading ? (
        <ReactLoading
          type={type_loading}
          color={color_loading}
          height={30}
          width={30}
        />
      ) : (
        <S.Title
          paddingVertical={paddingVertical}
          paddingHorizontal={paddingHorizontal}
          type_button={type_button}
          disabled={props.disabled ? true : false}
          font_size={font_size}
          font_weight={font_weight}
          align={align}
          height={height}
        >
          {icon_left && <S.IconLeft src={icon_left} />}
          {title}
          {icon_right && <S.IconRight src={icon_right} />}
        </S.Title>
      )}
    </S.Button>
  );
};
