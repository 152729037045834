import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  padding: 0px 20px;
  box-sizing: border-box;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const WrapperInput = styled.div`
  width: 250px;
  margin: 26px 0;
`;

export const Title = styled.p`
  min-width: 300px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  margin: 0;

  color: #18191f !important;

  @media screen and (max-width: 400px) {
    font-size: 36px;
  }
`;

export const Title2 = styled.span`
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 48px;
  margin: 0;

  font-feature-settings: "salt" on, "liga" off;

  background: linear-gradient(153.22deg, #ffc706 0%, #adff00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media screen and (max-width: 400px) {
    font-size: 36px;
  }
`;

export const SubTitle = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: rgba(49, 47, 48, 0.8);
  margin: 0;
  margin-top: 20px;
  min-width: 300px;
  max-width: 566px;
`;

export const Width33 = styled.div`
  width: 33%;
  @media screen and (max-width: 1023px) {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Width33Right = styled.div`
  width: 33%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @media screen and (max-width: 1023px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
`;

export const Img = styled.img``;

export const WrapperMap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
  }
`;

export const Margin100 = styled.div`
  margin-bottom: 100px;
`;

export const ImgMap = styled.img`
  width: 100%;
  object-fit: contain;
  @media screen and (max-width: 400px) {
    width: 300px;
    height: 300px;
  }
`;

export const ViewPrefecturesMap = styled.div`
  width: 100%;
  position: absolute;
`;

export const TitlePrefecturesMap = styled.h1`
  font-size: 120px;
  font-weight: 900;
  background: linear-gradient(90deg, #0085ff 0%, #7315eb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: absolute;
  top: 16rem;
  left: 7rem;
`;
export const OverSubTitlePrefecturesMap = styled.h2`
  font-size: 45px;
  font-weight: 900;
  background: linear-gradient(90deg, #0085ff 0%, #7315eb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: absolute;
  top: 10rem;
  left: 9rem;
`;

export const UnderSubTitlePrefecturesMap = styled.h2`
  font-size: 40px;
  font-weight: 900;
  background: linear-gradient(90deg, #0085ff 0%, #7315eb 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  position: absolute;
  top: 24rem;
  left: 14rem;
`;

export const WrapperInfosDetran = styled.div`
  margin-bottom: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const IconDetran = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50px;
  background: #d9d9d9;
`;

export const TextDetran = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  margin: 0;
  margin-left: 15px;
`;

export const ImgDetran = styled.img`
  width: 3rem;
  height: 3rem;
`;
