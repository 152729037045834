import { Input, BrazilMap } from "components";
import React, { useEffect, useState } from "react";
import TextMap from "assets/images/icons/text-map.svg";
import Search from "assets/images/icons/search.svg";
import MapTextBlur from "assets/images/icons/map-text-blur.svg";
import * as S from "./styles";
import { api } from "services/api";
import { states } from "utils/home";
import { IDetranMap, IPrefectures } from "./types";
import { useForm } from "react-hook-form";
import { useGetDetrans } from "views/Partner/providers/get-detrans.provider";
import { id_establishment_default } from "config/establishment";

export const PartnerSection: React.FC = () => {
  const [prefectures, setPrefectures] = useState<IPrefectures>(
    {} as IPrefectures
  );
  const [data, setData] = useState<IDetranMap[]>([]);
  const [search, setSearch] = useState<string>("");

  const { data: detrans } = useGetDetrans({
    id_establishment: id_establishment_default,
  });

  const { control } = useForm({
    mode: "all",
  });

  useEffect(() => {
    getPrefectures(search);
  }, [search]);

  useEffect(() => {
    if (detrans?.data.length) {
      const _data: IDetranMap[] = [];

      detrans?.data.map((detran) => {
        states.map((state) => {
          if (detran.state === state.state) {
            state.logo_url = detran.image_src;
            _data.push(state);
          }
        });
      });

      setData(_data);
    }
  }, [detrans]);

  const getPrefectures = async (search: string) => {
    try {
      const { data } = await api.get(
        `/partner/governaments/prefectures?search=${search}`
      );
      setPrefectures({
        prefectures: data.prefeituras,
        total: data.total,
      });
    } catch (error) {
      setPrefectures({} as IPrefectures);
      console.error(error);
    }
  };

  return (
    <S.Container>
      <S.Title>
        <S.Title2>Detrans</S.Title2> credenciados
      </S.Title>
      <S.SubTitle>
        Temos credenciamento com os principais Detrans brasileiros e estamos
        trabalhando para esse número crescer ainda mais.
      </S.SubTitle>

      <S.WrapperMap>
        <S.Width33Right>
          <S.Img src={TextMap} />
        </S.Width33Right>
        <S.Width33>
          <BrazilMap data={data} />
        </S.Width33>
        <S.Width33 style={{ marginTop: 45 }}>
          {data.slice(0, 6).map((detran, index) => {
            return (
              <S.WrapperInfosDetran key={index}>
                {detran?.logo_url ? (
                  <S.ImgDetran src={detran.logo_url} />
                ) : (
                  <S.IconDetran />
                )}
                <S.TextDetran>Detran {detran.state}</S.TextDetran>
              </S.WrapperInfosDetran>
            );
          })}
        </S.Width33>
      </S.WrapperMap>

      <S.Margin100 />

      <S.Title>
        <S.Title2>Prefeituras</S.Title2> credenciadas
      </S.Title>
      <S.SubTitle>
        São {prefectures.total} prefeituras integradas com nossos sistemas.
        Pesquise a sua!
      </S.SubTitle>
      <S.WrapperInput>
        <Input
          control={control}
          type="text"
          type_input="normal"
          placeholder="Encontre sua prefeitura"
          name="search"
          onChange={(e: any) => {
            setSearch(e.target.value);
          }}
          icon_right={Search}
        />
      </S.WrapperInput>

      <S.WrapperMap>
        <S.Width33Right></S.Width33Right>
        <S.Width33>
          <S.ImgMap src={MapTextBlur} />
        </S.Width33>
        <S.Width33 style={{ marginTop: 45 }}>
          {prefectures.prefectures?.slice(0, 6).map((prefecture, index) => {
            return (
              <S.WrapperInfosDetran key={index}>
                {prefecture?.favicon_url ? (
                  <S.ImgDetran src={prefecture.favicon_url} />
                ) : (
                  <S.IconDetran />
                )}
                <S.TextDetran>{prefecture.nome}</S.TextDetran>
              </S.WrapperInfosDetran>
            );
          })}
        </S.Width33>
      </S.WrapperMap>
    </S.Container>
  );
};
