import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  p {
    text-align: center;
    width: clamp(250px, 90vw, 670px);
    font-family: Inter;
    font-weight: 300; // text remove bold
  }
`;

export const WrapperFlags = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  svg {
    width: clamp(40px, 11vw, 60px);
    height: clamp(40px, 11vw, 60px);
    object-fit: contain;
  }
`;
