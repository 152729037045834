import tinycolor from "tinycolor2";

export function getContrastRatio(color1: string, color2: string) {
  const luminance1 = tinycolor(color1).getLuminance();
  const luminance2 = tinycolor(color2).getLuminance();

  const lighter = Math.max(luminance1, luminance2);
  const darker = Math.min(luminance1, luminance2);

  return (lighter + 0.05) / (darker + 0.05);  
}