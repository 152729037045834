import React, { useEffect, useState } from "react";
import * as S from "./styles";
import Order1 from "assets/images/icons/order-1.svg";
import Order2 from "assets/images/icons/order-2.svg";
import Order3 from "assets/images/icons/order-3.svg";
import { useParams } from "react-router-dom";
import {
  NavBar,
  Button,
  Loading,
  PaymentForm,
  Summary,
  UserInformationForm,
} from "components";
import { SdkPt } from "models";
import GetDataBank from "./Register";
import { useWhitelabel } from "hooks/useWhitelabel";
import { useGetWhiteLabel } from "services/ParcelamosService";
import { EnumStages } from "./types";
import usePayment from "hooks/usePayment";
import {
  EnumOriginPayment,
  EnumTypeDebit,
  MappedDebitsToGetInstallments,
} from "services/CreditCard";
import { toast } from "react-toastify";

export const TransferBank = () => {
  const { id_usuario } = useParams();
  const { client } = useWhitelabel();
  const { isLoading } = useGetWhiteLabel(
    id_usuario ?? (client?.id_usuario as string)
  );
  const [sdkPT, setSdkPT] = useState<SdkPt>({} as SdkPt);
  const [stage, setStage] = useState<EnumStages>(EnumStages.SEARCH);
  const [buttonDisabled, setButtonDisabled] = useState<boolean>(false);
  const { paymentForm, setPaymentForm, dataTransferBank, getInstallments } =
    usePayment();

  const getIconStage = (stage: EnumStages) =>
    [Order1, Order2, Order3][stage - 1];

  const goBack = () => {
    setStage(stage - 1);
  };

  function handleChangeStage(nextStage: EnumStages) {
    if (nextStage === EnumStages.PAYMENT_FORM) {
      const debits: MappedDebitsToGetInstallments = [
        {
          type: EnumTypeDebit.TRANSFER,
          id_transfer: dataTransferBank.id_transfer,
        },
      ];

      const request = {
        idEstablishment: id_usuario,
        data: { origin: EnumOriginPayment.ONLINE, debits },
      };

      getInstallments(request, {
        onError(error: any) {
          toast.error(error?.response?.data?.message);
        },
      }).then(() => {
        setStage(nextStage);
      });
      return;
    }

    setStage(nextStage);
  }

  useEffect(() => {
    if (stage) {
      handleChangeStage(
        stage !== EnumStages.SEARCH ? stage : EnumStages.SEARCH
      );
    }
  }, [stage]);

  return (
    <Loading isLoading={isLoading} size="large">
      <NavBar />
      <S.Wrapper>
        <S.Button>
          {stage === EnumStages.SEARCH || stage === EnumStages.ORDER_DETAILS ? (
            <></>
          ) : (
            <Button
              title="Voltar para etapa"
              type_button="outlined"
              icon_right={getIconStage(stage)}
              onClick={goBack}
              disabled={buttonDisabled}
            />
          )}
        </S.Button>
      </S.Wrapper>

      <S.WrapperStages>
        <GetDataBank setStage={setStage} enable={stage === EnumStages.SEARCH} />
        <PaymentForm
          setStage={setStage}
          paymentForm={paymentForm}
          setPaymentForm={setPaymentForm}
          showSummary={false}
          enable={stage === EnumStages.PAYMENT_FORM}
          setSdkPT={setSdkPT}
        />
        <UserInformationForm
          enable={stage === EnumStages.CLIENT_IDENTIFICATION}
          setStage={handleChangeStage}
          stage={stage}
        />
        <Summary
          route="transferencia"
          paymentForm={paymentForm}
          enable={stage === EnumStages.SUMMARY}
          stage={stage}
          goBack={goBack}
          sdkPT={sdkPT}
          setButtonDisabled={setButtonDisabled}
          buttonDisabled={buttonDisabled}
        />
      </S.WrapperStages>
    </Loading>
  );
};
