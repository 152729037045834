import React, { ReactNode } from "react";
import { Spin } from "antd";
import { Wrapper } from "./styles";

interface LoadingProps {
  size?: "small" | "default" | "large"; // optional size prop
  backgroundNone?: boolean;
  isLoading?: boolean;
  children: ReactNode;
}

export const Loading: React.FC<LoadingProps> = ({
  size = "default",
  isLoading = false,
  backgroundNone = false,
  children,
}) => {
  return (
    <Spin size={size} spinning={isLoading}>
      {backgroundNone && isLoading ? <Wrapper></Wrapper> : children}
    </Spin>
  );
};
