/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useMemo, useState } from "react";

import { sum } from "utils/operator";
import { formatCurrency } from "utils/mask";
import { EnumStages } from "views/Billet/types";
import { EnumPaymentForm } from "services/types";
import { IStagesBilletPaymentForm } from "models";
import usePayment from "hooks/usePayment";
import { useWhitelabel } from "hooks/useWhitelabel";
import { Button, CreditCard, RadioButton } from "components";

import InfoFrameImage from "assets/images/icons/blue-circle-info.svg";
import Order3 from "assets/images/icons/order-3.svg";
import * as S from "./styles";

export const PaymentForm: React.FC<IStagesBilletPaymentForm> = ({
  setStage,
  paymentForm,
  setPaymentForm,
  enable,
  showSummary = true,
}) => {
  const {
    dataPaymentBillet,
    setDisableEditValueInput,
    isEnableCheckout,
    paymentCard,
    installments,
  } = usePayment();

  const [sumBillets, setSumBillets] = useState(0);
  const [enableFlowPix, setEnableFlowPix] = useState(true);

  const { client } = useWhitelabel();

  const { is_credit_disabled, is_pix_disabled } = useMemo(
    () => {

      if (!client) {
        return {
          is_credit_disabled: false,
          is_pix_disabled: false,
        };
      }

      return {
        is_credit_disabled: !client?.credit_card_enable,
        is_pix_disabled: !client?.pix_enable,
      }
    },
    [client]
  );

  useEffect(() => {

    if (!client) {
      return;
    }

    if (client?.pix_enable && enableFlowPix) {
      setPaymentForm(EnumPaymentForm.PIX);
    }
    if (client?.credit_card_enable && enableFlowPix) {
      setPaymentForm(EnumPaymentForm.CREDIT);
    }
  }, [client, paymentForm]);

  useEffect(() => {
    const amountBillets = dataPaymentBillet?.billets?.map(
      (billet) => billet.amount.total
    );
    const amountDebits = dataPaymentBillet?.debits?.map(
      (debit) => debit.amount
    );
    const amountPix = dataPaymentBillet?.pix?.map((pix) => pix.amount.total);

    setSumBillets(sum([...amountBillets, ...amountDebits, ...amountPix]));
  }, [dataPaymentBillet, paymentCard]);

  useEffect(() => {
    setDisableEditValueInput(false);
  }, []);

  return (
    <S.PaymentWrapper enable={enable}>
      <S.WrapperBox>
        <S.TitleRow>
          <S.OrderImg src={Order3} />
          <S.Title>Pagamento</S.Title>
        </S.TitleRow>

        <S.WrapperTypePayment>
          <S.WrapperRadio disabled={is_credit_disabled}>
            <RadioButton
              text="Cartão de crédito"
              status={paymentForm === EnumPaymentForm.CREDIT}
              setStatus={() => setPaymentForm(EnumPaymentForm.CREDIT)}
            />
          </S.WrapperRadio>

          <S.WrapperRadio disabled={is_pix_disabled}>
            <RadioButton
              text="Pix"
              status={paymentForm === EnumPaymentForm.PIX}
              setStatus={() => {
                setPaymentForm(EnumPaymentForm.PIX);
                setEnableFlowPix(false);
              }}
            />
          </S.WrapperRadio>
        </S.WrapperTypePayment>

        <S.Row>
          <S.WrapperValue value={false}>
            <S.LabelValue>Total a pagar dos débitos</S.LabelValue>
            <S.Value value={false}>
              {paymentForm === EnumPaymentForm.CREDIT
                ? formatCurrency(paymentCard?.installment?.total_amount)
                : formatCurrency(installments?.pix?.total_amount)}
            </S.Value>
          </S.WrapperValue>
        </S.Row>

        {is_credit_disabled && is_pix_disabled ? (
          <S.PixInformationContainer enable>
            <S.InfoHeaderRow>
              <S.InfoImg src={InfoFrameImage} />

              <S.InfoHeader>Informação</S.InfoHeader>
            </S.InfoHeaderRow>
            <S.InfoTextRow>
              <S.InfoText>
                O estabelecimento não está habilitado para receber pagamentos.
              </S.InfoText>
            </S.InfoTextRow>
          </S.PixInformationContainer>
        ) : (
          <>
            <CreditCard
              amount={sumBillets}
              paymentForm={paymentForm}
              showSummary={showSummary}
              enable={paymentForm === EnumPaymentForm.CREDIT}
            />

            <S.PixInformationContainer
              enable={paymentForm === EnumPaymentForm.PIX}
            >
              <S.InfoHeaderRow>
                <S.InfoImg src={InfoFrameImage} />

                <S.InfoHeader>Informação</S.InfoHeader>
              </S.InfoHeaderRow>
              <S.InfoTextRow>
                <S.InfoText>
                  Vamos gerar o QRCode assim que o pagamento for confirmado na
                  próxima etapa.
                </S.InfoText>
              </S.InfoTextRow>
            </S.PixInformationContainer>

            <S.WrapperButton>
              <S.ButtonPayNow>
                <Button
                  title="Ir para pagamento"
                  type_button="primary"
                  paddingHorizontal={0}
                  action={() => setStage(EnumStages.SUMMARY)}
                  disabled={
                    !isEnableCheckout && paymentForm === EnumPaymentForm.CREDIT
                  }
                />
              </S.ButtonPayNow>
            </S.WrapperButton>
          </>
        )}
      </S.WrapperBox>
    </S.PaymentWrapper>
  );
};
