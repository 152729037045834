import React from "react";
import * as S from "./styles";
import BarCode from "assets/images/icons/barcode-white.svg";
import PayCar from "assets/images/icons/pay-car-white.svg";
import TaxWhite from "assets/images/icons/tax-white.svg";
// import CreditCardWhite from "assets/images/icons/credit-card-white.svg";
import RechargePhone from "assets/images/icons/recharge-phone-white.svg";
import EnergyWhite from "assets/images/icons/energy-white.svg";
import { BoxInstallment } from "components";

export const HowWeHelpYouSection: React.FC = () => {
  const data = [
    {
      title: "Boletos parcelados.",
      sub_title:
        "Você parcela seu boleto* em até 12 vezes com as melhores taxas disponíveis!",
      icon: BarCode,
    },
    {
      title: "Débitos de veículos parcelado.",
      sub_title:
        "Seja IPVA, licenciamento, multas ou qualquer outro tipo de pagamento para o Detran do seu estado.",
      icon: PayCar,
    },
    {
      title: "Débitos em prefeituras parcelado.",
      sub_title:
        "Taxas de IPTU, ISS, ICMS, Alvará e todo tipo de débito que sua prefeitura emita.",
      icon: TaxWhite,
    },
    // {
    //   title: "Uma conta bancária",
    //   sub_title:
    //     "É isso mesmo! Você tem PIX, cartão, e diversos serviços integrados num super app.",
    //   icon: CreditCardWhite,
    // },
    {
      title: "Recarga de celular",
      sub_title:
        "Recarregue celulares de diversas operadoras de forma simples, segura e rápida!",
      icon: RechargePhone,
    },
    {
      title: "Energia parcelada.",
      sub_title:
        "Aquela fatura da conta de energia veio muito alta? Resolvemos pra você!",
      icon: EnergyWhite,
    },
  ];
  return (
    <S.Container id="help">
      <S.Title>Como te ajudamos? </S.Title>

      <S.Wrapper>
        <S.Column>
          {data.map((item, index) => {
            return (
              <S.Card key={index}>
                <S.Img src={item.icon} />
                <S.WrapperTextsCards>
                  <S.TitleCard>{item.title}</S.TitleCard>
                  <S.SubTitleCard>{item.sub_title}</S.SubTitleCard>
                </S.WrapperTextsCards>
              </S.Card>
            );
          })}
          <S.ParagraphMinor>*sujeito a regulamentação do banco central.</S.ParagraphMinor>
        </S.Column>
        <S.Column2>
          <BoxInstallment removeMargin={true} />
        </S.Column2>
      </S.Wrapper>
    </S.Container>
  );
};
