import styled from "styled-components";

export const Wrapper = styled.div<{ disabled?: boolean }>`
  display: ${(props) => (props.disabled ? "none" : "flex")};
  flex-direction: column;
  text-align: left;
  opacity: 0.6;
`;

export const SimulatorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

export const FooterWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;

  display: ${(props) => (props.disabled ? "none" : "flex")};
`;

export const Button = styled.div`
  width: fit-content;

  padding: 0px 20px;
`;

export const SubTitle = styled.h2`
  font-size: 18px;
  font-weight: 700;
  color: #312f30;
  margin-bottom: 20px;
  text-align: center;
  opacity: 0.8;
`;

export const WrapperBoxBorderGradient = styled.div<{ disabled?: boolean }>`
  width: 380px;
  min-width: 300px;
  margin-top: 50px;

  display: ${(props) => (props.disabled ? "none" : "flex")};
`;

export const Title = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  color: #212121;
  margin: 0;
  margin-bottom: 25px;
`;

export const WrapperInput = styled.div`
  width: 100%;
  margin-bottom: 25px;
`;

export const Buttons = styled.div`
  width: 100%;
`;

export const Option = styled.option``;

export const ContainerGridButtons = styled.div<{ disabled?: boolean }>`
  display: ${(props) => (props.disabled ? "none" : "flex")};
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
`;

export const ContainerButton = styled.div`
  min-width: 48%;
`;

export const InstallmentButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 4px solid transparent;
  border-radius: 8px;
  padding: 6px 2px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background: linear-gradient(white, white) padding-box,
      linear-gradient(153.22deg, #ffc706 0%, #adff00 100%) border-box;
  }
`;

export const ContainerInsideButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0 8px;
`;

export const SubTextButton = styled.p`
  font-size: 12px;
  opacity: 0.6;
  margin: 0;
  padding: 0;
`;

export const TextButtonBold = styled.p`
  font-size: 18px;
  font-weight: 700;
  line-height: 36px;
  margin: 0;
  padding: 0;
`;

export const TextButton = styled.p`
  font-size: 18px;
  margin: 0;
  padding: 0;
`;
