import React from "react";
import { NavBar } from "components";
import PolicyOfPrivate from "components/PolicyOfPrivate";
import { Container } from "./styles";

const PolicyOfPrivatePage: React.FC = () => {
  return (
    <div>
      <NavBar />

      <Container>
        <PolicyOfPrivate />
      </Container>
    </div>
  );
};

export default PolicyOfPrivatePage;
