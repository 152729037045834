import { ISelectProps, TTypeInput } from "models";
import InputMask from "react-input-mask";
import ArrowDown from "assets/images/icons/arrow-down-select.svg";
import styled, { css } from "styled-components";

export const WrapperSelect = styled.div`
  width: 100%;
  position: relative;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.p`
  color: #5e718d;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin: 0;
  margin-bottom: 5px;
`;

export const Select = styled.select<ISelectProps>`
  width: ${({ width }) => (width ? width + "px" : "100%")};
  height: 46px;
  border: 1px solid #afbaca;
  border-radius: 6px;
  box-sizing: border-box;
  padding: 0px 14px;
  padding-right: 44px;
  outline: none;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.2px;
  color: #8897ae;

  background: url(${ArrowDown}) no-repeat right #fff;
  background-position: calc(100% - 15px) center !important;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;

  &:disabled {
    border: 1px solid #d7dfe9;
    color: #afbaca;
    background: #fff;
    opacity: 1;

    ::placeholder {
      color: #afbaca;
      opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #afbaca;
    }

    ::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #afbaca;
    }
  }

  ${({ value }) =>
    value &&
    css`
      color: #5e718d;
      border: 1px solid #5e718d;
    `};

  ${({ type_select }) =>
    type_select === "select_widget" &&
    css`
      background-color: #e0e0e0;
      color: rgba(33, 33, 33, 0.35);
      border: 0px;
    `};

  ${({ type_select }) =>
    type_select === "error" &&
    css`
      background-color: #fff5f4!important;
      border: 1px solid #ffa19b;
      border-radius: 6px;
      color: #e92215;
      caret-color: #e92215;

      &:focus-visible {
        border: 1px solid #ffa19b;
      }

      ::placeholder {
        color: #e92215;
        opacity: 1; /* Firefox */
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #e92215;
      }

      ::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #e92215;
      }

      &:disabled {
        border: 1px solid #ffa19b;
        color: #e92215;

        ::placeholder {
          color: #e92215;
          opacity: 1; /* Firefox */
        }

        :-ms-input-placeholder {
          /* Internet Explorer 10-11 */
          color: #e92215;
        }

        ::-ms-input-placeholder {
          /* Microsoft Edge */
          color: #e92215;
        }
      }
    `};

  ${({ border_radius_left }) =>
    !border_radius_left &&
    css`
      border-radius: 0px 6px 6px 0px !important;
      border-left-width: 0 !important;
    `};

  ${({ border_radius_right }) =>
    !border_radius_right &&
    css`
      border-radius: 6px 0px 0px 6px !important;
      border-right-width: 0 !important;
    `};
`;

export const Option = styled.option``;

export const ErrorShowComplete = styled.p<{ show?: boolean }>`
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #e92215;
  margin: 0;
  position: absolute;
  visibility: hidden;
  z-index: 1000;

  ${({ show }) =>
    show &&
    css`
      visibility: visible;
      /* top: 10px; */
      background-color: #fff;
      color: #e92215;
    `};
`;

export const TextError = styled.p`
  width: 100%;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #e92215;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  position: absolute;
`;
