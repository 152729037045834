import axios from "axios";
import reCaptcha from "./RecaptchaService";

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_PARCELAMOS_API_URL}/public`,
  headers: {
    "Api-version": 1,
    "Content-Type": "application/json",
    accept: "application/json",
  },
});

api.interceptors.request.use(async (config) => {
  const postRoutesCreateCheckout = [
    '/checkout',
    '/charge/order/3ds',
  ]

  const route3dsConfirmEnds = '/3ds/confirm'

  const routeIsPaymentProgress = postRoutesCreateCheckout.includes(config.url ?? '') || config.url?.endsWith(route3dsConfirmEnds)

  if (config.method === 'post' && routeIsPaymentProgress) {
    return config
  }

  const token = await reCaptcha.getRecaptchaToken();

  config.headers.set('x-recaptcha-token', token);

  return config
})

export const server = axios.create({
  baseURL: `${process.env.REACT_APP_PARCELAMOS_API_URL}`,
  headers: {
    "Api-version": 1,
    "Content-Type": "application/json",
    accept: "application/json",
    Authorization:
      "Bearer " +
      process.env.REACT_APP_PARCELAMOS_API_CLIENT_ID +
      "|" +
      process.env.REACT_APP_PARCELAMOS_API_CLIENT_SECRET,
  },
});
