export enum EnumCheckoutStatus {
  INITIATED = "INITIATED",
  CREATED = "CREATED",
  THREEDS_VALIDATION_REQUESTED = "3DS_VALIDATION_REQUESTED",
  THREEDS_APPROVED = "3DS_APPROVED",
  THREEDS_REPROVED = "3DS_REPROVED",
  THREEDS_ERROR = "3DS_ERROR",
  RISK_ANALYSIS_STARTED = "RISK_ANALYSIS_STARTED",
  RISK_ANALYSIS_APPROVED = "RISK_ANALYSIS_APPROVED",
  RISK_ANALYSIS_REPROVED = "RISK_ANALYSIS_REPROVED",
  CHARGE_WAITING = "CHARGE_WAITING",
  CHARGE_SUCCEEDED = "CHARGE_SUCCEEDED",
  CHARGE_NOT_SUCCEEDED = "CHARGE_NOT_SUCCEEDED",
  PAYMENT_WAITING = "PAYMENT_WAITING",
  PAYMENT_STARTED = "PAYMENT_STARTED",
  PAYMENT_DONE = "PAYMENT_DONE",
  PAYMENT_ERROR = "PAYMENT_ERROR",
  COMPLETED = "COMPLETED",
  IN_DISPUTE = "IN_DISPUTE",
  CHARGEBACK = "CHARGEBACK",
  REVERSED = "REVERSED",
  REMOVED = "REMOVED"
}

export type CreateClientRequest = {
  name: string;
  document: string;
  email: string;
  phone: string;
  address: {
    address: string;
    number: string;
    neighborhood: string;
    zip_code: string;
    city: string;
    state: string;
    complement?: string;
  };
};
export type CreateClientResponse = {
  id_client: string;
  name: string;
  document: string;
  email: string;
  phone: string;
  address: {
    address: string;
    city: string;
    complement: null;
    neighborhood: string;
    number: string;
    state: string;
    zip_code: string;
  };
  created_at: string;
};

export type CreateCheckoutRequest = {
  id_client: string;
  charges: {
    id_charge_option: string;
    card?: {
      number: string;
      exp_month: string;
      exp_year: string;
      security_code: string;
      holder_name: string;
      three_ds?: {
        provider: string;
        id: string;
        encrypt_card: string;
      };
    };
  }[];
};
export type CreateCheckoutResponse = {
  error?: EnumCheckoutErrorResponse;
  id_checkout: string;
  id_status: EnumCheckoutStatus;
  client_id: string;
  establishment_id: string;
  type: string;
  amount: number;
  fee_amount: number;
  total_amount: number;
  created_at: string;
  charges: {
    bill_code: string;
    pix_qr_code: string;
    due_date: string;
  }[];

  // 3ds challenge
  challenge_url?: string
  credential_request?: string  
};

export enum EnumCheckoutErrorResponse {
  CHARGE_OPTIONS_EXPIRED = "checkout.error.charge-options.expired",
}

export type CreateCheckoutOrder3dsResponse = {
  id_three_ds: string
  validation_method_token?: string;
  validation_method_url?: string;
}
type GetBrowserDataRes = {
  browser_user_agent: string;
  browser_java_enabled: string;
  browser_javascript_enabled: string;
  browser_language: string;
  browser_color_depth: string;
  browser_screen_height: string;
  browser_screen_width: string;
  browser_tz: string;
}
export type CreateCheckout = {
  token: string;
  id_establishment: string;
  browserInfoHeaders?: GetBrowserDataRes,
  payload: CreateCheckoutOrderRequest
}

export type CreateCheckout3dsConfirm = CreateCheckout & {
  id_checkout: string;
}

export type CreateCheckout3DS = Omit<CreateCheckout, 'browserInfoHeaders'>

export type CreateCheckoutOrderRequest = {
  id_client: string;
  charges: CheckoutOrderRequest[]
}

export type CheckoutOrderRequest = {
  id_charge_option: string;
  card?: {
    number: string
    exp_month: string
    exp_year: string
    security_code: string
    holder_name: string
    id_three_ds?: string
    three_ds?: ThreeDs
  }
}
export interface ThreeDs {
  provider: string
  id: string
  encrypt_card?: string
  validation_time_to_complete?: number
}
