import React, { createContext, ReactNode, useEffect, useState } from "react";

import {
  BilletResponseType,
  Card,
  PartnerSearch,
  EnumPaymentForm as EnumPaymentType,
  HandleError,
  Debits,
  EnumState,
  Pix,
} from "../services/types";
import { toast } from "react-toastify";
import { IInstallmentItem, IInstallments, SchemaCreditCard } from "models";
import {
  getFromLocalStorage,
  getFromLocalStorageParse,
  saveInLocalStorage,
} from "utils/localStorage";
import { UseMutateAsyncFunction, useMutation } from "react-query";
import { getInstallments, GetInstallmentsRequest } from "services/CreditCard";
import { defineMaxInstallments } from "utils/installments";
import { sum } from "utils/operator";
import { truncTwoDecimals } from "utils/billet";
import { FieldValues, useForm, UseFormReturn } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { IDataBank, IDataUser } from "models/TransferBank";
import { TransferResponse } from "views/Transfer/providers/dto/create-transfer-bank.dto";
import { DetranItem } from "views/Partner/providers/dto/get-detrans.dto";
import { CreateCheckoutResponse } from "views/Billet/providers/dto/create-checkout.dto";
import { CreateOrUpdateAccountResponse } from "views/Billet/providers/dto/create-or-update-account.dto";

export type IdentificationType = {
  name: string;
  document: string;
  phone: string;
  payment_identifier?: string;
  payment_cart_number?: string;
};

export type PaymentInstallments = {
  installments_number: number;
  installments_value: string;
  amount: string;
};

export enum EnumPaymentForm {
  CREDIT = 1,
  DEBIT = 2,
}

export type PaymentBilletType = IdentificationType &
  PaymentInstallments & {
    billets: BilletResponseType[];
    debits: Debits[];
    pix: Pix[];
  };

export type PaymentOptionsRefs = {
  handleOpen: () => void;
};

export type IDentran = {
  id_detran: string;
  logo_url: string;
  state: EnumState;
};

export type TaxsSearchDebitCar = {
  fee_tax_amount: number;
  cet: number;
  sum_total_payment: number;
};

interface ModalContextData {
  setPaymentBilletData: (
    data: PaymentBilletType,
    partnerSearch?: PartnerSearch
  ) => void;
  getInstallments: UseMutateAsyncFunction<
    IInstallments & HandleError,
    unknown,
    GetInstallmentsRequest,
    unknown
  >;
  updateInstallments: (data: IInstallments) => void;
  installments: IInstallments;
  installmentsToUse: IInstallmentItem[];
  setPaymentCard: (data: Card) => void;
  processPaymentBilletData: (
    data: PaymentBilletType,
    type?: EnumPaymentForm
  ) => void;
  clearPaymentBilletData: () => void;
  dataPaymentBillet: PaymentBilletType;
  paymentCard: Card;
  paymentBilletResponse: undefined;
  loadingPayment: boolean;
  setRatePix: (rate: number) => void;
  ratePix: number;
  QTDE_PARCELAS_PIX: number;
  disableEditValueInput: boolean;
  setDisableEditValueInput: (value: boolean) => void;
  MIN_INSTALLMENT: number;
  setEnableCheckout: (value: boolean) => void;
  dataPartnerSearch: PartnerSearch;
  isEnableCheckout: boolean;
  detran: DetranItem;
  setDetran: (data: DetranItem) => void;
  clearDetran: () => void;  
  setUserId: (value: string) => void;
  userId: string;
  setRenavam: (value: string) => void;
  renavam: string;
  removeBillet: (index: number) => void;
  removeDebit: (id_debit: string) => void;
  MAX_INSTALLMENT_NUMBER: number;
  setDataPartnerSearch: (data: PartnerSearch) => void;
  maxInstallmentNumber: number;
  updateMaxInstallmentNumber: (billets: BilletResponseType[]) => void;
  setMaxInstallmentNumber: (value: number) => void;
  setPaymentForm: (value: EnumPaymentType) => void;
  paymentForm: EnumPaymentType;
  taxSearchDebitCar: TaxsSearchDebitCar;
  formCreditCard: UseFormReturn<FieldValues, any>;
  setClient: (value: CreateOrUpdateAccountResponse) => void;
  client: CreateOrUpdateAccountResponse;
  checkoutData: CreateCheckoutResponse;
  setCheckoutData: (value: CreateCheckoutResponse) => void;
  dataBank: IDataBank;
  dataUser: IDataUser;
  setDataBank: (data: IDataBank) => void;
  setDataUser: (data: IDataUser) => void;
  dataTransferBank: TransferResponse;
  saveTransferBank: (data: TransferResponse) => void;
  rulesFromQuery: TRulesFromQuery;
  updateRulesFromQuery: (value: TRulesFromQuery) => void;
}

type TRulesFromQuery = {
  barcode?: string[];
  fixedInstallment?: number;
};

const PaymentContext = createContext<ModalContextData>({} as ModalContextData);

export const PaymentProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const AMOUNT_INSTALLMENTS_PIX = 1;
  const MIN_INSTALLMENT = 5;
  const MAX_INSTALLMENT_NUMBER = 12;
  const [paymentForm, setPaymentForm] = useState<EnumPaymentType>(
    EnumPaymentType.CREDIT
  );

  const [maxInstallmentNumber, setMaxInstallmentNumber] = useState<number>(
    MAX_INSTALLMENT_NUMBER
  );
  const [disableEditValueInput, setDisableEditValueInput] =
    useState<boolean>(false);
  const [isEnableCheckout, setEnableCheckout] = useState<boolean>(false);
  const [dataPaymentBillet, setDataPaymentBillet] = useState<PaymentBilletType>(
    {
      document: "",
      phone: "",
      name: "",
      amount: "",
      installments_number: 0,
      installments_value: "",
      billets: [],
      debits: [],
      pix: [],
    }
  );
  const [userId, setUserId] = useState<string>("");
  const [detran, setDetran] = useState<DetranItem>({} as DetranItem);
  const [taxSearchDebitCar, setTaxSearchDebitCar] =
    useState<TaxsSearchDebitCar>({} as TaxsSearchDebitCar);
  const [renavam, setRenavam] = useState<string>("");
  const [dataPartnerSearch, setDataPartnerSearch] = useState<PartnerSearch>(
    {} as PartnerSearch
  );
  const [paymentCard, setPaymentCard] = useState<Card>({} as Card);
  const [rulesFromQuery, setRulesFromQuery] = useState<TRulesFromQuery>({});
  const [paymentBilletResponse, setPaymentBilletResponse] =
    useState<undefined>();
  const [installments, setInstallments] = useState<IInstallments>({
    credit_card: [] as IInstallmentItem[],
  } as IInstallments);
  const [installmentsToUse, setInstallmentsToUse] = useState<
    IInstallmentItem[]
  >([]);
  const [ratePix, setRatePix] = useState<number>(0);
  const [loadingPayment, setLoadingPayment] = useState<boolean>(false);
  const [client, setClient] = useState<CreateOrUpdateAccountResponse>(
    {} as CreateOrUpdateAccountResponse
  );

  const [checkoutData, setCheckoutData] = useState<CreateCheckoutResponse>(
    {} as CreateCheckoutResponse
  );

  const [dataBank, setDataBank] = useState<IDataBank>({} as IDataBank);
  const [dataUser, setDataUser] = useState<IDataUser>({} as IDataUser);
  const [dataTransferBank, setDataTransferBank] = useState<TransferResponse>(
    {} as TransferResponse
  );

  const formCreditCard = useForm({
    resolver: yupResolver(SchemaCreditCard),
    mode: "all",
  });

  const { mutateAsync: fetchInstallments } = useMutation(
    ["get-installments", dataPaymentBillet],
    getInstallments,
    {
      onSuccess: (data) => {
        setInstallments(data);
      },
      onError: (error) => {
        console.error(error);
      },
      retry: 1,
    }
  );

  useEffect(() => {
    getDetran();
    getDataPartnerSearch();
    getRatePix();
    getTransferBank();
  }, []);

  useEffect(() => {
    if (paymentForm === EnumPaymentType.CREDIT) {
      setInstallmentsToUse(installments.credit_card);
      updateMaxInstallmentNumber();
    }

    if (paymentForm === EnumPaymentType.DEBIT) {
      setInstallmentsToUse(installments.debit_card);
    }

    if (paymentForm === EnumPaymentType.PIX) {
      setInstallmentsToUse([installments.pix]);
    }
  }, [paymentForm, installments]);

  async function getRatePix() {
    const rate = await getFromLocalStorage("@parcelamos/rate-pix");

    if (rate) {
      setRatePix(Number(rate));
    }
  }

  async function getDetran() {
    const detrans = await getFromLocalStorageParse("@parcelamos/detran");

    if (detrans) {
      setDetran(detrans);
    }
  }

  function updateDetran(data: DetranItem) {
    saveInLocalStorage("@parcelamos/detran", JSON.stringify(data));

    setDetran(data);
  }

  function clearDetran() {
    sessionStorage.removeItem("@parcelamos/detran");
  }

  function getDataPartnerSearch() {
    const data = getFromLocalStorageParse("@parcelamos/partner-search");

    if (data) {
      setDataPartnerSearch(data);
    }
  }

  async function updatePaymentCard(data: Card) {
    setPaymentCard(data);
  }

  useEffect(() => {
    if (installments.credit_card.length) {
      calculateFeeTaxAmount(dataPaymentBillet);
    }
  }, [dataPaymentBillet, paymentCard.installment, installments]);

  function updateInstallments(data: IInstallments) {
    setInstallments(data);
  }

  function calculateFeeTaxAmount(payment: PaymentBilletType) {
    const amountBillets = payment?.billets?.map(
      (billet) => billet.amount.total
    );
    const amountDebits = payment?.debits?.map((debit) => debit.amount);
    const originalAmount = sum([...amountBillets, ...amountDebits]);

    let installment_selected: IInstallmentItem = paymentCard.installment;

    if (!installment_selected) {
      installment_selected = defineMaxInstallments(installments.credit_card);

      setPaymentCard({
        ...paymentCard,
        id_charge_option: installment_selected.id_charge_option,
        installment: installment_selected,
      });

      setPaymentForm(EnumPaymentType.CREDIT);
      setInstallmentsToUse(installments.credit_card);
      setMaxInstallmentNumber(installment_selected.installment_number);
      formCreditCard.setValue(
        "installments",
        installment_selected?.id_charge_option
      );
    }

    const sumTotalPayment = truncTwoDecimals(installment_selected.total_amount);

    const feeTax = sumTotalPayment - originalAmount;

    const feeTaxAmount = truncTwoDecimals(feeTax);

    const cet = truncTwoDecimals(
      ((sumTotalPayment - originalAmount) / originalAmount) * 100
    );

    setTaxSearchDebitCar({
      fee_tax_amount: feeTaxAmount,
      cet,
      sum_total_payment: sumTotalPayment,
    });
  }

  function handleSetDataPaymentBillet(data: PaymentBilletType) {
    setDataPaymentBillet(data);
  }

  function updateMaxInstallmentNumber() {
    const max_installments = defineMaxInstallments(installments.credit_card);

    setMaxInstallmentNumber(max_installments?.installment_number);

    setPaymentCard({
      ...paymentCard,
      id_charge_option: max_installments?.id_charge_option,
      installment: max_installments,
    });

    formCreditCard.setValue("installments", max_installments?.id_charge_option);
  }

  async function updateDataPartnerSearch(data: PartnerSearch) {
    await saveInLocalStorage(
      "@parcelamos/partner-search",
      JSON.stringify(data)
    );
    setDataPartnerSearch(data);
  }

  function processPaymentBillet(
    data: PaymentBilletType,
    type: EnumPaymentForm = EnumPaymentForm.CREDIT
  ) {
    if (
      !data.name &&
      !data.phone &&
      !data.document &&
      !data.installments_value &&
      data.installments_number < 0 &&
      !data.amount &&
      !data.payment_identifier
    ) {
      return toast.error("É necessário informar todos os dados para pagamento");
    }
    setPaymentBilletResponse(undefined);
    setLoadingPayment(true);
  }

  function clearPaymentBilletData() {
    sessionStorage.removeItem("@parcelamos/payment-billet-data");
    setDataPaymentBillet({
      document: "",
      phone: "",
      name: "",
      amount: "",
      debits: [] as Debits[],
      installments_number: 0,
      installments_value: "",
      billets: [] as BilletResponseType[],
      pix: [],
    });
    setDataTransferBank({
      amount: 0,
      id_transfer: "",
    });
  }

  const removeBillet = (index: number) => {
    const billets = dataPaymentBillet?.billets;
    const arr = billets.filter((_, idx) => idx !== index);
    handleSetDataPaymentBillet({ ...dataPaymentBillet, billets: arr });
  };

  const removeDebit = (id_debit: string) => {
    const filteredDebits = dataPaymentBillet.debits.filter(
      (debit) => debit.id_debit !== id_debit
    );
    handleSetDataPaymentBillet({
      ...dataPaymentBillet,
      debits: filteredDebits,
    });
  };

  async function getTransferBank() {
    const data_transfer_bank = await getFromLocalStorageParse(
      "@parcelamos/transfer-bank"
    );

    if (data_transfer_bank) {
      setDataTransferBank(data_transfer_bank);
    }
  }

  async function saveTransferBank(dataTransferBank: TransferResponse) {
    await saveInLocalStorage(
      "@parcelamos/transfer-bank",
      JSON.stringify(dataTransferBank)
    );

    setDataTransferBank(dataTransferBank);
  }
  function updateRulesFromQuery(value: TRulesFromQuery) {
    setRulesFromQuery((prev) => ({ ...prev, ...value }));
  }

  return (
    <PaymentContext.Provider
      value={{
        setPaymentBilletData: handleSetDataPaymentBillet,
        maxInstallmentNumber,
        installments,
        installmentsToUse,
        getInstallments: fetchInstallments,
        setPaymentCard: updatePaymentCard,
        processPaymentBilletData: processPaymentBillet,
        clearPaymentBilletData,
        setRatePix,
        ratePix,
        updateInstallments,
        dataPaymentBillet,
        paymentCard,
        paymentBilletResponse,
        loadingPayment,
        QTDE_PARCELAS_PIX: AMOUNT_INSTALLMENTS_PIX,
        MIN_INSTALLMENT,
        disableEditValueInput: disableEditValueInput,
        setDisableEditValueInput: setDisableEditValueInput,
        setEnableCheckout,
        isEnableCheckout,
        detran,
        setDetran: updateDetran,
        clearDetran,
        dataPartnerSearch,
        setDataPartnerSearch: updateDataPartnerSearch,
        setUserId,
        userId,
        setRenavam,
        renavam,
        removeBillet,
        removeDebit,
        MAX_INSTALLMENT_NUMBER,
        updateMaxInstallmentNumber,
        setMaxInstallmentNumber,
        setPaymentForm,
        paymentForm,
        taxSearchDebitCar,
        formCreditCard,
        setClient,
        client,
        checkoutData,
        setCheckoutData,
        dataBank,
        dataUser,
        setDataBank,
        setDataUser,
        dataTransferBank,
        saveTransferBank,
        rulesFromQuery,
        updateRulesFromQuery,
      }}
    >
      {children}
    </PaymentContext.Provider>
  );
};

const usePayment = () => React.useContext(PaymentContext);

export default usePayment;
