import styled, { css } from "styled-components";
import { deviceMaxWidth } from "theme/theme";

export const BorderGradient = styled.div``;

export const Margin = styled.div<{ responsive: boolean }>`
  margin-top: 108px;

  ${({ responsive }) =>
    responsive &&
    css`
      @media screen and (max-width: 1099px) {
        padding-top: 274px;
      }
    `};
`;

export const AHeader = styled.a<{
  active?: boolean;
  responsive?: boolean;
  margin?: boolean;
  hide?: boolean;
}>`
  float: left;
  display: ${({ hide }) => (hide ? "none" : "flex")};
  text-align: center;
  text-decoration: none;
  height: 59px;
  padding: 0 10px;
  align-items: center;
  justify-content: center;
  position: relative;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;

  &:hover {
    color: #fff;

    ${({ active }) =>
      !active &&
      css`
        ${BorderGradient} {
          width: 100%;
          height: 3px;
          position: absolute;
          bottom: 10px;
          background: rgba(255, 255, 255, 0.3);
        }
      `};
  }

  ${({ active }) =>
    active &&
    css`
      color: #fff;

      ${BorderGradient} {
        width: 100%;
        height: 3px;
        position: absolute;
        bottom: 10px;
        background: linear-gradient(153.22deg, #ffc706 0%, #adff00 100%);
      }
    `};

  @media screen and (max-width: 1099px) {
    margin: 0 !important;
    padding: 0 !important;
    margin-left: 5.5px !important;

    &:not(:first-child) {
      display: none;

      ${({ responsive }) =>
        responsive &&
        css`
          float: none;
          display: flex;
          justify-content: flex-start;
          text-align: left;
        `};
    }
  }
`;

export const TopNav = styled.div<{ responsive: boolean }>`
  background-color: #333;
  min-height: 59px;
  padding: 0 30px;

  @media screen and (min-width: 1441px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ responsive }) =>
    responsive &&
    css`
      @media screen and (max-width: 1099px) {
        position: relative;
        padding-top: 75px;
      }
    `};
`;

export const WrapperTopNav = styled.div`
  max-width: 1440px;
  @media screen and (min-width: 1441px) {
    min-width: 1440px;
  }
`;

export const ALogo = styled.a`
  float: left;
  display: flex;
  height: 59px;
  text-align: center;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
`;

export const Img = styled.img``;

export const ImgLogo = styled.img`
  max-width: 100px;
`;

export const SectionHeaderEnd = styled.section<{ responsive: boolean }>`
  @media screen and (min-width: 1100px) {
    display: flex !important;
    justify-content: flex-end !important;
  }

  @media screen and (max-width: 1099px) {
    display: none !important;

    ${({ responsive }) =>
      responsive &&
      css`
        display: flex !important;
        flex-direction: column;
        align-items: flex-start;
      `};
  }
`;

export const ABlog = styled(AHeader)`
  float: left;
  display: flex;
  height: 59px;
  text-align: center;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  position: relative;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: rgba(255, 255, 255, 0.8);
`;

export const DropDownContent = styled.div`
  display: none;
  position: absolute;
  background: #353535;
  border-radius: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  width: 100%;
  padding: 0px 10px 10px 10px;
  box-sizing: border-box;
  z-index: 1000;
  top: 59px;
`;

export const DropDownItems = styled.div`
  width: 100%;
`;

export const DropDownItem = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

export const DropDownIcon = styled.img``;

export const DropDownUnderline = styled.div`
  width: 100%;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.3);
  margin-bottom: 10px;
`;

export const ALogin = styled(AHeader)`
  float: left;
  display: flex;
  height: 59px;
  text-align: center;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  position: relative;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: rgba(255, 255, 255, 0.8);

  &:hover {
    min-width: 174px;
    ${DropDownContent} {
      display: block;
    }
  }
`;

export const AZoom = styled(AHeader)`
  float: left;
  display: flex;
  height: 59px;
  text-align: center;
  text-decoration: none;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: unset;

  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: rgba(255, 255, 255, 0.8);
`;

export const ImgMarginRight = styled.img`
  margin-right: 10px;
`;

export const Minus = styled.a`
  width: 24px;
  height: 24px;

  background: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  cursor: pointer;

  color: #fff;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const Plus = styled(Minus)`
  width: 24px;
  height: 24px;

  background: rgba(255, 255, 255, 0.1);
  border-radius: 24px;

  align-items: center;
  justify-content: center;
  display: flex;
`;

export const SizeZoom = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  color: rgba(255, 255, 255, 0.8);
  margin: 0;
  margin: 0 5px;
`;

export const WrapperIcon = styled.div`
  display: none;
  min-height: 59px;

  @media screen and (max-width: 1099px) {
    position: absolute;
    right: 30px;
    top: 0;
    display: flex !important;
    justify-content: center;
    align-items: center;
  }
`;

export const Icon = styled.a<{ responsive: boolean }>`
  text-decoration: none;
  color: #fff;
`;

export const I = styled.i``;

export const Section = styled.div`
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;

  @media screen and (max-width: 1099px) {
    ${ALogo} {
      position: absolute;
      top: 0;
    }

    ${Icon} {
      float: right;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
  }
`;

export const NavBarClient = styled(TopNav)`
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.colors.primary};
  background-color: white;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  display: flex;
  position: fixed;
  width: 100%;
  z-index: 999;
  top: 0;
  height: 62px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.12);
`;

export const ALogoClient = styled(ALogo)`
  height: max-content;
`;

export const ImgLogoClient = styled.img`
  max-height: 50px;
  max-width: 150px;
`;

export const MarginTop = styled.div`
  margin-top: 100px;
`;
