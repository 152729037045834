import { IInstallments } from "models";
import reCaptcha from "services/RecaptchaService";

import { api } from "services/api";
import { HandleError } from "services/types";

export type GetInstallmentsRequest = {
  idEstablishment?: string;
  data: GetInstallmentsSchema;
};

export enum EnumOriginPayment {
  ONLINE = "ONLINE",
  MOBILE = "MOBILE",
}

export enum EnumTypeDebit {
  BILLET = "BILLET",
  CAR_DEBIT = "CAR_DEBIT",
  TRANSFER = "TRANSFER",
  PIX = "PIX"
}

type BilletDebit = {
  type: EnumTypeDebit.BILLET;
  id_bill: string;
};

type CarDebit = {
  type: EnumTypeDebit.CAR_DEBIT;
  id_debit: string;
};

type TransferDebit = {
  type: EnumTypeDebit.TRANSFER;
  id_transfer: string;
};
type PixDebit = {
  type: EnumTypeDebit.PIX;
  id_pix: string;
};

export type MappedDebitsToGetInstallments = Array<
  BilletDebit | CarDebit | TransferDebit | PixDebit
>;

export type GetInstallmentsSchema = {
  origin: EnumOriginPayment;
  debits: MappedDebitsToGetInstallments;
};

class CreditCardService {
  private api = api;

  async getInstallments({
    idEstablishment,
    data,
  }: GetInstallmentsRequest): Promise<IInstallments & HandleError> {
    const token = await reCaptcha.getRecaptchaToken();

    const response = await this.api.post("/checkout/charge/options", data, {
      headers: {
        "id-establishment": idEstablishment,
        "x-recaptcha-token": token,
      },
    });
    return response.data;
  }
}

export async function getInstallments(
  dataRequest: GetInstallmentsRequest
): Promise<IInstallments & HandleError> {
  const parcelamosService = new CreditCardService();
  return await parcelamosService.getInstallments(dataRequest);
}

export default new CreditCardService();
