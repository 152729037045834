import React from "react";
import * as S from "./styles";

interface IBoxBorderGradient {
  children: React.ReactNode;
}

export const BoxBorderGradient: React.FC<IBoxBorderGradient> = ({ children }) => {
  return <S.Container>{children}</S.Container>;
};
