export function applyMaskToIdentifier(number?: string) {
  if (!number) return ''

  const cleanedNumber = number.replace(/\D/g, '');
  
  let maskedNumber = '';
  for (let i = 0; i < cleanedNumber.length; i++) {
    if (i > 0 && i % 4 === 0 && maskedNumber.length < 10) {
      maskedNumber += '-';
    }
    maskedNumber += cleanedNumber.charAt(i);
  }

  return maskedNumber
}