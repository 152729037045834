import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;

  @media screen and (max-width: 1023px) {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

export const WrapperContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  max-width: 1440px;

  @media screen and (max-width: 1023px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const WrapperInfos = styled.div`
  padding-left: 83px;
  padding-right: 32px;
  max-width: 832px;

  @media screen and (max-width: 1023px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Title = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 75px;
  margin-top: 106px;
  margin-bottom: 50px;

  font-feature-settings: "salt" on, "liga" off;

  background: linear-gradient(153.22deg, #ffc706 0%, #adff00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media screen and (max-width: 1023px) {
    margin-top: 50px;
    margin-bottom: 25px;
  }

  @media screen and (max-width: 768px) {
    font-size: 8vw;
    line-height: 9vw;
  }
`;

export const SubTitle = styled.p`
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
  margin-bottom: 50px;

  @media screen and (max-width: 768px) {
    font-size: 4vw;
    line-height: 6vw;
  }

  color: #312f30;
`;

export const WrapperButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 58px;

  @media screen and (max-width: 1023px) {
    justify-content: center;
  }

  @media screen and (max-width: 540px) {
    justify-content: center;
    flex-direction: column;
  }
`;

export const Button = styled.a`
  /* margin-right: 20px; */
  text-decoration: none;

  @media screen and (max-width: 540px) {
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
  }
`;

export const WrapperArrowDown = styled.div`
  display: flex;
  justify-content: center;
`;

export const ArrowDown = styled.img``;

export const WrapperInstallment = styled.div`
  height: fit-content;
`;
