import React, { useMemo, useState } from "react";

import * as S from "./styles";

import BarCode from "assets/images/icons/bar-code.svg";
import IconOpen from "assets/images/icons/down-open.svg";
import Delete from "assets/images/icons/delete.svg";
import { BilletResponseType, Debits, EnumState, Pix } from "services/types";
import { formatCurrency, formatPhoneNumber, formatShortToDateString } from "utils/mask";
import { cnpj, cpf } from "cpf-cnpj-validator";

type Props = {
  billet?: BilletResponseType;
  debit?: Debits;
  pix?: Pix;
  removeBillet?: () => void;
  defaultOpen?: "open" | "close" | "";
};

type IData = {
  name?: string;
  due_date?: string;
  infraection_date?: string;
  license_plate?: string;
  renavam?: string;
  state?: EnumState;
  amount?: number;
  digitable?: string;
  payer_name?: string;
  assignor_name?: string;
  id: string;
  key?: string;
  emv?: string;
};

export const BankslipItem: React.FC<Props> = ({
  billet,
  debit,
  removeBillet,
  defaultOpen = "",
  pix
}) => {
  const [open, setOpen] = useState<"open" | "close" | "">(defaultOpen);

  function handleOpen() {
    if (open && isOpen) {
      setOpen("close");
    } else {
      setOpen("open");
    }
  }

  const isOpen = useMemo(() => open === 'open', [open])

  const data: IData = useMemo(() => {
    const info: IData = {} as IData;

    if (billet) {
      info.name = billet?.recipient?.name;
      info.due_date = billet?.due_date;
      info.amount = billet?.amount.total;
      info.digitable = billet?.digitable;
      info.payer_name = billet?.payer?.name;
      info.assignor_name = billet?.assignor;
      info.id = billet?.id_bill;
    }
    if (debit) {
      info.name = debit?.name;
      info.due_date = debit?.due_date;
      info.amount = debit?.amount;
      info.infraection_date = debit?.infraction_date;
      info.license_plate = debit?.license_plate;
      info.renavam = debit?.renavam;
      info.state = debit?.state;
      info.id = debit?.id_debit;
    }

    if (pix) {
      info.name = pix?.merchant.name ?? pix.merchant.city;
      info.due_date = pix?.due_date;
      info.amount = pix?.amount.total;
      info.id = pix?.id_pix;
      info.emv = pix.emv;
      info.key = pix.key

      const isCPF = cpf.isValid(pix.key)
      const isCNPJ = cnpj.isValid(pix.key)
      const isPhone = /^\+\d{2}\d{2}\d{9}$/.test(pix.key)

      if (isCPF) {
        info.key = cpf.format(pix.key)
      } else
      if (isCNPJ) {
        info.key = cnpj.format(pix.key)
      } else
      if (isPhone) {
        info.key = formatPhoneNumber(pix.key)
      }
    }

    return info;
  }, [debit, billet]);

  return (
    <S.Container className={open}>
      <S.Wrapper className={open}>
        <S.ButtonOpen onClick={() => handleOpen()}>
          <S.Icon src={IconOpen} />
        </S.ButtonOpen>
        {removeBillet && (
          <S.ButtonDelete
            id={`trash-button-${data.id}`}
            onClick={() => removeBillet()}
          >
            <S.Icon src={Delete} />
          </S.ButtonDelete>
        )}
        <S.Row>
          <S.IconBarCode src={BarCode} />
          <S.Div>
            <S.Name>{data.name}</S.Name>
            {data.amount ? (
              <S.Value>{formatCurrency(data.amount)}</S.Value>
            ) : null}
          </S.Div>
        </S.Row>

        {pix && data.key && (
           <S.Row>
           <S.Label>Chave</S.Label>
           <S.Dot>•</S.Dot>
           <S.TextLabel>
             {data.key}
           </S.TextLabel>
         </S.Row>
        )}

        <S.Row>
          <S.Label>Vencimento</S.Label>
          <S.Dot>•</S.Dot>
          <S.TextLabel>
            {data.due_date
              ? formatShortToDateString(data.due_date)
              : "Sem Informação"}
          </S.TextLabel>
        </S.Row>

        
        
        {data?.assignor_name && (
          <S.Row>
            <S.Label>Instituição financeira</S.Label>
            <S.Dot>•</S.Dot>
            <S.TextLabel>{data?.assignor_name || "Sem Informação"}</S.TextLabel>
          </S.Row>
        )}

        {debit && (
          <S.Row>
            <S.Label>Data da infração</S.Label>
            <S.Dot>•</S.Dot>
            <S.TextLabel>
              {data.infraection_date
                ? formatShortToDateString(data.infraection_date)
                : "Sem Informação"}
            </S.TextLabel>
          </S.Row>
        )}

        {debit && data.state && (
          <S.Row>
            <S.Label>Estado</S.Label>
            <S.Dot>•</S.Dot>
            <S.TextLabel>{data.state}</S.TextLabel>
          </S.Row>
        )}

        {isOpen && data.license_plate && (
          <S.Row>
            <S.Label>Placa</S.Label>
            <S.Dot>•</S.Dot>
            <S.TextLabel>{data.license_plate}</S.TextLabel>
          </S.Row>
        )}

        {isOpen && data.renavam && (
          <S.Row>
            <S.Label>Renavam</S.Label>
            <S.Dot>•</S.Dot>
            <S.TextLabel>{data.renavam}</S.TextLabel>
          </S.Row>
        )}

        {isOpen && data.digitable && (
          <S.Row>
            <S.Label>Linha digitável</S.Label>
            <S.Dot>•</S.Dot>
            <S.TextLabel>{data.digitable || "Sem Informação"}</S.TextLabel>
          </S.Row>
        )}

        {isOpen && data.payer_name && (
          <S.Row>
            <S.Label>Pagador</S.Label>
            <S.Dot>•</S.Dot>
            <S.TextLabel>{data.payer_name}</S.TextLabel>
          </S.Row>
        )}
      </S.Wrapper>
    </S.Container>
  );
};
