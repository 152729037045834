import CryptoJS from "crypto-js";

const keyCrypto = process.env.REACT_APP_KEY_CRYPTO;

export const saveInLocalStorage = (key: string, value: string) => {
  const encryptedValue = CryptoJS.AES.encrypt(
    value,
    keyCrypto as string
  ).toString();
  sessionStorage.setItem(key, encryptedValue);
};

export const getFromLocalStorage = (key: string) => {
  const encryptedValue = sessionStorage.getItem(key);

  if (!encryptedValue) return null;

  return CryptoJS.AES.decrypt(encryptedValue, keyCrypto as string).toString(
    CryptoJS.enc.Utf8
  );
};

export const getFromLocalStorageParse = (key: string) => {
  const data = getFromLocalStorage(key);

  if (!data) return null;

  return JSON.parse(data);
};
