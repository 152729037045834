import React, { useEffect } from "react";
import {
  NavBar,
  LandingSection,
  SeeHowSimpleSection,
  PartnerSection,
  HowWeHelpYouSection,
  TalkToUsSection,
} from "components";
import { useLocation } from "react-router-dom";

export const Home: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const id = location.hash.substring(1);
      const targetElement = document.getElementById(id);
      if (targetElement) {
        const targetOffset = targetElement.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: targetOffset,
          behavior: 'smooth'
        });
      }
    }
  }, [location.hash]);

  return (
    <>
      <NavBar />

      <LandingSection />

      <SeeHowSimpleSection />

      <PartnerSection />

      <HowWeHelpYouSection />

      <TalkToUsSection />
    </>
  );
};
