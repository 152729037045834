import { createSlice } from '@reduxjs/toolkit';

interface UserState {
  name: string;
}

function defineInitialState(): UserState {
  const initialState: UserState = {
    name: ''
  };
  // Get user from localStorage
  const json = localStorage.getItem('user');
  if (json) {
    const userJSON = JSON.parse(json);
    initialState.name = userJSON;
  }
  return initialState;
}

const userSlice = createSlice({
  name: 'user',
  initialState: defineInitialState(),
  reducers: {
    reset: (state) => {
      localStorage.removeItem('user');
      state = defineInitialState();
    },
    logout: (state) => {
      localStorage.removeItem('user');
      state.name = '';
    },
    update: (state, action) => {
      localStorage.setItem('user', JSON.stringify(action.payload));
      state.name = action.payload;
    }
  }
});

export const { reset, logout, update } = userSlice.actions;

export default userSlice.reducer;
