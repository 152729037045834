import React from "react";
import * as S from "./styles";

interface IBoxForm {
  children: React.ReactNode;
  hide?: boolean;
}

export const BoxForm: React.FC<IBoxForm> = ({ children, hide = true }) => {
  return <>{hide && <S.Container>{children}</S.Container>}</>;
};
